import { App, AppStatusError, AuthenticationError, AuthorizationError, NetworkError, NotFoundError, Plan } from "@back4app2/sdk";
import { useEffect, useReducer, useState } from "react";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import back4app2 from "../back4app2";
import LoadingSpinner from "../components/LoadingSpinner";
import ErrorMessage from "../components/ErrorMessage";
import TextInput from "../components/FormInputField/TextInput/TextInput";
import { BACK4APP_DOT_COM_SITE_URL } from "../settings";
import ToggleSwitchCheckbox from "../components/FormInputField/ToggleSwitchCheckbox/ToggleSwitchCheckbox";
import toast from "react-hot-toast";
import { ReactComponent as PlusIcon } from '../assets/images/plus-icon.svg';
import { ReactComponent as TrashIcon } from "../assets/images/trash-icon.svg";
import { ReactComponent as VisibilityIcon } from "../assets/images/visibility-icon.svg";
import { ReactComponent as VisibilityOffIcon } from "../assets/images/visibility-off-icon.svg";
import { ReactComponent as TrashOutlineIcon } from "../assets/images/trash-outline-icon.svg";
import { ReactComponent as TriangleExclamationIcon } from '../assets/images/triangle-exclamation.svg';
import { ReactComponent as AttentionIconSVG } from "../assets/images/circle-exclaimation-icon.svg";
import { ReactComponent as StatusErrorSVG } from '../assets/images/status-error.svg';
import { ReactComponent as StatusSuccessSVG } from '../assets/images/status-success.svg';
import { ReactComponent as StatusSpinnerSVG } from '../assets/images/status-spinner.svg';
import { ReactComponent as GhostIconSVG } from "../assets/images/ghost-icon.svg";
import { ReactComponent as CopySVG } from '../assets/images/copy-icon.svg';
import Button from "../components/Button";
import Modal from "../components/Modal";
import CustomDomainSettings from "../components/CustomDomainSettings";
import ReactTooltip from "react-tooltip";
import { AmplitudeEvent, trackEvent } from "../utils/amplitude";

enum MyAppSettingsTab {
  GENERAL_SETTINGS = 'general-settings',
  BUILD_AND_DEPLOY = 'build-and-deploy',
  VARIABLES = 'variable',
  CUSTOM_DOMAIN = 'custom-domain',
  HEALTH_AND_ALERT = 'health-and-alert',
  SUSPEND_DELETE = 'suspend-delete'
}

enum MyAppSettingsFieldName {
  BRANCH_NAME,
  ROOT_DIR,
  AUTO_DEPLOY,
  ENV_VARS,
  EXPOSED_PORT,
  HEALTH_CHECK_ENDPOINT
}

interface MyAppSettingsState {
  currentTab: MyAppSettingsTab;
  isLoadingApp: boolean;
  appLoadingErrorMessage?: string;
  app?: App;
  isSubmitting: boolean;
  submittingErrorMessage?: string;
  isTriggerDeploymentModalOpen: boolean;
}

const INITIAL_STATE: MyAppSettingsState = {
  currentTab: MyAppSettingsTab.GENERAL_SETTINGS,
  isLoadingApp: true,
  isSubmitting: false,
  isTriggerDeploymentModalOpen: false
};

enum MyAppSettingsActionType {
  RESET,
  FINISH_LOADING_APP,
  SET_CURRENT_TAB,
  SET_TRIGGER_DEPLOYMENT_MODAL,
  FINISH_UPDATING_APP
}

const reset = () => ({
  type: MyAppSettingsActionType.RESET
} as const);

const setCurrentTab = (tab: MyAppSettingsTab) => ({
  type: MyAppSettingsActionType.SET_CURRENT_TAB,
  payload: tab
} as const);

const finishLoadingApp = (errorMessage?: string, app?: App) => ({
  type: MyAppSettingsActionType.FINISH_LOADING_APP,
  payload: {
    errorMessage,
    app
  }
} as const);

const finishUpdatingApp = (app: App, errorMessage?: string) => ({
  type: MyAppSettingsActionType.FINISH_UPDATING_APP,
  payload: {
    errorMessage,
    app
  }
} as const);

const openTriggerDeploymentModal = () => ({
  type: MyAppSettingsActionType.SET_TRIGGER_DEPLOYMENT_MODAL,
  payload: true,
} as const);

const closeTriggerDeploymentModal = () => ({
  type: MyAppSettingsActionType.SET_TRIGGER_DEPLOYMENT_MODAL,
  payload: false,
} as const);

type MyAppSettingsAction = ReturnType<typeof reset> | ReturnType<typeof finishLoadingApp> | ReturnType<typeof setCurrentTab> | ReturnType<typeof openTriggerDeploymentModal> | ReturnType<typeof closeTriggerDeploymentModal> | ReturnType<typeof finishUpdatingApp>;

const reducer = (state: MyAppSettingsState = INITIAL_STATE, action: MyAppSettingsAction): MyAppSettingsState => {
  switch (action.type) {
    case MyAppSettingsActionType.RESET:
      return INITIAL_STATE;
    
    case MyAppSettingsActionType.FINISH_LOADING_APP:
      return {
        ...state,
        isLoadingApp: false,
        appLoadingErrorMessage: action.payload.errorMessage,
        app: action.payload.app
      }
    case MyAppSettingsActionType.SET_CURRENT_TAB: {
      return {
        ...state,
        currentTab: action.payload || MyAppSettingsTab.GENERAL_SETTINGS
      }
    }
    case MyAppSettingsActionType.SET_TRIGGER_DEPLOYMENT_MODAL:
      return {
        ...state,
        isTriggerDeploymentModalOpen: action.payload
      }
    case MyAppSettingsActionType.FINISH_UPDATING_APP:
      return {
        ...state,
        app: action.payload.app,
        isSubmitting: false,
        submittingErrorMessage: action.payload.errorMessage
      }
  }
};

type AppForm = {
  branchName?: string, 
  rootDir?: string, 
  envs?: { key: string; value?: string }[], 
  autoDeploy?: boolean,
  exposedPort?: number,
  healthCheckEndpoint?: string
}

const MyAppSettings = () => {
  const { appId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { updateIsDeletingApp } = useOutletContext<{ updateIsDeletingApp: (isDeleting: boolean) => void }>();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { currentTab, app, isLoadingApp, appLoadingErrorMessage, isSubmitting, isTriggerDeploymentModalOpen } = state;
  const mainServiceEnvironment = app && app.mainService && app.mainService.mainServiceEnvironment;
  const mainServiceEnvironmentId = mainServiceEnvironment && mainServiceEnvironment.id;

  useEffect(() => {
    dispatch(setCurrentTab(location.hash.replace('#', '') as MyAppSettingsTab));
  }, [location.hash]);

  useEffect(() => {
    (async() => {
      if (appId) {
        try {
          const app: App = await back4app2.findAppById(appId);
          dispatch(finishLoadingApp(undefined, app));
        } catch (err) {
          let errMsg = '';
          if (err instanceof NetworkError) {
            console.error('network error', err);
            errMsg = 'Network error when fetching app settings. Check your internet connection and try again.';
          } else if (err instanceof NotFoundError) {
            errMsg = 'App not found!'
          } else {
            console.error('unexpected error fetching app settings', err);
            errMsg = 'Unexpected error while fetching app settings. Please try again.'
          }
          dispatch(finishLoadingApp(errMsg, undefined));
        }
      }
    })();
  }, [appId]);

  const handleSubmit = async (newValues: AppForm) => {
    // check if something change
    let isSettingsUpdated = false;
    if (newValues.branchName && newValues.branchName !== app?.mainService?.mainServiceEnvironment?.branchName) {
      isSettingsUpdated = true;
    }
    if (newValues.rootDir && newValues.rootDir !== app?.mainService?.mainServiceEnvironment?.rootDirPath) {
      isSettingsUpdated = true;
    }
    if (typeof newValues.autoDeploy !== "undefined" && newValues.autoDeploy !== app?.mainService?.mainServiceEnvironment?.autoDeploy) {
      isSettingsUpdated = true;
    }
    if (newValues.envs) {
      if (app?.mainService?.mainServiceEnvironment?.envVars.length !== newValues.envs.length) {
        isSettingsUpdated = true;
      } else {
        // check each envs
        for (let idx = 0; idx < newValues.envs.length; idx++) {
          const newEnv = newValues.envs[idx];
          const sameEnv = app.mainService.mainServiceEnvironment.envVars.find(oldEnv => {
            if (oldEnv.key === newEnv.key && oldEnv.value === newEnv.value) return false;
            else if (oldEnv.key === newEnv.key && oldEnv.value !== newEnv.value) return true;
            else if (oldEnv.key !== newEnv.key && oldEnv.value === newEnv.value) return true;
            return false;
          });
          if (sameEnv) {
            isSettingsUpdated = true; break;
          }
        }
      }
    }
    if (newValues.exposedPort !== app?.mainService?.mainServiceEnvironment?.exposedPort) {
      isSettingsUpdated = true;
    }
    if (newValues.healthCheckEndpoint && newValues.healthCheckEndpoint !== app?.mainService?.mainServiceEnvironment?.healthCheckEndpoint) {
      isSettingsUpdated = true;
    }

    if (!isSettingsUpdated) {
      toast('Please make some changes to save new settings!', {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-md text-sm",
        icon: <AttentionIconSVG width="20px" height="20px" className="animate-bounce-in text-old-blue" />
      });
      return;
    }

    const toastId = toast.loading('Updating settings...', {
      className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
      icon: <StatusSpinnerSVG width="24px" height="24px" color="text-old-blue" className="animate-spin" />
    });
    try {
      const updatedApp = await back4app2.updateAppSettings(appId as string, newValues.branchName, newValues.rootDir, newValues.autoDeploy, newValues.envs, newValues.healthCheckEndpoint, newValues.exposedPort);
      dispatch(finishUpdatingApp(updatedApp, undefined));
      toast.success('Settings were updated!', {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
        id: toastId,
        icon: <StatusSuccessSVG width="24px" height="24px" className="animate-bounce-in top-" />
      });
      dispatch(openTriggerDeploymentModal());
    } catch (err) {
      let errMsg = 'Something went wrong!';
      if (err instanceof AppStatusError) {
        errMsg = err.message;
      } else {
        console.error('error', err);
      }      
      toast.error(errMsg, {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
        id: toastId,
        icon: <StatusErrorSVG width="24px" height="24px" className="animate-bounce-in" />,
        duration: 6000
      });
      //
    }
  }

  const getFormContent = () => {
    let headerText = '';
    let formContent;
    if (!app) {
      return;
    }
    switch (currentTab) {
      case MyAppSettingsTab.GENERAL_SETTINGS: {
        headerText = 'General Settings';
        formContent = <GeneralSettingsForm appId={app.id} appName={app.name} plan={app.mainService?.mainServiceEnvironment?.plan} />;
        break;
      }
      case MyAppSettingsTab.BUILD_AND_DEPLOY: {
        headerText = 'Build & Deploy';
        formContent = <BuildAndDeployForm 
          branchName={app.mainService?.mainServiceEnvironment?.branchName || ''} 
          rootDir={app.mainService?.mainServiceEnvironment?.rootDirPath || ''} 
          autoDeploy={!!app.mainService?.mainServiceEnvironment?.autoDeploy}
          exposedPort={`${app.mainService?.mainServiceEnvironment?.exposedPort || ''}`}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
        />;
        break;
      }
      case MyAppSettingsTab.VARIABLES: {
        headerText = 'Environment Variables';
        formContent = <VariablesForm envVars={app.mainService?.mainServiceEnvironment?.envVars || []} isSubmitting={isSubmitting} handleSubmit={handleSubmit} />;
        break;
      }
      case MyAppSettingsTab.CUSTOM_DOMAIN: {
        headerText = 'Domains';
        formContent = <CustomDomainSettings serviceEnvironmentId={mainServiceEnvironmentId as string} mainCustomDomainId={app.mainService?.mainServiceEnvironment?.mainCustomDomain?.id as string} />
        break;
      }
      case MyAppSettingsTab.HEALTH_AND_ALERT: {
        headerText = 'Health and Alert';
        formContent = <HealthAndAlertForm healthCheckEndpoint={app.mainService?.mainServiceEnvironment?.healthCheckEndpoint || ''}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
        />
        break;
      }
      case MyAppSettingsTab.SUSPEND_DELETE: {
        headerText = 'Delete';
        formContent = <SuspendDeleteForm appName={app.name} appId={app.id} updateIsDeletingApp={updateIsDeletingApp} />;
        break;
      }
      default:
        break;
    }
    if (!app.mainService?.mainServiceEnvironment?.id && ![MyAppSettingsTab.GENERAL_SETTINGS, MyAppSettingsTab.SUSPEND_DELETE].includes(currentTab)) {
      formContent = <div className="flex flex-col items-center">
        <GhostIconSVG />
        <div className="mt-4 textlg font-sora font-semibold leading-[140%] text-light-grey">
          Nothing here, yet!
        </div>
      </div>
    }
    return <>
      <h3 className="text-light-grey text-lg font-semibold leading140 mb-14">{headerText}</h3>
      {formContent}
    </>
  }

  const handleTriggerDeployment = async () => {
    try {
      const mainServiceEnvironment = app && app.mainService && app.mainService.mainServiceEnvironment;
      const mainServiceEnvironmentId = mainServiceEnvironment && mainServiceEnvironment.id;
      await back4app2.triggerManualDeployment(mainServiceEnvironmentId as string);
      navigate(`/apps/${appId}`);
    } catch (err) {
      toast.error('Something went wrong while trigging new deployment!', {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
        icon: <StatusErrorSVG width="24px" height="24px" className="animate-bounce-in" />,
        duration: 6000
      });
    }
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <h1 className="font-sora text-[1.375rem] leading-140">Settings</h1>
        <div className="text-sm flex gap-2">App ID:
          <span className="text-cta-green">{appId}</span>
          <span data-tip="Copied!" data-event='click focus'>
            <CopySVG className="text-cta-green hover:cursor-pointer flex-none" onClick={() => navigator.clipboard.writeText(appId as string)} />
          </span>
        </div>
      </div>
      <div className="pl-[3.75rem]">
        <ul className="w-full flex flex-wrap gap-8 mt-10 text-sm pb-[0.625rem]" role="tablist">
          <li><a href={`#${MyAppSettingsTab.GENERAL_SETTINGS}`} role="tablist" data-toggle="tab" className={`relative duration-300 transition-all before:absolute before:bg-cta-green before:h-[3px] before:w-full before:-bottom-[0.625rem] ${currentTab === MyAppSettingsTab.GENERAL_SETTINGS ? 'text-white before:block' : 'text-[#9ba0a9] before:hidden'}`}>General Settings</a></li>
          <li><a href={`#${MyAppSettingsTab.BUILD_AND_DEPLOY}`} role="tablist" data-toggle="tab" className={`relative duration-300 transition-all before:absolute before:bg-cta-green before:h-[3px] before:w-full before:-bottom-[0.625rem] ${currentTab === MyAppSettingsTab.BUILD_AND_DEPLOY ? 'text-white before:block' : 'text-[#9ba0a9] before:hidden'}`}>Build and Deploy</a></li>
          <li><a href={`#${MyAppSettingsTab.VARIABLES}`} role="tablist" data-toggle="tab" className={`relative duration-300 transition-all before:absolute before:bg-cta-green before:h-[3px] before:w-full before:-bottom-[0.625rem] ${currentTab === MyAppSettingsTab.VARIABLES ? 'text-white before:block' : 'text-[#9ba0a9] before:hidden'}`}>Environment Variables</a></li>
          <li><a href={`#${MyAppSettingsTab.CUSTOM_DOMAIN}`} role="tablist" data-toggle="tab" className={`relative duration-300 transition-all before:absolute before:bg-cta-green before:h-[3px] before:w-full before:-bottom-[0.625rem] ${currentTab === MyAppSettingsTab.CUSTOM_DOMAIN ? 'text-white before:block' : 'text-[#9ba0a9] before:hidden'}`}>Domain</a></li>
          <li><a href={`#${MyAppSettingsTab.HEALTH_AND_ALERT}`} role="tablist" data-toggle="tab" className={`relative duration-300 transition-all before:absolute before:bg-cta-green before:h-[3px] before:w-full before:-bottom-[0.625rem] ${currentTab === MyAppSettingsTab.HEALTH_AND_ALERT ? 'text-white before:block' : 'text-[#9ba0a9] before:hidden'}`}>Health and Alert</a></li>
          <li><a href={`#${MyAppSettingsTab.SUSPEND_DELETE}`} role="tablist" data-toggle="tab" className={`relative duration-300 transition-all before:absolute before:bg-cta-green before:h-[3px] before:w-full before:-bottom-[0.625rem] ${currentTab === MyAppSettingsTab.SUSPEND_DELETE ? 'text-white before:block' : 'text-[#9ba0a9] before:hidden'}`}>Delete</a></li>
        </ul>
        <div className="mt-14">
          {isLoadingApp ? <div className="flex h-full justify-center items-center"> <LoadingSpinner color='text-white' /> </div> : null}
          {appLoadingErrorMessage ? (<>
            <div className="flex h-full">
              <ErrorMessage message={appLoadingErrorMessage} />
            </div>
          </>) : (getFormContent())}
        </div>
      </div>
      <Modal open={isTriggerDeploymentModalOpen} onClose={() => dispatch(closeTriggerDeploymentModal())}>
        <>
          <div className="flex w-full justify-center">
            <StatusSuccessSVG className="text-cta-green" width="40px" height="56px" />
          </div>
          <div className="font-semibold leading-140 text-dark text-center mb-2">Settings updated successfully</div>
          <div className="text-sm text-center max-w-xs mb-2">Would you like trigger new deployment with saved settings?</div>
          <div className="flex justify-center space-x-2 w-full mt-4">
            <Button className="text-dark-grey border border-light-grey rounded-[5px]" onClick={() => dispatch(closeTriggerDeploymentModal())}>No</Button>
            <Button type="primary" onClick={handleTriggerDeployment}>Yes</Button>
          </div>
        </>
      </Modal>
      <ReactTooltip
        className="tooltip shadow-[0px_0px_30px_rgba(0,0,0,0.4)]" 
        delayHide={2000} 
        place="top" 
        effect="solid" 
        html={true} 
        arrowColor="#303338" 
        eventOff="click" 
        afterShow={() => navigator.clipboard.writeText(appId as string)}
      />
    </>
  )
};

const GeneralSettingsForm = (props: {appId: string; appName: string, plan?: Plan}) => {
  const { appName, plan, appId } = props;
  let planRAM = '';
  if (plan?.maxRAM) {
    planRAM = plan.maxRAM >= 1024 ? `${plan.maxRAM / 1024}GB` : `${plan.maxRAM}MB`;
  }
  return <form>
    <TextInput value={appName} onChange={() => {}} label="Container Name" isDisabled={true} />
    <div className="mt-6">
      <div className="text-lg text-light-grey leading-140 mb-[0.625rem]">Plan</div>
      <div className="bg-light-grey/[0.06] py-2 px-6 rounded w-full flex justify-between items-center">
        <div className="flex flex-col">
          <span className="uppercase text-lg">{plan?.name}</span>
          <span className="text-sm text-light-blue opacity-70">{planRAM} {plan?.maxCPU} CPU</span>
          <span className="text-sm text-light-grey mt-2">{plan?.tagline}</span>
        </div>
        <div className="flex items-center gap-4">
          <span className="text-sm text-old-blue">$ {plan?.price}.00 / month</span>
          <a role="button" target="_blank" rel='noreferrer noopener' href={`${BACK4APP_DOT_COM_SITE_URL}/pricing/container-as-a-service?appId=${appId}&type=containers`} className="border rounded-[5px] border-cta-green text-cta-green inline-block py-2 px-2.5 text-xs font-medium leading-140">Upgrade</a>
        </div>
      </div>
    </div>
  </form>
}

const BuildAndDeployForm = (props: {branchName: string, rootDir: string, autoDeploy: boolean, exposedPort: string, isSubmitting: boolean, handleSubmit: (newValue: AppForm) => {} }) => {
  const { branchName, rootDir, autoDeploy, exposedPort, isSubmitting, handleSubmit } = props;
  const [formFields, setFormFields] = useState(() => (
    {
      branchName: {
        value: branchName || '',
        isValid: true,
        isRequired: true,
        errorMsg: ''
      },
      rootDir: {
        value: rootDir || '',
        isValid: true,
        isRequired: true,
        errorMsg: ''
      },
      exposedPort: {
        value: exposedPort || '',
        isValid: true,
        isRequired: false,
        errorMsg: ''
      },
      autoDeploy: !!autoDeploy
    }
  ));

  const handleFormFields = (fieldName: MyAppSettingsFieldName, fieldValue: string) => {
    const newState = { ...formFields };
    if (fieldName === MyAppSettingsFieldName.BRANCH_NAME) {
      if (fieldValue === '') {
        newState.branchName.isValid = false;
        newState.branchName.errorMsg = 'Repositary branch name is required!'
      }
      newState.branchName.value = fieldValue;
    } else if (fieldName === MyAppSettingsFieldName.ROOT_DIR) {
      if (!fieldValue.startsWith('./')) {
        if (fieldValue.startsWith('.') || fieldValue.startsWith('/')) {
          fieldValue = fieldValue.substring(1);
        }
        fieldValue = `./${fieldValue}`;
      }
      newState.rootDir.value = fieldValue;
    } else if (fieldName === MyAppSettingsFieldName.EXPOSED_PORT) {
      if (!fieldValue) {
        newState.exposedPort.value = fieldValue;
        return setFormFields({...newState});
      }
      let val = +fieldValue;
      newState.exposedPort.value = fieldValue;
      newState.exposedPort.errorMsg = '';
      newState.exposedPort.isValid = true;
      if ((isNaN(val) || !(Number.isInteger(val) && val > 0 && val <= 65535)) && val !== undefined) {
        newState.exposedPort.errorMsg = 'Please provide a valid TCP port number (1-65535).'
        newState.exposedPort.isValid = false;
        newState.exposedPort.value = ''
      }
    }
    setFormFields({...newState})
  }

  const onSubmit = () => {
    const newValues = {
      branchName: formFields.branchName.value,
      rootDir: formFields.rootDir.value,
      autoDeploy: formFields.autoDeploy,
      exposedPort: +formFields.exposedPort.value
    }
    handleSubmit(newValues);
  }

  return <div className="space-y-6">
    <TextInput 
      value={formFields.branchName.value} 
      label="Branch" 
      name="branch" 
      onChange={(e) => handleFormFields(MyAppSettingsFieldName.BRANCH_NAME, e.target.value.trim())} 
      placeholderText="Branch"
      infoText="The repository branch used for your App."
      isError={!formFields.branchName.isValid}
      errorMessage={formFields.branchName.errorMsg}
      isDisabled={isSubmitting}
    />
    <TextInput 
      value={formFields.rootDir.value} 
      label="Root directory" 
      name="rootDirPath" 
      onChange={(e) => handleFormFields(MyAppSettingsFieldName.ROOT_DIR, e.target.value.trim())} 
      placeholderText="./"
      infoText="Defaults to repository root. When you specify a root directory that is different from your repository root, Back4app runs all your commands in the specified directory and ignores changes outside the directory."
      isError={!formFields.rootDir.isValid}
      errorMessage={formFields.rootDir.errorMsg}
      isDisabled={isSubmitting}
    />
    <ToggleSwitchCheckbox 
      value={formFields.autoDeploy} 
      onChange={() => setFormFields(prev => ({...prev, autoDeploy: !prev.autoDeploy}))} 
      labelText="Autodeploy" 
      description={`Automatic deploy on every push to your repository or changes to your App? Select "No" to handle your deploys manually.`} 
    />
    <TextInput 
      value={`${formFields.exposedPort.value}`} 
      label="Port" 
      name="exposedPort" 
      onChange={(e) => handleFormFields(MyAppSettingsFieldName.EXPOSED_PORT, e.target.value.trim())} 
      placeholderText="8080"
      infoText="Defaults to dockerfile exposed port. When you specify a port that is different from your dockerfile exposed port, Back4app exposes that port."
      isError={!formFields.exposedPort.isValid}
      errorMessage={formFields.exposedPort.errorMsg}
    />
    <div className="mt-12">
      <Button onClick={onSubmit} type="primary" className="py-3 px-[0.875rem]" isDisabled={isSubmitting}>
        <div className="flex items-center gap-2">
          {isSubmitting ? 'Saving Settings...' : 'Save Settings' }
        </div>
      </Button>
    </div>
  </div>
}

const HealthAndAlertForm = (props: {healthCheckEndpoint: string, isSubmitting: boolean, handleSubmit: (newValue: AppForm) => {} }) => {
  const { healthCheckEndpoint, isSubmitting, handleSubmit } = props;
  const [formFields, setFormFields] = useState(() => (
    {
      healthCheckEndpoint: {
        value: healthCheckEndpoint || '',
        isValid: true,
        isRequired: true,
        errorMsg: ''
      }
    }
  ));

  const handleFormFields = (fieldName: MyAppSettingsFieldName, fieldValue: string) => {
    const newState = { ...formFields };
    if (fieldName === MyAppSettingsFieldName.HEALTH_CHECK_ENDPOINT) {
      if (fieldValue[0] !== '/') {
        fieldValue = `/${fieldValue}`
      }
      newState.healthCheckEndpoint.value = fieldValue;
    }
    setFormFields({...newState})
  }

  const onSubmit = () => {
    const newValues = {
      healthCheckEndpoint: formFields.healthCheckEndpoint.value,
    }
    handleSubmit(newValues);
  }

  return <div className="space-y-6">
    <TextInput 
      value={formFields.healthCheckEndpoint.value} 
      label="Health check path" 
      name="healthCheckEndpoint" 
      onChange={(e) => handleFormFields(MyAppSettingsFieldName.HEALTH_CHECK_ENDPOINT, e.target.value.trim())} 
      placeholderText="/checkpath"
      infoText="A health check is a HTTP request that will run during deployments to ensure a service is healthy."
      isError={!formFields.healthCheckEndpoint.isValid}
      errorMessage={formFields.healthCheckEndpoint.errorMsg}
      isDisabled={isSubmitting}
    />
    <div className="mt-12">
      <Button onClick={onSubmit} type="primary" className="py-3 px-[0.875rem]" isDisabled={isSubmitting}>
        <div className="flex items-center gap-2">
          {isSubmitting ? 'Saving Settings...' : 'Save Settings' }
        </div>
      </Button>
    </div>
  </div>
}

const VariablesForm = (props: { envVars: { id: string, key: string; value?: string }[], isSubmitting: boolean, handleSubmit: (newValues: AppForm) => {}}) => {
  const { envVars, isSubmitting, handleSubmit } = props;
  const [formFields, setFormFields] = useState(() => (envVars.map(env => ({
    id: Math.random().toString(16).slice(2), 
    name: { isValid: true, value: env.key, errorMsg: '' }, 
    value: { isValid: true, value: env.value, showValue: false }
  }))));

  const handleOnChangeEnvVars = (id: string, newValue: string, type: 'name' | 'value') => {
    const newEnvVariableList = formFields.map(env => {
      if (id === env.id && type === 'name') {
        // use only uppercase letters, underscore (_) and numbers.
        // Ref: https://stackoverflow.com/a/2821183/11070995
        let isValid = !!newValue.match(/(^[A-Z_])[A-Z0-9_]+$/);
        let errorMsg = '';
        if (!isValid) {
          errorMsg = 'Substitution variables must begin with an underscore (_) or uppercase letter and use only uppercase letters and numbers';
        } else {
          errorMsg = '';
        }
        return { ...env, name: { value: newValue, isValid, errorMsg }}
      } else if (id === env.id && type === 'value') {
        let isValid = newValue !== '';
        return { ...env, value: { ...env.value ,value: newValue, isValid }}
      }
      return env;
    });
    setFormFields([...newEnvVariableList]);
  }

  const handleAddEnviromentVar = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    // validate if already empty entry present
    const emptyVarIdx = formFields.findIndex(env => env.name.value === '');
    let newEnvVariableList = [...formFields];
    if (emptyVarIdx !== -1) {
      let newEnvVar = { ...formFields[emptyVarIdx] };
      newEnvVar.name.isValid = false;
      newEnvVar.name.errorMsg = 'A variable name is must!'
      newEnvVariableList.splice(emptyVarIdx, 1, newEnvVar);
    } else {
      const newEnvVar = {
        id: Math.random().toString(16).slice(2), name: { isValid: true, value: '', errorMsg: '' }, value: { isValid: true, value: '', showValue: false }
      } 
      newEnvVariableList = [...formFields, newEnvVar ];
    }
    setFormFields(newEnvVariableList);
  }

  const handleRemoveEnviromentVar = (id: string) => {
    const newEnvVariableList = formFields.filter(env => env.id !== id);
    setFormFields(newEnvVariableList);
  }

  const toggleEnvVariableVisibilty = (id: string) => {
    const newEnvVariableList = formFields.map(env => {
      if (env.id === id) {
        return { ...env, value: {...env.value, showValue: !env.value.showValue} }
      }
      return env;
    });
    setFormFields(newEnvVariableList);
  }

  const onSubmit = () => {
    // validation
    const inValidEnv = formFields.findIndex(env => !env.name.value.match(/(^[A-Z_])[A-Z0-9_]+$/));
    if (formFields.length && inValidEnv !== -1) {
      const newEnv = { ...formFields[inValidEnv] };
      newEnv.name.errorMsg = 'Substitution variables must begin with an underscore (_) or uppercase letter and use only uppercase letters and numbers'
      newEnv.name.isValid = false;
      const newFormFields = formFields.splice(inValidEnv, 1, newEnv);
      setFormFields([...newFormFields]);
      return;
    }
    const envs = formFields.map(env => ({
      key: env.name.value,
      value: env.value.value
    }));
    handleSubmit({ envs });
  }

  return <>
    <div className="text-sm mb-6">Your App will have access to these variables at both build time and runtime.</div>
    {formFields.map((enviromentVar) => {
      const { id, name, value } = enviromentVar;
      return(
        <div className="flex justify-between items-start mb-8" key={id}>
          <div className='grid grid-cols-2 gap-x-6 flex-1'>
            <TextInput 
              value={name.value} 
              label="Name" 
              name={`env-name-${id}`} 
              onChange={(e) => handleOnChangeEnvVars(id, e.target.value.trim(), 'name')} 
              placeholderText="EXAMPLE_NAME"
              isError={!name.isValid}
              errorMessage={name.errorMsg}
            />
            <TextInput 
              type={value.showValue ? 'text' : 'password'}
              value={value.value || ''} 
              label="Value" 
              name={`env-value-${id}`} 
              onChange={(e) => handleOnChangeEnvVars(id, e.target.value, 'value' )} 
              placeholderText="A1B2C3D4E5F6"
              endAdornment={<span className="cursor-pointer" onClick={() => toggleEnvVariableVisibilty(id)}>{value.showValue ? <VisibilityOffIcon /> : <VisibilityIcon /> }</span>}
              helpText={value.showValue ? "Will be encrypted." : ''}
            />
          </div>
          <span className="text-error-red flex justify-center items-center px-4 border border-error-red rounded hover:cursor-pointer flex-initial ml-4 h-12 mt-[1.9rem] text-center" onClick={() => handleRemoveEnviromentVar(id)}><TrashIcon /></span>
        </div>
      )}
    )}
    {/* Add button for Environment varible */}
    <a onClick={handleAddEnviromentVar} type="button" href="/#" className="inline-flex text-cta-green text-sm font-semibold leading-140 items-center"> <PlusIcon className="mr-[0.625rem]" /> Add variable</a>
    <div className="mt-12">
      <Button onClick={onSubmit} type="primary" className="py-3 px-[0.875rem]" isDisabled={isSubmitting}>
        <div className="flex items-center gap-2">
          {isSubmitting ? 'Saving Settings...' : 'Save Settings' }
        </div>
      </Button>
    </div>
  </>
};

const SuspendDeleteForm = (props: { appName: string, appId: string, updateIsDeletingApp: (isDeleting: boolean) => void }) => {
  const { appName, appId, updateIsDeletingApp } = props;
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteAppErrorMessage, setDeleteAppErrorMessage] = useState('');
  const [verifyAppNameInput, setVerifyAppNameInput] = useState(() => ({
    value: '',
    isError: false,
    isValid: true,
    isTouched: false,
    errorMsg: ''
  }));

  const onChangeDeleteAppInput = (val: string) => {
    let isValid=true, errorMsg='';
    if (val !== appName) {
      isValid = false; errorMsg = 'App name entered does not match!';
    }
    setVerifyAppNameInput({
      ...verifyAppNameInput,
      value: val,
      isValid,
      errorMsg,
      isTouched: true
    });
  };

  const handleDeleteApp = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!verifyAppNameInput.isTouched) {
      return;
    }
    setIsDeleting(true);
    updateIsDeletingApp(true);
    trackEvent(AmplitudeEvent.CLICK_ON_DELETE_APP);
    try {
      await back4app2.deleteApp(appId as string);
      updateIsDeletingApp(false);
      navigate(`/apps`);
    } catch (err) {
      if (err instanceof NetworkError) {
        setDeleteAppErrorMessage('Network error when deleting app. Please check your internet connection or try again later.')
        }
      if (err instanceof AuthenticationError || e instanceof AuthorizationError) {
        window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
      } else {
        console.log('error deleting app', e);
        setDeleteAppErrorMessage('Something went wrong, please try again later.');
      }
    } finally {
      setIsDeleting(false);
    }
  }

  return <div className="flex space-y-6 flex-col">
    <div className="flex flex-col justify-start items-start">
      <div className="font-medium mb-[0.5rem]">Delete</div>
      <div className="text-sm mb-[1rem]">All resources for your App will stop working immediately. This action cannot be undone.</div>
      <button onClick={(e) => setOpenModal(true)} className="inline-flex items-center outline-none border border-regal-blue rounded-[5px] bg-transparent font-bold px-[0.625rem] py-[0.5rem]"><TrashOutlineIcon className="text-error-red mr-[0.625rem]" />Delete</button>
    </div>

    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <>
        <div className="flex w-full justify-center">
          <TriangleExclamationIcon className="text-error-red" width="40px" height="56px" />
        </div>
        <div className="font-semibold leading-140 text-dark text-center mb-2">Delete the App?</div>
        <TextInput 
          value={verifyAppNameInput.value} 
          label="Type the App name below to confirm." 
          name="confirmDeleteAppName" 
          onChange={(e) => onChangeDeleteAppInput(e.target.value.trim())} 
          placeholderText="App name"
          isError={!verifyAppNameInput.isValid}
          errorMessage={verifyAppNameInput.errorMsg}
          className="border border-light-grey border-opacity-100 w-full p-3 text-dark"
          labelClassName="font-normal text-dark opacity-70 text-sm justify-center"
          isLightThemed={true}
        />
        {deleteAppErrorMessage ? <span className='text-xs text-error-red'>{deleteAppErrorMessage}</span> : null}
        <div className="flex justify-end space-x-2 w-full mt-4">
          <Button className="text-dark-grey border border-light-grey rounded-[5px]" onClick={() => setOpenModal(false)}>Cancel</Button>
          <Button type="primary" isDisabled={!verifyAppNameInput.isTouched || !verifyAppNameInput.isValid} onClick={handleDeleteApp}>{isDeleting ? 'Deleting...' : 'Delete'}</Button>
        </div>
      </>
    </Modal>

  </div>
}

export default MyAppSettings