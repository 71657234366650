import {SHA256} from "crypto-js";

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export const pushGTMEvent = (eventName: string, includeEmail: boolean = false) => {
  const email = includeEmail ? localStorage.getItem('username') || '' : ''
  let hashedEmail = SHA256(email).toString();
  if (window && window.dataLayer) {
    window.dataLayer.push({ event: eventName, email: hashedEmail})
  }
}