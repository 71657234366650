import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ReactComponent as EnterIconSVG } from '../../assets/images/enter-icon.svg';
import { nextTick } from "process";
import LoadingSpinner from "../LoadingSpinner";

const AgentInput = forwardRef<{ setContent: (content: string) => void }, { onSend: (content: string) => Promise<boolean> }>(({ onSend }, ref) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [content, setContent] = useState<string>();
  const [isSending, setIsSending] = useState<boolean>(false);
  
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setContent(value);
  };

  useImperativeHandle(ref, () => ({
    setContent: (content: string) => {
      if (!isSending) {
        setContent(content);
        textareaRef.current?.focus();
      }
    }
  }));

  useEffect(
    () => {
      textareaRef.current?.focus();
    },
    []
  );

  useEffect(
    () => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHeight + 'px';
      }
    },
    [content]
  );

  const send = async () => {
    if (content) {
      setIsSending(true);
      if (await onSend(content)) {
        setContent('');
      }
      setIsSending(false);
      nextTick(() => {
        if (textareaRef.current) {
          textareaRef.current.focus();
        }
      });
    } else if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  return (
    <div className="absolute w-full bottom-0 left-0 rounded-lg pb-0">
      <div className="mx-12 lg:mx-44 bg-mid-blue border border-white/5 p-4 rounded rounded-lg">
        <div className="p-4 bg-dark rounded border outline-none border-light-blue flex gap-4 items-center w-full">          
          <textarea disabled={isSending} className="m-0 w-full resize-none bg-transparent outline-none"
            style={{
              maxHeight: '120px'
            }}
            ref={textareaRef}
            placeholder={'Write a message...' }
            value={content}
            rows={1}
            onChange={handleChange}
            onKeyDown={async (event) => {
              if (event.key.toLowerCase() === 'enter') {
                event.preventDefault();
                if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
                  if (textareaRef.current) {
                    const startPos = textareaRef.current.selectionStart;
                    const endPos = textareaRef.current.selectionEnd;
                    const value = textareaRef.current.value;
                    const newValue = value.substring(0, startPos) + '\n' + value.substring(endPos);

                    setContent(newValue);

                    nextTick(() => {
                      if (textareaRef.current) {
                        textareaRef.current.setSelectionRange(startPos + 1, startPos + 1);
                        if (textareaRef.current.value.length === startPos + 1) {
                          textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
                        }
                      }
                    });
                  }
                } else {
                  send();
                }
              }
            }}
          />
          {isSending ? (
            <LoadingSpinner
              width='2rem'
              height='2rem'
            />
          ): (
            <EnterIconSVG className="inline-block border border-regal-blue text-light-blue bg-regal-blue hover:cursor-pointer rounded-sm p-1" width="2rem" height="2rem" onClick={send} />
          )}
        </div>
      </div>
    </div>
  )
});

export default AgentInput;