import LoadingSpinner from './LoadingSpinner';

const LoadingMessage = ({ message }: { message: string }) => {
  return (<>
    <div className="grow flex flex-col items-center justify-center">
      <LoadingSpinner />
      <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
        {message}
      </div>
    </div>
  </>);
};

export default LoadingMessage;
