export enum ChipType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
  DEFAULT = 'DEFAULT'
}

const Chip = ({ text, type, className }: { text: string, type: ChipType, className?: string }) => {
  let chipColor = '';
  if (type === 'SUCCESS') {
    chipColor = 'bg-cta-green/25 border border-cta-green';
  } else if (type === 'ERROR') {
    chipColor = 'bg-error-red/25 border border-error-red';
  } else if (type === 'INFO') {
    chipColor = 'bg-regal-blue/25 border border-regal-blue';
  } else {
    chipColor = '';
  }

  return (
    <span className={`inline-block font-bold text-[0.625rem] leading-140 px-[0.625rem] py-[0.25rem] ${chipColor} rounded-xl uppercase ${className as string}`}>{text}</span>
  )
}

export default Chip