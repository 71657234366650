export const loadThirdPartyScript = ({ scriptUrl, scriptName }:{
  scriptUrl: string;
  scriptName: string;
}, callback: () => void) => {
  const existingScript = document.getElementById(scriptName);
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.id = scriptName;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

export const SOLUCX_SCRIPT_INFO = {
  scriptUrl: 'https://survey.solucx.com.br/widget.js',
  scriptName: 'solucx'
}