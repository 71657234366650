import { useEffect, useState } from 'react';
import { ReactComponent as MailCircleSVG } from '../../assets/images/mail-circle-icon.svg';
import back4app2 from '../../back4app2';
import CustomerErrorMessage from './CustomErrorMessage';
import toast from "react-hot-toast";
import { ReactComponent as StatusSuccessSVG } from '../../assets/images/status-success.svg';
import { ReactComponent as StatusErrorSVG } from '../../assets/images/status-error.svg';
import { DisposableEmailError } from '@back4app2/sdk';

const VerifyEmailErrorMessage = ({ userEmail }: { userEmail: string }) => {
  const [startTimer, setStartTimer] = useState(true);
  const [seconds, setSeconds] = useState(59);
  const [isloading, setIsloading] = useState(false);

  const maskedEmail = userEmail.split('@').map((str, idx) => idx === 0 ? str.substring(0, 2) + Array(str.length - 2).fill('*').join('') : str).join('@');

  useEffect(()=>{
    if (!startTimer) return;
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        setStartTimer(false);
        setSeconds(59);
      }
    }, 1000);
    return ()=> {
      clearInterval(myInterval);
    };
  }, [startTimer, seconds]);

  const onResendButtonClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsloading(true);
    try {
      await back4app2.resendVerificationMail();
      setStartTimer(true);
      toast.success('A verification mail has been sent!', {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
        icon: <StatusSuccessSVG width="24px" height="24px" className="animate-bounce-in" />
      });
    } catch (err) {
      let errMsg = 'Something went wrong!';
      let alertWidth = 'max-w-xs text-sm';
      if (err instanceof DisposableEmailError) {
        errMsg = err.message;
        alertWidth = 'max-w-3xl text-sm'
      }
      console.log('err calling api', err);
      toast.error(errMsg, {
        className:`bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] ${alertWidth}`,
        icon: <StatusErrorSVG width="24px" height="24px" className="animate-bounce-in flex-none" />,
        duration: 6000
      });
    } finally {
      setIsloading(false);
    }
  }

  return (
    <CustomerErrorMessage 
      type='warn' 
      icon={<MailCircleSVG width="28px" height="28px" className='text-alert-yellow flex-none' />} 
      headerText="Verify your email to proceed with the deployment" 
      description={<>
        <div className="mb-6">We sent an email to {maskedEmail} with a confirmation link. <br />Please check your inbox and verify. </div>
        <div className="">Didn’t receive it?<br />Check you Spam box, or {startTimer ? <>resend it in 0:{`${seconds < 10 ? '0' : ''}${seconds}`}</> : <button className='outline-none border border-old-blue rounded bg-old-blue/60 py-[2px] px-2 text-xs font-semibold' onClick={onResendButtonClick}>{isloading ? 'Sending...' : 'Resend the email'}</button>} </div>
      </>
    } />
  )
}

export default VerifyEmailErrorMessage;