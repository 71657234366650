import { useCallback, useEffect, useReducer, useRef } from 'react';
import { ReactComponent as ChevronLeftSVG } from '../assets/images/chevron-left.svg';
import { Agent, AgentPrompt, AgentsSubscriptionAmountHardLimitError, AuthenticationError, AuthorizationError, DuplicatedError, NetworkError, Subscription } from '@back4app2/sdk';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingMessage from '../components/LoadingMessage';
import ErrorMessage from '../components/ErrorMessage';
import back4app2 from '../back4app2';
import { BACK4APP_DOT_COM_SITE_URL } from '../settings';
import Button from '../components/Button';
import { ReactComponent as PlusSVG } from '../assets/images/plus-icon.svg';
import LoadingSpinner from '../components/LoadingSpinner';
import AIAgentList from '../components/AIAgentComp/AIAgentList';
import toast from 'react-hot-toast';
import { ReactComponent as StatusErrorSVG } from '../assets/images/status-error.svg';
import GhostMessage from '../components/GhostMessage';
import { ReactComponent as ErrorIconSVG } from '../assets/images/error-icon.svg';
import AIPromptList from '../components/AIAgentComp/AIPromptList';
import MyAgent from './MyAgent';
import { AmplitudeEvent, trackEvent } from '../utils/amplitude';

interface MyAgentsState {
  isFirstLoad: boolean;
  isLoading: boolean;
  loadingErrorMessage?: string;
  agents?: Agent[];  
  isSidebarOpen: boolean;
  isCreating: boolean;
  creatingErrorMessage?: string;
  isLoadingPrompts: boolean;
  prompts?: AgentPrompt[];
  loadingPromptsErrorMessage?: string;
  selectedPrompt?: AgentPrompt;
}

const INITIAL_STATE: MyAgentsState = {
  isFirstLoad: false,
  isLoading: true,
  isSidebarOpen: true,
  isCreating: false,
  isLoadingPrompts: true,
};

enum MyAgentsActionType {
  RESET,
  FINISH_LOADING,
  TOGGLE_SIDEBAR,
  CREATE,
  RESET_CREATING,
  FINISH_CREATING,
  RESET_CREATING_ERROR_MESSAGE,
  FINISH_UPDATING,
  FINISH_DELETING,
  FINISH_LOADING_PROMPTS,
  SET_SELECTED_PROMPT,
}

const reset = () => ({
  type: MyAgentsActionType.RESET
} as const);

const toggleSidebar = () => ({
  type: MyAgentsActionType.TOGGLE_SIDEBAR
} as const);

const finishLoading = (isFirstLoad: boolean, errorMessage?: string, agents?: Agent[]) => ({
  type: MyAgentsActionType.FINISH_LOADING,
  payload: {
    isFirstLoad,
    errorMessage,
    agents,
  }
} as const);

const finishLoadingPrompts = (errorMessage?: string, prompts?: AgentPrompt[]) => ({
  type: MyAgentsActionType.FINISH_LOADING_PROMPTS,
  payload: {
    errorMessage,
    prompts,
  }
} as const);

const resetCreating = () => ({
  type: MyAgentsActionType.RESET_CREATING
} as const);

const create = () => ({
  type: MyAgentsActionType.CREATE
} as const);

const finishCreating = (errorMessage?: string, agent?: Agent) => ({
  type: MyAgentsActionType.FINISH_CREATING,
  payload: {
    errorMessage,
    agent,
  }
} as const);

const resetCreatingErrorMessage = () => ({
  type: MyAgentsActionType.RESET_CREATING_ERROR_MESSAGE
} as const);

const finishUpdating = (agent: Agent) => ({
  type: MyAgentsActionType.FINISH_UPDATING,
  payload: {
    agent,
  }
} as const);

const finishDeleting = (agentId: string) => ({
  type: MyAgentsActionType.FINISH_DELETING,
  payload: {
    agentId,
  }
} as const);

const setSelectedPrompt = (prompt: AgentPrompt | undefined) => ({
  type: MyAgentsActionType.SET_SELECTED_PROMPT,
  payload: prompt,
} as const);

type MyAgentsAction = ReturnType<typeof reset> | ReturnType<typeof toggleSidebar> | ReturnType<typeof finishLoading> | ReturnType<typeof create> | ReturnType<typeof resetCreating> | ReturnType<typeof finishCreating> | ReturnType<typeof resetCreatingErrorMessage> | ReturnType<typeof finishUpdating> | ReturnType<typeof finishDeleting> | ReturnType<typeof finishLoadingPrompts> | ReturnType<typeof setSelectedPrompt>;

const reducer = (state: MyAgentsState = INITIAL_STATE, action: MyAgentsAction): MyAgentsState => {
  switch (action.type) {
    case MyAgentsActionType.RESET:
      return {
        ...INITIAL_STATE
      };
    
    case MyAgentsActionType.TOGGLE_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      }
    
    case MyAgentsActionType.FINISH_LOADING:
      return {
        ...state,
        isFirstLoad: action.payload.isFirstLoad,
        isLoading: false,
        loadingErrorMessage: action.payload.errorMessage,
        agents: (action.payload.agents && [...action.payload.agents]) || undefined
      }

    case MyAgentsActionType.RESET_CREATING:
      return {
        ...state,
        isCreating: false,
        creatingErrorMessage: undefined
      }

    case MyAgentsActionType.CREATE:
      return {
        ...state,
        isCreating: true,
        creatingErrorMessage: undefined
      }

    case MyAgentsActionType.FINISH_CREATING:
      return {
        ...state,
        isCreating: false,
        creatingErrorMessage: action.payload.errorMessage,
        agents: action.payload.agent ? [...((state.agents && state.agents.filter(agent => agent.id !== action.payload.agent!.id)) || []), action.payload.agent].sort((a, b) => a.name.localeCompare(b.name)) : state.agents
      }

    case MyAgentsActionType.RESET_CREATING_ERROR_MESSAGE:
      return {
        ...state,
        creatingErrorMessage: undefined
      }

    case MyAgentsActionType.FINISH_UPDATING:
      return {
        ...state,
        agents: [...((state.agents && state.agents.filter(agent => agent.id !== action.payload.agent.id)) || []), action.payload.agent].sort((a, b) => a.name.localeCompare(b.name))
      }

    case MyAgentsActionType.FINISH_DELETING:
      return {
        ...state,
        agents: (state.agents && state.agents.filter(agent => agent.id !== action.payload.agentId)) || []
      }
    
    case MyAgentsActionType.FINISH_LOADING_PROMPTS:
      return {
        ...state,
        isLoadingPrompts: false,
        loadingPromptsErrorMessage: action.payload.errorMessage,
        prompts: (action.payload.prompts && [...action.payload.prompts]) || undefined
      }

    case MyAgentsActionType.SET_SELECTED_PROMPT: 
      return {
        ...state,
        selectedPrompt: action.payload
      }
  }
};

const MyAgents = () => {
  const { agentId } = useParams();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { isFirstLoad, isLoading, loadingErrorMessage, agents, isSidebarOpen, isCreating, creatingErrorMessage, prompts } = state;
  const myAgentRef = useRef<{ setInputContent: (content: string) => void }>(null);

  const handleCreate = useCallback(async () => {
    dispatch(create());

    let agent;

    try {
      agent = await back4app2.createAgent();
    } catch (e) {
      if (e instanceof NetworkError) {
        console.error('network error', e);
        dispatch(finishCreating('Network error when creating agent. Check your internet connection.'));
      } else if (e instanceof AuthenticationError || e instanceof AuthorizationError) {
        window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
      } else if (e instanceof DuplicatedError) {
        dispatch(finishCreating('You already have an agent with this same name.'));
      } else if (e instanceof AgentsSubscriptionAmountHardLimitError) {
        dispatch(finishCreating('Please subscribe to a paying plan in order to create a new Agent.'));
      } else {
        console.error('unexpected error creating agent', e);
        dispatch(finishCreating('Unexpected error when creating agent.'));
      }

      return;
    }

    dispatch(finishCreating(undefined, agent));
    navigate(`/agents/${agent.id}`)
  }, [navigate]);

  useEffect(
    () => {
      dispatch(resetCreatingErrorMessage());
    },
    [agentId]
  );

  useEffect(
    () => {
      if (agentId && agents && !agents.find(agent => agent.id === agentId)) {
        navigate('/agents');
      }
    },
    [agentId, agents, navigate]
  );

  useEffect(
    () => {
      if (creatingErrorMessage && agentId) {
        toast.error(creatingErrorMessage, {
          className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
          icon: <StatusErrorSVG width="24px" height="24px" className="animate-bounce-in" />,
          duration: 6000
        });

        dispatch(resetCreatingErrorMessage());
      }
    },
    [creatingErrorMessage, agentId]
  );
  
  useEffect(
    () => {
      let isFirstLoad = true;
      let subscription: Subscription;

      subscription = back4app2.subscribeToAgents(
        (error, snapshot) => {

          if (error) {
            if (error instanceof NetworkError) {
              console.error('network error', error);
              dispatch(finishLoading(isFirstLoad, 'Network error when loading agents. Check your internet connection and try again.'));
            } else if (error instanceof AuthenticationError || error instanceof AuthorizationError) {
              window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
            } else {
              console.error('unexpected error loading agents', error);
              dispatch(finishLoading(isFirstLoad, 'Unexpected error when loading agents. Please try again.'));
            }
          } else {
            dispatch(finishLoading(isFirstLoad, undefined, snapshot));
          }

          isFirstLoad = false;
        }
      );

      trackEvent(AmplitudeEvent.AT_AGENT_UI);

      return () => {
        if (subscription) {
          subscription.unsubscribe();
          dispatch(reset());
        }
      };
    },
    []
  );

  useEffect(
    () => {
      let subscription: Subscription;

      subscription = back4app2.subscribeToAgentPrompts(
        (error, snapshot) => {

          if (error) {
            if (error instanceof NetworkError) {
              console.error('network error', error);
              dispatch(finishLoadingPrompts('Network error when loading agents. Check your internet connection and try again.'));
            } else if (error instanceof AuthenticationError || error instanceof AuthorizationError) {
              window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
            } else {
              console.error('unexpected error loading agents', error);
              dispatch(finishLoadingPrompts('Unexpected error when loading agents. Please try again.'));
            }
          } else {
            dispatch(finishLoadingPrompts(undefined, snapshot));
          }

        }
      );

      return () => {
        if (subscription) {
          subscription.unsubscribe();
          dispatch(reset());
        }
      };
    },
    []
  );

  useEffect(
    () => {
      if (!agentId && !isLoading && !isCreating && !creatingErrorMessage) {
        if (agents && agents.length > 0) {
          navigate(`/agents/${agents[0].id}`);
        } else {
          if (isFirstLoad) {
            handleCreate();
          }
        }        
      }
    },
    [
      agentId,
      isFirstLoad,
      isLoading,
      agents,
      isCreating,
      creatingErrorMessage,
      navigate,
      handleCreate
    ]
  )

  const handleCreateButtonClick = () => {
    navigate('/agents');
    trackEvent(AmplitudeEvent.AGENT_CREATE_ACTION);
    handleCreate();
  };

  const handleUpdate = async (agentId: string, name: string) => {
    let agent;

    try {
      agent = await back4app2.updateAgent(agentId, name);
    } catch (e) {
      let errorMessage;

      if (e instanceof NetworkError) {
        console.error('network error', e);
        errorMessage = 'Network error when updating agent. Check your internet connection.';
      } else if (e instanceof AuthenticationError || e instanceof AuthorizationError) {
        window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
        return;
      } else if (e instanceof DuplicatedError) {
        errorMessage = 'You already have an agent with this same name.';
      } else {
        console.error('unexpected error updating agent', e);
        errorMessage = 'Unexpected error when updating agent.';
      }

      toast.error(errorMessage as string, {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
        icon: <StatusErrorSVG width="24px" height="24px" className="animate-bounce-in" />,
        duration: 6000
      });

      return;
    }

    dispatch(finishUpdating(agent));
  };

  const handleDelete = async (agentId: string) => {
    try {
      await back4app2.deleteAgent(agentId);
    } catch (e) {
      let errorMessage;

      if (e instanceof NetworkError) {
        console.error('network error', e);
        errorMessage = 'Network error when deleting agent. Check your internet connection.';
      } else if (e instanceof AuthenticationError || e instanceof AuthorizationError) {
        window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
        return;
      } else {
        console.error('unexpected error deleting agent', e);
        errorMessage = 'Unexpected error when deleting agent.';
      }

      toast.error(errorMessage as string, {
        className:"bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-xs text-sm",
        icon: <StatusErrorSVG width="24px" height="24px" className="animate-bounce-in" />,
        duration: 6000
      });

      return;
    }

    dispatch(finishDeleting(agentId));
  };

  if (isLoading || (!agentId && !isCreating && !creatingErrorMessage && isFirstLoad)) {
    return (
      <LoadingMessage message="Loading agents..." />
    );
  }

  if (loadingErrorMessage) {
    return (
      <ErrorMessage message={loadingErrorMessage} />
    );
  }

  return (
    <div className='flex h-[calc(100vh-80px)] z-[1]'>
      <div
        className={`relative h-full flex-none bg-mid-blue transition-all duration-500 z-10 ${
          isSidebarOpen ? "w-[19.75rem]" : "w-4"
        }`}
      >
        <div
          className='absolute top-[2.25rem] -right-3 rounded-full p-1 bg-cta-green hover:cursor-pointer'
          onClick={() => dispatch(toggleSidebar())}
        >
          <ChevronLeftSVG
            className={`transition-all duration-500 transform ${
              isSidebarOpen ? "" : "rotate-180"
            }`}
          />
        </div>
        <div className='w-full overflow-x-hidden'>
          <div className='w-[19.75rem] px-6 pt-6'>
            <button disabled={isCreating} onClick={handleCreateButtonClick} className={`flex gap-2 items-center justify-start py-[0.625rem] font-medium text-lg px-4 rounded-lg bg-gradient-to-r from-[#208AEC] to-cta-green w-full mb-10 ${isCreating ? 'pointer-events-none cursor-not-allowed' : ''} transition-all duration-500 hover:cursor-pointer hover:drop-shadow-[1px_1px_10px_rgba(146,225,180,1)]`}>
              {isCreating ? (
                <>
                  <LoadingSpinner
                    width='18px'
                    height='18px'
                  />
                  Creating Agent...
                </>
              ) : (
                <>
                  <PlusSVG
                    className='border rounded-full p-1 inline-block'
                    width='18px'
                    height='18px'
                  />
                  New Agent
                </>
              )}
            </button>
            <AIAgentList
              agents={agents} 
              selectedAgentId={agentId}
              onSelected={(agentId: string) => {
                dispatch(resetCreatingErrorMessage());
                navigate(`/agents/${agentId}`);
              }} 
              onUpdate={handleUpdate}
              onDelete={handleDelete}
            />
          </div>
          <AIPromptList prompts={prompts || []} onClickPlay={(prompt: AgentPrompt) => {
            dispatch(setSelectedPrompt(prompt));
            if (myAgentRef.current) {
              myAgentRef.current?.setInputContent(prompt.content)
            }
            dispatch(setSelectedPrompt(undefined));
          }} />
        </div>
      </div>
      {agentId ? (
        <MyAgent agents={agents} agentId={agentId} isSidebarOpen={isSidebarOpen} ref={myAgentRef} />
      ) : (creatingErrorMessage ? (
        <div className="grow flex flex-col items-center justify-center">
          <ErrorIconSVG />
          <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey mb-8">
            {creatingErrorMessage}
          </div>
          {creatingErrorMessage === 'Please subscribe to a paying plan in order to create a new Agent.' && (
            <Button type='submit'><a role="button" target="_blank" rel='noreferrer noopener' href={`${BACK4APP_DOT_COM_SITE_URL}/pricing/agent${agents && agents.length > 0 ? `?appId=${agents[0].id}&type=agent` : ''}`}>Pay Now</a></Button>
          )}
        </div>
      ) : (isCreating ? (
          <LoadingMessage message="Creating agent..." />
        ) : (
          <GhostMessage />
        )
      ))}
    </div>
  );
}

export default MyAgents;
