import { ReactComponent as OutOfMemorySVG } from '../../assets/images/out-of-memory-icon.svg';
import { BACK4APP_DOT_COM_SITE_URL } from '../../settings';
import Button from '../Button';
import CustomerErrorMessage from './CustomErrorMessage';

const OutOfMemoryError = ({ appId }: { appId: string }) => {
  return (
    <CustomerErrorMessage type='error' icon={<OutOfMemorySVG width="27px" height="26px" className='text-error-red flex-none' />} headerText="Out of Memory" description={
      <>
        <div className="text-sm font-bold">Container Memory Limit Exceeded!</div>
        <div className="text-sm mb-6">Insufficient memory has caused your project build to fail. To resolve this issue, consider upgrading to a larger container with increased memory capacity for successful builds and optimal performance.</div>
        <Button type='submit'><a role="button" target="_blank" rel='noreferrer noopener' href={`${BACK4APP_DOT_COM_SITE_URL}/pricing/container-as-a-service?appId=${appId}&type=containers`}>Upgrade Now</a></Button>
      </>
    } />
  )
}

export default OutOfMemoryError