import TimeGraph from './TimeGraph';

const NETGraph = ({ data, colors }: { data: { time: number, [containerId: string]: number }[], colors: { [containerId: string]: string } }) => {
  return <>
    <TimeGraph
      data={data}
      colors={colors}
      timeWindow={3 * 60 * 60 * 1000}
      yAxisProps={{
        tickFormatter: value => {
          const units = ['B/s', 'kB/s', 'MB/s', 'GB/s', 'TB/s', 'PB/s', 'EB/s', 'ZB/s', 'YB/s'];
          let i;

          for (i = 0; i < units.length - 1; i++) {
            if (value < 1000) {
              break;
            }

            value = value / 1000;
          }

          return `${value.toFixed(1).replace(/\.0$/, '')}${units[i]}`;
        },
      }}
    />
  </>
};

export default NETGraph;
