import { SOLUCX_API_KEY } from "../settings";
import { loadThirdPartyScript, SOLUCX_SCRIPT_INFO } from "./loadThirdPartyScript";

const monthQuarter = {
  '0': 'Q1',
  '1': 'Q2',
  '2': 'Q3',
  '3': 'Q4'
} as const;

type MonthQuarterKeys = keyof typeof monthQuarter;
declare var createSoluCXWidget: any;

export const initializeSolucxForm = (user: { id: string; username: string; disableSolucxForm: boolean; createdAt: Date; }) => {
  if (!SOLUCX_API_KEY) return;

  if (user.disableSolucxForm) {
    return;
  }
  const now = new Date();
  const hourDiff = Math.floor(Math.abs(now.getTime() - new Date(user.createdAt).getTime()) / 36e5);
  if(hourDiff === 0){
    return;
  }
  // Flow1 are users who signed up less than 30 days ago (720 hours)
  const isFlow1 = hourDiff <= 720 ? true : false;
  let transactionId = user.id;
  if(!isFlow1){
    const idx = ((now.getMonth() / 3) | 0).toString() as MonthQuarterKeys;
    const quarter = monthQuarter[idx];
    transactionId += `${now.getFullYear()}${quarter}`;
  }
  const options = {
    transaction_id: transactionId,
    store_id: isFlow1 ? '1001' : '1002',
    name: user.username,
    email: user.username,
    journey: isFlow1 ? 'csat-back4app' : 'nps-back4app',
    param_requestdata: ''
  };
  let retryInterval = isFlow1 ? 5 : 45;
  let collectInterval = isFlow1 ? 30 : 90;
  options['param_requestdata'] = encodeURIComponent(JSON.stringify({
    user,
    options,
    localStorage: localStorage.getItem('solucxWidgetLog-' + user.username)
  }));
  

  // let createSoluCXWidget: any;
  loadThirdPartyScript(SOLUCX_SCRIPT_INFO, () => {
    if (typeof createSoluCXWidget === "function") {
      createSoluCXWidget(
        SOLUCX_API_KEY,
        'bottomBoxLeft',
        options,
        { collectInterval, retryAttempts: 1, retryInterval }
      );
    }
  })
}