type Option = {
  displayText: string,
  value: string
}
interface SelectInputProps {
  label?: String,
  optionList: Option[],
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
  value?: string,
  isDisabled?: Boolean,
  // infoText?: string
}

const SelectInput = (props: SelectInputProps) => {
  const { label, optionList, onChange, isDisabled=false, /* infoText, */ value } = props;
  return (
    <div className="w-full">
      {label && <label className="inline-block font-medium mb-[0.4rem]">{label}</label>}
      <select 
        className={`outline-none leading-140 h-[50px] bg-transparent border border-light-blue duration-300 opacity-70 border-opacity-20 rounded w-full p-3 ${isDisabled ? 'cursor-not-allowed' : 'hover:border-opacity-70 hover:opacity-100'}`}
        onChange={onChange}
        value={value}
      >
        {optionList.map((opt, idx) => (
          <option key={idx} value={opt.value}>{opt.displayText}</option>
        ))}
      </select>
    </div>
  )
}

export default SelectInput;