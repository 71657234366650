import { Plan } from "@back4app2/sdk";
import { BACK4APP_DOT_COM_SITE_URL } from "../settings";

const PlanWidget = ({ plan, appId, freePlanUsageHours }: { plan?: Plan, appId: string, freePlanUsageHours?: number }) => {
  let planRAM = '';
  if (plan?.maxRAM) {
    planRAM = plan.maxRAM >= 1024 ? `${plan.maxRAM / 1024}GB` : `${plan.maxRAM}MB`;
  }
  return <div className="rounded p-3 small:py-3 small:px-4 overflow-hidden bg-white/[0.06] w-full h-full">
    <div className="flex justify-between items-center mb-6">
      <span className='uppercase text-lg leading-140'>{plan?.name} plan</span>
      <a role="button" target="_blank" rel='noreferrer noopener' href={`${BACK4APP_DOT_COM_SITE_URL}/pricing/container-as-a-service?appId=${appId}&type=containers`} className="border rounded-[5px] border-cta-green text-cta-green inline-block py-2 px-2.5 text-xs font-medium leading-140">Upgrade</a>
    </div>
    <div className="flex justify-between gap-2 items-center mb-2">
      <span className="text-sm">1 {plan?.name} Container</span>
      <span className="text-xs text-light-blue max-w-[50%]">{planRAM} {plan?.maxCPU} CPU</span>
    </div>
    {plan?.name.includes('Free') && typeof freePlanUsageHours === 'number' && (
      <div className="flex justify-between gap-2 items-center">
        <span className="text-sm">Free Plan Usage</span>
        <span className={`text-xs max-w-[50%] ${freePlanUsageHours >= 600 ? 'text-error-red' : 'text-light-blue'}`}>{freePlanUsageHours} / 600 hours</span>
      </div>
    )}
  </div>
}

export default PlanWidget;