import { App, AuthenticationError, AuthorizationError, ContainerDiskSize, ContainerStats, NetworkError, Subscription } from '@back4app2/sdk';
import { useOutletContext } from 'react-router-dom';
import { useEffect, useReducer } from 'react';
import { AmplitudeEvent, trackEvent } from '../utils/amplitude';
import back4app2 from '../back4app2';
import { BACK4APP_DOT_COM_SITE_URL } from '../settings';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorMessage from '../components/ErrorMessage';
import { ReactComponent as GhostIconSVG } from "../assets/images/ghost-icon.svg";
import CPUGraph from '../components/Charts/CPUGraph';
import RAMGraph from '../components/Charts/RAMGraph';
import PIDGraph from '../components/Charts/PIDGraph';
import NETGraph from '../components/Charts/NETGraph';
import DiskSizeGraph from '../components/Charts/DiskSizeGraph';
import { createRandomColor, getContainerStatsGraphData } from '../utils/metrics';

interface MyAppMetricsState {
  isLoadingDisksSizes: boolean;
  loadingDisksSizesErrorMessage?: string;
  containerDiskSizeGraphProps?: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string }, max?: number };
  diskSizeGraphProps?: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string }};
  isLoadingStats: boolean;
  loadingStatsErrorMessage?: string;
  colors?: { [containerId: string]: string };
  cpuGraphProps?: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string }};
  ramGraphProps?: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string }, max: number};
  pidGraphProps?: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string }, max: number};
  netInGraphProps?: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string }};
  netOutGraphProps?: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string }};
}

const INITIAL_STATE: MyAppMetricsState = {
  isLoadingDisksSizes: true,
  isLoadingStats: true
};

enum MyAppMetricsActionType {
  RESET,
  FINISH_LOADING_DISKS_SIZES,
  FINISH_LOADING_STATS
}

const reset = () => ({
  type: MyAppMetricsActionType.RESET
} as const);

const finishLoadingDisksSizes = (errorMessage?: string, disksSizes?: ContainerDiskSize[]) => ({
  type: MyAppMetricsActionType.FINISH_LOADING_DISKS_SIZES,
  payload: {
    errorMessage,
    disksSizes
  }
} as const);

const finishLoadingStats = (errorMessage?: string, stats?: ContainerStats[]) => ({
  type: MyAppMetricsActionType.FINISH_LOADING_STATS,
  payload: {
    errorMessage,
    stats
  }
} as const);

type MyAppMetricsAction = ReturnType<typeof reset> | ReturnType<typeof finishLoadingDisksSizes> | ReturnType<typeof finishLoadingStats>;

const reducer = (state: MyAppMetricsState = INITIAL_STATE, action: MyAppMetricsAction): MyAppMetricsState => {
  switch (action.type) {
    case MyAppMetricsActionType.RESET:
      return INITIAL_STATE;
    
    case MyAppMetricsActionType.FINISH_LOADING_DISKS_SIZES: {
      let colors = (state.colors && { ...state.colors }) || undefined;

      let containerDiskSizeGraphProps: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string }, max?: number } | undefined = undefined;
      let diskSizeGraphProps: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string } } | undefined = undefined;

      if (action.payload.disksSizes) {
        if (!colors) {
          colors = {};
        }

        const containerDiskSizeGraphData: { time: number, [containerId: string]: number }[] = [];
        const containerDiskSizeGraphColors: { [containerId: string]: string } = {};
        let containerDiskSizeGraphMax;

        const diskSizeGraphData: { time: number, [containerId: string]: number }[] = [];
        const diskSizeGraphColors: { [containerId: string]: string } = {};

        for (let i = 0; i < action.payload.disksSizes.length; i++) {
          const containerId = action.payload.disksSizes[i].container.id.split('-')[0];

          if (!colors[containerId]) {
            colors[containerId] = createRandomColor();
          }

          const containerMaxDiskSize = typeof action.payload.disksSizes[i].container.maxDiskSize === 'number' && (action.payload.disksSizes[i].container.maxDiskSize as number) * 1000 * 1000;

          containerDiskSizeGraphData.push({
            time: action.payload.disksSizes[i].time.getTime(),
            [containerId]: typeof containerMaxDiskSize === 'number' && action.payload.disksSizes[i].containerSize >= containerMaxDiskSize ? containerMaxDiskSize : action.payload.disksSizes[i].containerSize
          });

          if (!containerDiskSizeGraphColors[containerId]) {
            containerDiskSizeGraphColors[containerId] = colors[containerId];
          }

          if (typeof containerMaxDiskSize === 'number' && (typeof containerDiskSizeGraphMax !== 'number' || containerMaxDiskSize > (containerDiskSizeGraphMax as number))) {
            containerDiskSizeGraphMax = containerMaxDiskSize;
          }

          diskSizeGraphData.push({
            time: action.payload.disksSizes[i].time.getTime(),
            [containerId]: action.payload.disksSizes[i].size
          });

          if (!diskSizeGraphColors[containerId]) {
            diskSizeGraphColors[containerId] = colors[containerId];
          }
        }

        containerDiskSizeGraphProps = { data: containerDiskSizeGraphData, colors: containerDiskSizeGraphColors, max: containerDiskSizeGraphMax };
        diskSizeGraphProps = { data: diskSizeGraphData, colors: diskSizeGraphColors };
      }

      return {
        ...state,
        isLoadingDisksSizes: false,
        loadingDisksSizesErrorMessage: action.payload.errorMessage,
        colors,
        containerDiskSizeGraphProps,
        diskSizeGraphProps
      };
    }

    case MyAppMetricsActionType.FINISH_LOADING_STATS: {
      const { colors,
        cpuGraphProps,
        ramGraphProps,
        pidGraphProps,
        netInGraphProps,
        netOutGraphProps
      } = getContainerStatsGraphData(action.payload.stats || [], state.colors)

      return {
        ...state,
        isLoadingStats: false,
        loadingStatsErrorMessage: action.payload.errorMessage,
        colors,
        cpuGraphProps,
        ramGraphProps,
        pidGraphProps,
        netInGraphProps,
        netOutGraphProps
      };
    }
  }
};

const MyAppMetrics = () => {
  const { app } = useOutletContext<{ app: App }>();
  const mainServiceEnvironment = app && app.mainService && app.mainService.mainServiceEnvironment;
  const mainServiceEnvironmentId = mainServiceEnvironment && mainServiceEnvironment.id;
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  let { isLoadingDisksSizes, loadingDisksSizesErrorMessage, containerDiskSizeGraphProps, diskSizeGraphProps, isLoadingStats, loadingStatsErrorMessage, cpuGraphProps, ramGraphProps, pidGraphProps, netInGraphProps, netOutGraphProps } = state;

  useEffect(() => {
    trackEvent(AmplitudeEvent.AT_APP_METRICS_PAGE);
  }, []);

  useEffect(
    () => {
      let disksSizesSubscription: Subscription;
      let statsSubscription: Subscription;

      if (mainServiceEnvironmentId) {
        disksSizesSubscription = back4app2.subscribeToContainersDisksSizes(
          mainServiceEnvironmentId,
          3 * 60 * 60 * 1000,
          (error, snapshot) => {
            if (error) {
              if (error instanceof NetworkError) {
                console.error('network error', error);
                dispatch(finishLoadingDisksSizes('Network error when loading app disks sizes. Check your internet connection and try again.'));
              } else if (error instanceof AuthenticationError || error instanceof AuthorizationError) {
                window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
              } else {
                console.error('unexpected error loading app disks sizes', error);
                dispatch(finishLoadingDisksSizes('Unexpected error when loading app disks sizes. Please try again.'));
              }
            } else {
              dispatch(finishLoadingDisksSizes(undefined, snapshot));
            }
          }
        );

        statsSubscription = back4app2.subscribeToContainersStats(
          mainServiceEnvironmentId,
          3 * 60 * 60 * 1000,
          (error, snapshot) => {
            if (error) {
              if (error instanceof NetworkError) {
                console.error('network error', error);
                dispatch(finishLoadingStats('Network error when loading app stats. Check your internet connection and try again.'));
              } else if (error instanceof AuthenticationError || error instanceof AuthorizationError) {
                window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
              } else {
                console.error('unexpected error loading app stats', error);
                dispatch(finishLoadingStats('Unexpected error when loading app stats. Please try again.'));
              }
            } else {
              dispatch(finishLoadingStats(undefined, snapshot));
            }
          }
        );
      }

      return () => {
        if (disksSizesSubscription) {
          disksSizesSubscription.unsubscribe();          
        }

        if (statsSubscription) {
          statsSubscription.unsubscribe();          
        }

        dispatch(reset());
      };
    },
    [
      mainServiceEnvironmentId
    ]
  );

  return (<>
    <h1 className="font-sora text-[1.375rem] leading-[140%]">Metrics</h1>
    <div className="mt-10 flex flex-col gap-8">
      <div className="bg-dark-blue rounded-[1rem] px-[1.25rem] py-5 flex flex-col gap-2">
        <div className="font-sora text-xs leading-[140%] text-light-grey px-[1.25rem]">CPU</div>
        {isLoadingStats && mainServiceEnvironmentId ? (<>
          <div className="flex flex-col justify-center items-center">
            <LoadingSpinner />
            <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
              Loading
            </div>
          </div>
        </>) : (<>
          {loadingStatsErrorMessage ? (<>
            <ErrorMessage message={loadingStatsErrorMessage} />
          </>) : (<>
            {cpuGraphProps && cpuGraphProps.data.length > 0 ? (<>
              <div className="h-[13.3125rem] w-100">
                <CPUGraph
                  data={cpuGraphProps.data}
                  colors={cpuGraphProps.colors}
                />
              </div>
            </>) : (<>
              <div className="flex flex-col items-center">
                <GhostIconSVG />
                <div className="mt-4 text-xs leading-[140%] text-light-grey">
                  Nothing here, yet!
                </div>
              </div>
            </>)}
          </>)}
        </>)}
      </div>
      <div className="bg-dark-blue rounded-[1rem] px-[1.25rem] py-5 flex flex-col gap-2">
        <div className="font-sora text-xs leading-[140%] text-light-grey px-[1.25rem]">RAM</div>
        {isLoadingStats && mainServiceEnvironmentId ? (<>
          <div className="flex flex-col justify-center items-center">
            <LoadingSpinner />
            <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
              Loading
            </div>
          </div>
        </>) : (<>
          {loadingStatsErrorMessage ? (<>
            <ErrorMessage message={loadingStatsErrorMessage} />
          </>) : (<>
            {ramGraphProps && ramGraphProps.data.length > 0 ? (<>
              <div className="h-[13.3125rem] w-100">
                <RAMGraph
                  data={ramGraphProps.data}
                  colors={ramGraphProps.colors}
                  max={ramGraphProps.max}
                />
              </div>
            </>) : (<>
              <div className="flex flex-col items-center">
                <GhostIconSVG />
                <div className="mt-4 text-xs leading-[140%] text-light-grey">
                  Nothing here, yet!
                </div>
              </div>
            </>)}
          </>)}
        </>)}
      </div>
      <div className="bg-dark-blue rounded-[1rem] px-[1.25rem] py-5 flex flex-col gap-2">
        <div className="font-sora text-xs leading-[140%] text-light-grey px-[1.25rem]">PROCESSES</div>
        {isLoadingStats && mainServiceEnvironmentId ? (<>
          <div className="flex flex-col justify-center items-center">
            <LoadingSpinner />
            <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
              Loading
            </div>
          </div>
        </>) : (<>
          {loadingStatsErrorMessage ? (<>
            <ErrorMessage message={loadingStatsErrorMessage} />
          </>) : (<>
            {pidGraphProps && pidGraphProps.data.length > 0 ? (<>
              <div className="h-[13.3125rem] w-100">
                <PIDGraph
                  data={pidGraphProps.data}
                  colors={pidGraphProps.colors}
                  max={pidGraphProps.max}
                />
              </div>
            </>) : (<>
              <div className="flex flex-col items-center">
                <GhostIconSVG />
                <div className="mt-4 text-xs leading-[140%] text-light-grey">
                  Nothing here, yet!
                </div>
              </div>
            </>)}
          </>)}
        </>)}
      </div>
      <div className="bg-dark-blue rounded-[1rem] px-[1.25rem] py-5 flex flex-col gap-2">
        <div className="font-sora text-xs leading-[140%] text-light-grey px-[1.25rem]">NETWORK IN</div>
        {isLoadingStats && mainServiceEnvironmentId ? (<>
          <div className="flex flex-col justify-center items-center">
            <LoadingSpinner />
            <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
              Loading
            </div>
          </div>
        </>) : (<>
          {loadingStatsErrorMessage ? (<>
            <ErrorMessage message={loadingStatsErrorMessage} />
          </>) : (<>
            {netInGraphProps && netInGraphProps.data.length > 0 ? (<>
              <div className="h-[13.3125rem] w-100">
                <NETGraph
                  data={netInGraphProps.data}
                  colors={netInGraphProps.colors}
                />
              </div>
            </>) : (<>
              <div className="flex flex-col items-center">
                <GhostIconSVG />
                <div className="mt-4 text-xs leading-[140%] text-light-grey">
                  Nothing here, yet!
                </div>
              </div>
            </>)}
          </>)}
        </>)}
      </div>
      <div className="bg-dark-blue rounded-[1rem] px-[1.25rem] py-5 flex flex-col gap-2">
        <div className="font-sora text-xs leading-[140%] text-light-grey px-[1.25rem]">NETWORK OUT</div>
        {isLoadingStats && mainServiceEnvironmentId ? (<>
          <div className="flex flex-col justify-center items-center">
            <LoadingSpinner />
            <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
              Loading
            </div>
          </div>
        </>) : (<>
          {loadingStatsErrorMessage ? (<>
            <ErrorMessage message={loadingStatsErrorMessage} />
          </>) : (<>
            {netOutGraphProps && netOutGraphProps.data.length > 0 ? (<>
              <div className="h-[13.3125rem] w-100">
                <NETGraph
                  data={netOutGraphProps.data}
                  colors={netOutGraphProps.colors}
                />
              </div>
            </>) : (<>
              <div className="flex flex-col items-center">
                <GhostIconSVG />
                <div className="mt-4 text-xs leading-[140%] text-light-grey">
                  Nothing here, yet!
                </div>
              </div>
            </>)}
          </>)}
        </>)}
      </div>
      <div className="bg-dark-blue rounded-[1rem] px-[1.25rem] py-5 flex flex-col gap-2">
        <div className="font-sora text-xs leading-[140%] text-light-grey px-[1.25rem]">TOTAL DISK USAGE</div>
        {isLoadingDisksSizes && mainServiceEnvironmentId ? (<>
          <div className="flex flex-col justify-center items-center">
            <LoadingSpinner />
            <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
              Loading
            </div>
          </div>
        </>) : (<>
          {loadingDisksSizesErrorMessage ? (<>
            <ErrorMessage message={loadingDisksSizesErrorMessage} />
          </>) : (<>
            {diskSizeGraphProps && diskSizeGraphProps.data.length > 0 ? (<>
              <div className="h-[13.3125rem] w-100">
                <DiskSizeGraph
                  data={diskSizeGraphProps.data}
                  colors={diskSizeGraphProps.colors}
                />
              </div>
            </>) : (<>
              <div className="flex flex-col items-center">
                <GhostIconSVG />
                <div className="mt-4 text-xs leading-[140%] text-light-grey">
                  Nothing here, yet!
                </div>
              </div>
            </>)}
          </>)}
        </>)}
      </div>
      <div className="bg-dark-blue rounded-[1rem] px-[1.25rem] py-5 flex flex-col gap-2">
        <div className="font-sora text-xs leading-[140%] text-light-grey px-[1.25rem]">CONTAINER DISK USAGE</div>
        {isLoadingDisksSizes && mainServiceEnvironmentId ? (<>
          <div className="flex flex-col justify-center items-center">
            <LoadingSpinner />
            <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
              Loading
            </div>
          </div>
        </>) : (<>
          {loadingDisksSizesErrorMessage ? (<>
            <ErrorMessage message={loadingDisksSizesErrorMessage} />
          </>) : (<>
            {containerDiskSizeGraphProps && containerDiskSizeGraphProps.data.length > 0 ? (<>
              <div className="h-[13.3125rem] w-100">
                <DiskSizeGraph
                  data={containerDiskSizeGraphProps.data}
                  colors={containerDiskSizeGraphProps.colors}
                  max={containerDiskSizeGraphProps.max}
                />
              </div>
            </>) : (<>
              <div className="flex flex-col items-center">
                <GhostIconSVG />
                <div className="mt-4 text-xs leading-[140%] text-light-grey">
                  Nothing here, yet!
                </div>
              </div>
            </>)}
          </>)}
        </>)}
      </div>
    </div>
  </>);
};

export default MyAppMetrics;
