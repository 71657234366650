import { useMemo } from 'react';
import { App, AppStatus, DeploymentStatus, TaskStatus } from '@back4app2/sdk';
import StatusError from './StatusError';
import StatusSpinner from './StatusSpinner';
import StatusSuccess from './StatusSuccess';
import StatusQueued from './StatusQueued';

const MyAppStatus = ({ app }: { app: App }) => {
  const appStatus = app.status;
  const mainServiceEnvironment = app.mainService && app.mainService.mainServiceEnvironment;
  const mainServiceEnvironmentExists = !!mainServiceEnvironment;
  const isPendingPayment = mainServiceEnvironment && (mainServiceEnvironment.isPendingPayment || (!mainServiceEnvironment.isFreePlanElegible && mainServiceEnvironment.plan.name.includes('Free')))
  const lastDeployment = mainServiceEnvironment && mainServiceEnvironment.lastDeployment;
  const lastDeploymentStatus = lastDeployment && lastDeployment.status;
  const deployTaskStatus = lastDeployment && lastDeployment.deployTask && lastDeployment.deployTask.status;
  const activeDeployment = mainServiceEnvironment && mainServiceEnvironment.activeDeployment;
  const activeDeploymentExists = !!activeDeployment;

  const { Icon, text } = useMemo(
    () => {
      let Icon = StatusError;
      let text = 'Failed';

      if (appStatus === AppStatus.INITIALIZING) {
        Icon = StatusSpinner;
        text = 'Initializing';
      } else if (appStatus === AppStatus.PENDING_VERIFICATION) {
        Icon = StatusQueued;
        text = 'Pending Verification';
      } else if (
        appStatus === AppStatus.INITIALIZED &&
        mainServiceEnvironmentExists
      ) {
        if (isPendingPayment) {
          Icon = StatusQueued;
          text = 'Pending Payment';
        } else if (activeDeploymentExists) {
          Icon = StatusSuccess;
          text = 'Available';
        } else if (lastDeploymentStatus) {
          if (
            [DeploymentStatus.INITIALIZING, DeploymentStatus.INITIALIZED, DeploymentStatus.DEPLOYING, DeploymentStatus.QUEUED].includes(lastDeploymentStatus) &&
            deployTaskStatus !== TaskStatus.FAILED
          ) {
            Icon = StatusSpinner;
            text = 'Deploying';
          } else {
            Icon = StatusError;
            text = 'Unavailable';
          }
        } else {
          Icon = StatusSpinner;
          text = 'Initialized';
        }
      }

      return { Icon, text };
    },
    [
      appStatus,
      mainServiceEnvironmentExists,
      lastDeploymentStatus,
      deployTaskStatus,
      activeDeploymentExists,
      isPendingPayment
    ]
  )
  
  return (<>
    <div className="flex items-center">
      <Icon />
      <div className="ml-[0.3125rem] text-light-blue text-xs leading-[140%]">
        {text}
      </div>
    </div>
  </>);
}

export default MyAppStatus;
