import { ReactComponent as PauseIconSVG } from '../../assets/images/status-queued.svg';
import { BACK4APP_DOT_COM_SITE_URL } from '../../settings';
import Button from '../Button';
import CustomerErrorMessage from './CustomErrorMessage';

const SleepingContainerMessage = ({ appId }: { appId: string }) => {
  return (
    <CustomerErrorMessage type='warn' icon={<PauseIconSVG width="27px" height="26px" className='text-alert-yellow flex-none' />} headerText="Sleeping Container App" description={
      <>
        <div className="text-sm font-bold">Keep Your Application Active</div>
        <div className="text-sm mb-6">Your application's container have entered sleep mode as there have been no requests in the last 30 minutes. Upgrade to a Paid plan for uninterrupted service or ensure that requests are made to your application every 30 minutes to keep your containers active.</div>
        <Button type='submit'><a role="button" target="_blank" rel='noreferrer noopener' href={`${BACK4APP_DOT_COM_SITE_URL}/pricing/container-as-a-service?appId=${appId}&type=containers`}>Upgrade Now</a></Button>
      </>
    } />
  )
}

export default SleepingContainerMessage