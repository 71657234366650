"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseBack4app2Error = exports.CommandNotExecutingError = exports.BadDevelopmentEnvironmentError = exports.DevelopmentEnvironmentStatusError = exports.ChatMessageStatusError = exports.AgentStatusError = exports.AgentsSubscriptionChatMessagesHardLimitError = exports.AgentsSubscriptionAmountHardLimitError = exports.GitRepositoryEmptyError = exports.CustomDomainStatusError = exports.DNSVerificationError = exports.KanikoImageBuildError = exports.InvalidInputError = exports.CreditCardValidationRequiredError = exports.PaymentPendingError = exports.BlockedResourceError = exports.TimeoutError = exports.UserVerificationPendingError = exports.UnexpectedError = exports.GitHubReadWritePermissionError = exports.GitHubResourceNotFoundError = exports.GitHubInstallationError = exports.GitHubInstallationIdError = exports.DockerfileNotFoundError = exports.CreateParseAppError = exports.AppStatusError = exports.DeploymentStatusError = exports.DeploymentMemoryExceededError = exports.GitHubCodeError = exports.DisposableEmailError = exports.DuplicatedError = exports.NotFoundError = exports.CaptchaError = exports.AuthorizationError = exports.AuthenticationError = exports.InternalServerError = exports.NetworkError = exports.Back4app2Error = void 0;
var Back4app2Error_1 = require("./Back4app2Error");
Object.defineProperty(exports, "Back4app2Error", { enumerable: true, get: function () { return Back4app2Error_1.default; } });
var NetworkError_1 = require("./NetworkError");
Object.defineProperty(exports, "NetworkError", { enumerable: true, get: function () { return NetworkError_1.default; } });
var InternalServerError_1 = require("./InternalServerError");
Object.defineProperty(exports, "InternalServerError", { enumerable: true, get: function () { return InternalServerError_1.default; } });
var AuthenticationError_1 = require("./AuthenticationError");
Object.defineProperty(exports, "AuthenticationError", { enumerable: true, get: function () { return AuthenticationError_1.default; } });
var AuthorizationError_1 = require("./AuthorizationError");
Object.defineProperty(exports, "AuthorizationError", { enumerable: true, get: function () { return AuthorizationError_1.default; } });
var CaptchaError_1 = require("./CaptchaError");
Object.defineProperty(exports, "CaptchaError", { enumerable: true, get: function () { return CaptchaError_1.default; } });
var NotFoundError_1 = require("./NotFoundError");
Object.defineProperty(exports, "NotFoundError", { enumerable: true, get: function () { return NotFoundError_1.default; } });
var DuplicatedError_1 = require("./DuplicatedError");
Object.defineProperty(exports, "DuplicatedError", { enumerable: true, get: function () { return DuplicatedError_1.default; } });
var DisposableEmailError_1 = require("./DisposableEmailError");
Object.defineProperty(exports, "DisposableEmailError", { enumerable: true, get: function () { return DisposableEmailError_1.default; } });
var GitHubCodeError_1 = require("./GitHubCodeError");
Object.defineProperty(exports, "GitHubCodeError", { enumerable: true, get: function () { return GitHubCodeError_1.default; } });
var DeploymentMemoryExceededError_1 = require("./DeploymentMemoryExceededError");
Object.defineProperty(exports, "DeploymentMemoryExceededError", { enumerable: true, get: function () { return DeploymentMemoryExceededError_1.default; } });
var DeploymentStatusError_1 = require("./DeploymentStatusError");
Object.defineProperty(exports, "DeploymentStatusError", { enumerable: true, get: function () { return DeploymentStatusError_1.default; } });
var AppStatusError_1 = require("./AppStatusError");
Object.defineProperty(exports, "AppStatusError", { enumerable: true, get: function () { return AppStatusError_1.default; } });
var CreateParseAppError_1 = require("./CreateParseAppError");
Object.defineProperty(exports, "CreateParseAppError", { enumerable: true, get: function () { return CreateParseAppError_1.default; } });
var DockerfileNotFoundError_1 = require("./DockerfileNotFoundError");
Object.defineProperty(exports, "DockerfileNotFoundError", { enumerable: true, get: function () { return DockerfileNotFoundError_1.default; } });
var GitHubInstallationIdError_1 = require("./GitHubInstallationIdError");
Object.defineProperty(exports, "GitHubInstallationIdError", { enumerable: true, get: function () { return GitHubInstallationIdError_1.default; } });
var GitHubInstallationError_1 = require("./GitHubInstallationError");
Object.defineProperty(exports, "GitHubInstallationError", { enumerable: true, get: function () { return GitHubInstallationError_1.default; } });
var GitHubResourceNotFoundError_1 = require("./GitHubResourceNotFoundError");
Object.defineProperty(exports, "GitHubResourceNotFoundError", { enumerable: true, get: function () { return GitHubResourceNotFoundError_1.default; } });
var GitHubReadWritePermissionError_1 = require("./GitHubReadWritePermissionError");
Object.defineProperty(exports, "GitHubReadWritePermissionError", { enumerable: true, get: function () { return GitHubReadWritePermissionError_1.default; } });
var UnexpectedError_1 = require("./UnexpectedError");
Object.defineProperty(exports, "UnexpectedError", { enumerable: true, get: function () { return UnexpectedError_1.default; } });
var UserVerificationPendingError_1 = require("./UserVerificationPendingError");
Object.defineProperty(exports, "UserVerificationPendingError", { enumerable: true, get: function () { return UserVerificationPendingError_1.default; } });
var TimeoutError_1 = require("./TimeoutError");
Object.defineProperty(exports, "TimeoutError", { enumerable: true, get: function () { return TimeoutError_1.default; } });
var BlockedResourceError_1 = require("./BlockedResourceError");
Object.defineProperty(exports, "BlockedResourceError", { enumerable: true, get: function () { return BlockedResourceError_1.default; } });
var PaymentPendingError_1 = require("./PaymentPendingError");
Object.defineProperty(exports, "PaymentPendingError", { enumerable: true, get: function () { return PaymentPendingError_1.default; } });
var CreditCardValidationRequiredError_1 = require("./CreditCardValidationRequiredError");
Object.defineProperty(exports, "CreditCardValidationRequiredError", { enumerable: true, get: function () { return CreditCardValidationRequiredError_1.default; } });
var InvalidInputError_1 = require("./InvalidInputError");
Object.defineProperty(exports, "InvalidInputError", { enumerable: true, get: function () { return InvalidInputError_1.default; } });
var KanikoImageBuildError_1 = require("./KanikoImageBuildError");
Object.defineProperty(exports, "KanikoImageBuildError", { enumerable: true, get: function () { return KanikoImageBuildError_1.default; } });
var DNSVerificationError_1 = require("./DNSVerificationError");
Object.defineProperty(exports, "DNSVerificationError", { enumerable: true, get: function () { return DNSVerificationError_1.default; } });
var CustomDomainStatusError_1 = require("./CustomDomainStatusError");
Object.defineProperty(exports, "CustomDomainStatusError", { enumerable: true, get: function () { return CustomDomainStatusError_1.default; } });
var GitRepositoryEmptyError_1 = require("./GitRepositoryEmptyError");
Object.defineProperty(exports, "GitRepositoryEmptyError", { enumerable: true, get: function () { return GitRepositoryEmptyError_1.default; } });
var AgentsSubscriptionAmountHardLimitError_1 = require("./AgentsSubscriptionAmountHardLimitError");
Object.defineProperty(exports, "AgentsSubscriptionAmountHardLimitError", { enumerable: true, get: function () { return AgentsSubscriptionAmountHardLimitError_1.default; } });
var AgentsSubscriptionChatMessagesHardLimitError_1 = require("./AgentsSubscriptionChatMessagesHardLimitError");
Object.defineProperty(exports, "AgentsSubscriptionChatMessagesHardLimitError", { enumerable: true, get: function () { return AgentsSubscriptionChatMessagesHardLimitError_1.default; } });
var AgentStatusError_1 = require("./AgentStatusError");
Object.defineProperty(exports, "AgentStatusError", { enumerable: true, get: function () { return AgentStatusError_1.default; } });
var ChatMessageStatusError_1 = require("./ChatMessageStatusError");
Object.defineProperty(exports, "ChatMessageStatusError", { enumerable: true, get: function () { return ChatMessageStatusError_1.default; } });
var DevelopmentEnvironmentStatusError_1 = require("./DevelopmentEnvironmentStatusError");
Object.defineProperty(exports, "DevelopmentEnvironmentStatusError", { enumerable: true, get: function () { return DevelopmentEnvironmentStatusError_1.default; } });
var BadDevelopmentEnvironmentError_1 = require("./BadDevelopmentEnvironmentError");
Object.defineProperty(exports, "BadDevelopmentEnvironmentError", { enumerable: true, get: function () { return BadDevelopmentEnvironmentError_1.default; } });
var CommandNotExecutingError_1 = require("./CommandNotExecutingError");
Object.defineProperty(exports, "CommandNotExecutingError", { enumerable: true, get: function () { return CommandNotExecutingError_1.default; } });
__exportStar(require("./KanikoImageBuildError"), exports);
var parseBack4app2Error_1 = require("./parseBack4app2Error");
Object.defineProperty(exports, "parseBack4app2Error", { enumerable: true, get: function () { return parseBack4app2Error_1.default; } });
