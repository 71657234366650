import Button from '../../Button';
import styles from './MessageContainer.module.css';
import { ReactComponent as GithubIcon } from "../../../assets/images/github-logo.svg";
import { ReactComponent as ArrorIcon } from "../../../assets/images/arrow-right-up-square.svg";
import { GITHUB_APP_URL } from '../../../settings';
import { AmplitudeEvent, trackEvent } from '../../../utils/amplitude';

export interface MessageContainerProps {
  msgTitle: string;
  primaryIcon?: React.ReactNode;
  msgDesc?: string;
  showButton?: Boolean,
  buttonText?: string,
  buttonAction?: 'EDIT' | 'IMPORT';
  extra?: React.ReactNode
}

let windowObjectReference: Window | null = null;
const windowName = 'GithubIntegrationWindow';

const openGithubInPopup = ({url, title, w, h}: {url: string; title: string; w: number; h: number}) => {
  if (!(windowObjectReference === null || windowObjectReference.closed)) {
    windowObjectReference.focus();
  } 
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

  // eslint-disable-next-line no-restricted-globals
  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  // eslint-disable-next-line no-restricted-globals
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(url, title, 
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  )

  if (typeof window.focus === 'function' && newWindow) newWindow.focus();
  return newWindow;
}

const MessageContainer = ({msgTitle, primaryIcon, msgDesc = '', showButton = false, buttonText = '', buttonAction, extra }: MessageContainerProps) => {
  const onButtonClick = () => {
    if (buttonAction === 'EDIT')  trackEvent(AmplitudeEvent.EDIT_GITHUB_PERMISSION_CLICK);
    if (buttonAction === 'IMPORT') trackEvent(AmplitudeEvent.IMPORT_GITHUB_REPOSITORY_CLICK);
    windowObjectReference = openGithubInPopup({ url: GITHUB_APP_URL, title: windowName, w: 800, h: 600 });
  }

  return (
    <div className={styles.msgContainer}>
      {primaryIcon}
      <div className={styles.msgTitle}>
        {msgTitle}
      </div>
      {msgDesc && <div className={styles.msgDesc}>{msgDesc}</div>}
      {showButton && (
        <>
          <Button type="primary" onClick={onButtonClick} className={styles.connectBtn}>
            <GithubIcon />
            <span>{buttonText}</span>
            <ArrorIcon fill="#F9F9F9" />
          </Button>
          {extra}
          {/* <a href="/#" className={styles.link}>
            Connect another account
          </a> */}
        </>
      )}
    </div>
  );
};

export default MessageContainer;
