import React, { useEffect, useReducer, useRef } from 'react';
import { Link, Outlet, useLocation, useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { App, AppStatus, AuthenticationError, AuthorizationError, DisposableEmailError, NetworkError, NotFoundError, User } from '@back4app2/sdk';
import { BACK4APP_DOT_COM_SITE_URL } from '../settings';
import back4app2 from '../back4app2';
import LoadingSpinner from '../components/LoadingSpinner';
import MyAppStatus from '../components/MyAppStatus';
import { ReactComponent as LinkIconSVG } from '../assets/images/link-icon.svg';
import { ReactComponent as BranchIconSmallSVG } from '../assets/images/branch-icon-small.svg';
import { ReactComponent as MenuOverviewSVG } from '../assets/images/menu-overview.svg';
import { ReactComponent as MenuDeploymentsSVG } from '../assets/images/menu-deployments.svg';
import { ReactComponent as MenuLogsSVG } from '../assets/images/menu-logs.svg';
import { ReactComponent as BackIconSVG } from '../assets/images/back-icon.svg';
import { ReactComponent as StatusErrorSVG } from '../assets/images/status-error.svg';
import { ReactComponent as MenuMetricsSVG } from '../assets/images/menu-metrics.svg';
import { ReactComponent as MenuSettingsSVG } from '../assets/images/menu-settings.svg';
import ErrorMessage from '../components/ErrorMessage';
import MyAppActions from '../components/MyAppActions';
import Chip, { ChipType } from '../components/Chip';
import { toast } from 'react-hot-toast';

interface MyAppState {
  isLoading: boolean;
  loadingErrorMessage?: string;
  app?: App;
  appLoadedAt?: Date;
  isDeletingApp: boolean;
}

const INITIAL_STATE: MyAppState = {
  isLoading: true,
  isDeletingApp: false
};

enum MyAppActionType {
  RESET,
  FINISH_LOADING,
  SET_IS_DELETING_APP
}

const reset = () => ({
  type: MyAppActionType.RESET
} as const);

const finishLoading = (errorMessage?: string, app?: App) => ({
  type: MyAppActionType.FINISH_LOADING,
  payload: {
    errorMessage,
    app
  }
} as const);

const setIsDeletingApp = (isDeleting: boolean) => ({
  type: MyAppActionType.SET_IS_DELETING_APP,
  payload: isDeleting
} as const);

type MyAppAction = ReturnType<typeof reset> | ReturnType<typeof finishLoading> | ReturnType<typeof setIsDeletingApp>;

const reducer = (state: MyAppState = INITIAL_STATE, action: MyAppAction): MyAppState => {
  switch (action.type) {
    case MyAppActionType.RESET:
      return {
        ...INITIAL_STATE,
      };
    
    case MyAppActionType.FINISH_LOADING: {
      if (action.payload.errorMessage === 'App not found.' && state.isDeletingApp) {
        return {
          ...state,
          isLoading: false,
          appLoadedAt: new Date()
        }
      }
      return {
        ...state,
        isLoading: false,
        loadingErrorMessage: action.payload.errorMessage,
        app: action.payload.app,
        appLoadedAt: new Date()
      }
    }
    
    case MyAppActionType.SET_IS_DELETING_APP:
      return {
        ...state,
        isDeletingApp: action.payload
      }
  }
};

const MyApp = () => {
  const { appId } = useParams();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { isLoading, loadingErrorMessage, app, appLoadedAt } = state;
  const mainRef = useRef<HTMLDivElement>(null);
  const { user, freePlanUsageHours } = useOutletContext<{ user: User, freePlanUsageHours: number }>();

  useEffect(
    () => {
      const subscription = back4app2.subscribeToApp(appId as string, (error, snapshot) => {
        if (error) {
          if (error instanceof NetworkError) {
            console.error('network error', error);
            dispatch(finishLoading('Network error when loading app. Check your internet connection and try again.'));
          } else if (error instanceof AuthenticationError || error instanceof AuthorizationError) {
            window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
          } else if (error instanceof NotFoundError) {
            dispatch(finishLoading('App not found.'));
          } else {
            console.error('unexpected error loading app', error);
            dispatch(finishLoading('Unexpected error when loading app. Please try again.'));
          }
        } else {
          dispatch(finishLoading(undefined, snapshot));
        }        
      });

      return () => {
        subscription.unsubscribe();
        dispatch(reset());
      }
    },
    [appId]
  );

  const updateIsDeletingApp = (isDeleting: boolean) => dispatch(setIsDeletingApp(isDeleting));

  useEffect(() => {
    if (app?.status === AppStatus.PENDING_VERIFICATION && app?.error && app.error.code === DisposableEmailError.CODE) {
      toast.error(app.error.message, {
        className:`bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-3xl text-sm`,
        icon: <StatusErrorSVG width="24px" height="24px" className="animate-bounce-in flex-none" />,
        duration: 5000
      });
    }
  }, [app])

  if (isLoading) {
    return (<>
      <div className="grow flex flex-col justify-center items-center">
        <LoadingSpinner />
        <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
          Loading app
        </div>
      </div>
    </>);
  }

  if (loadingErrorMessage) {
    return (<>
      <ErrorMessage message={loadingErrorMessage} />
    </>);
  }

  return (<>
    <div className="flex min-h-[calc(100vh-5rem)]" >
      <aside className="top-0 min-h-full flex-none min-w-[18.5rem] max-w-[18.5rem] bg-dark-blue p-6 flex flex-col z-[2] overflow-y-auto">
        <div className="">
          <div className="flex justify-between items-center mb-4">
            <BackIconSVG width="25px" height="25px" className=" hover:cursor-pointer" role="button" onClick={() => navigate('/apps')} />
            <Chip text="beta" type={ChipType.SUCCESS} />
          </div>
          <div className="font-sora font-bold text-[1.375rem] leading-[140%] truncate">
            {app!.name}
          </div>
          <MyAppActions app={app!} />
          <div className="mt-4">
            <MyAppStatus app={app!} />
          </div>
          {app!.mainService && (<>
            <div className="mt-4 flex flex-col gap-1.5">
              {app!.mainService.mainServiceEnvironment && (<>
                <a target="_blank" href={`${window.location.protocol}//${app!.mainService.mainServiceEnvironment.mainCustomDomain.name}`} rel="noreferrer" className="text-cta-green text-xs inline-flex items-center gap-2 leading-[140%] max-w-full">
                  <span className='truncate hover:underline hover:underline-offset-2'>{app!.mainService.mainServiceEnvironment.mainCustomDomain.name}</span>
                  <LinkIconSVG className='flex-none' />
                </a>
              </>)}
              <div className="text-xs leading-[140%] truncate">
                <a target="_blank" href={`https://www.github.com/${app?.mainService?.repository.fullName}`} rel="noreferrer" className="text-light-blue text-xs inline-flex leading-[140%] max-w-full">
                  <span className='truncate hover:underline hover:underline-offset-2'>{app!.mainService.repository.fullName}</span>
                </a>
              </div>
              {app!.mainService.mainServiceEnvironment && (<>
                <div className="flex items-center">
                  <BranchIconSmallSVG />
                  <div className="ml-1 text-white text-xs leading-[140%] truncate">
                    {app!.mainService.mainServiceEnvironment.branchName}
                  </div>
                </div>
              </>)}
            </div>
          </>)}
        </div>
        <div className="grow pt-6 tall:pt-10 flex flex-col gap-4">
          <MenuItem
            Icon={MenuOverviewSVG}
            title="Overview"
            url={`/apps/${appId}`}
          />
          <MenuItem
            Icon={MenuDeploymentsSVG}
            title="Deployments"
            url={`/apps/${appId}/deployments`}
          />
          <MenuItem
            Icon={MenuLogsSVG}
            title="Logs"
            url={`/apps/${appId}/logs`}
          />
          <MenuItem
            Icon={MenuMetricsSVG}
            title="Metrics"
            url={`/apps/${appId}/metrics`}
          />
          <MenuItem
            Icon={MenuSettingsSVG}
            title="Settings"
            url={`/apps/${appId}/settings`}
          />
        </div>
      </aside>
      <main className="flex-1 px-20 pt-14 pb-10 overflow-y-auto" ref={mainRef}>
        <Outlet context={{ app, appLoadedAt, mainRef, user, freePlanUsageHours, updateIsDeletingApp }} />
      </main>
    </div>
  </>);
};

interface MenuItemProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  url: string;
}

const MenuItem = ({ Icon, title, url }: MenuItemProps) => {
  const { pathname } = useLocation();

  const isActive = pathname === url;
  
  return (<>
    <Link to={url} className={`${isActive ? 'bg-white rounded-md' : 'transition-all duration-300 hover:gap-6'} gap-4 py-2.5 px-3.5 flex items-center`}>
      <Icon className={isActive ? 'text-cta-green' : 'text-cta-white'} />
      <div className={`${isActive ? 'text-dark' : 'text-white'} leading-[140%]`}>
        {title}
      </div>
    </Link>
  </>);
};

export default MyApp;
