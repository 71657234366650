import styles from './ToggleSwitchCheckbox.module.css';

interface ToggleSwitch {
  value: Boolean,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelText: string,
  description?: string
}

const ToggleSwitchCheckbox = ({ value, onChange, labelText, description }: ToggleSwitch) => {
  return (
    <div>
      <div className={styles.labelText}>{labelText}</div>
      {description ? <div className={styles.descText}>{description}</div> : null}
      <label className={styles.toggleSwitch}>
        <input type="checkbox" className={styles.checkbox} checked={!!value} onChange={onChange} />
        <span className={styles.knob}></span>
        <span className={`${styles.statusText} ${styles.checkedText}`}>Yes</span>
        <span className={`${styles.statusText} ${styles.unCheckedText}`}>No</span>
      </label>
    </div>
  )
}

export default ToggleSwitchCheckbox;