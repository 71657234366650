import { Deployment, DeploymentStatus, DeploymentStatusError, TaskStatus } from '@back4app2/sdk';
import { useEffect, useState } from 'react';
import back4app2 from '../back4app2';
import Button from "./Button"
import Modal from "./Modal";

interface CancelBuildProps {
  deployment: Deployment;
}

const inProgressDeploymentStatus = [DeploymentStatus.INITIALIZING, DeploymentStatus.INITIALIZED, DeploymentStatus.DEPLOYING, DeploymentStatus.QUEUED];

const CancelBuildButton = ({ deployment } : CancelBuildProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    return () => {
      setOpenModal(false);
      setErrorMessage('');
    }
  }, []);

  const openCancelBuildModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setOpenModal(true);
  }

  const onCancelBuild = async (deployment: Deployment) => {
    setErrorMessage('');
    setIsLoading(true);
    try {
      await back4app2.cancelDeploymentBuild(deployment.id);
      setOpenModal(false);
    } catch (err) {
      if (err instanceof DeploymentStatusError) {
        setErrorMessage('Sorry, the build cannot be canceled!');
      } else {
        console.error('error in cancelling the build', err);
        setErrorMessage('Sorry, something went wrong while canceling build!');
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    inProgressDeploymentStatus.includes(deployment.status) && (!deployment.deployTask || [TaskStatus.WAITING, TaskStatus.DOING].includes(deployment.deployTask.status)) ?
    <>
      <button className='outline-none border border-error-red rounded-[5px] bg-error-red/60 py-[0.125rem] px-2 text-xs font-semibold transition hover:bg-error-red' onClick={openCancelBuildModal}>Cancel</button>
      <div onClick={e => e.stopPropagation()}>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <div className="font-semibold leading-140 text-dark text-center mb-2">Cancel build?</div>
          {errorMessage ? <div className="text-sm text-dark/70 text-center">{errorMessage}</div> : (
            <>
              <div className="text-sm text-dark/70 text-center">{deployment.id}</div>
              <div className="flex justify-center space-x-2 w-full mt-4">
                <Button className="text-dark-grey border border-light-grey text-xs px-[0.625rem] py-2 font-semibold rounded-[5px]" onClick={() => setOpenModal(false)}>No</Button>
                <button className="bg-error-red text-xs px-[0.625rem] py-2 font-semibold rounded-[5px] text-white" onClick={isLoading ? undefined : ()=> onCancelBuild(deployment)}>{isLoading ? 'Canceling...' : 'Yes, Cancel'}</button>
              </div>
            </>
          )}
        </Modal>
      </div>
    </> : null
  )
}

export default CancelBuildButton;