import { DEFAULT_STATS_TIME_WINDOW } from '../../utils/metrics';
import TimeGraph from './TimeGraph';

const RAMGraph = ({ data, colors, max, hideLegend, axisLegendColor, yAxisTicksCount=5, timeWindow = DEFAULT_STATS_TIME_WINDOW }: { data: { time: number, [containerId: string]: number }[], colors: { [containerId: string]: string }, max: number, hideLegend?: boolean, axisLegendColor?: string, yAxisTicksCount?: number, timeWindow?: number}) => {
  let ticks = [0];
  for (let idx = 1; idx < yAxisTicksCount - 1; idx++) {
    ticks.push((idx * max)/(yAxisTicksCount-1));
  }
  ticks.push(max);

  return <>
    <TimeGraph
      data={data}
      colors={colors}
      timeWindow={timeWindow}
      yAxisProps={{
        domain: [0, max],
        tickFormatter: value => {
          const units = ['B', 'kiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
          let i;

          for (i = 0; i < units.length - 1; i++) {
            if (value < 1024) {
              break;
            }

            value = value / 1024;
          }

          return `${value.toFixed(1).replace(/\.0$/, '')}${units[i]}`;
        },
        ticks
      }}
      hideLegend={!!hideLegend}
      axisLegendColor={axisLegendColor}
    />
  </>
};

export default RAMGraph;
