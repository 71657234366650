import { App, AuthenticationError, AuthorizationError, ContainerLog, NetworkError, Subscription } from '@back4app2/sdk';
import { useEffect, useReducer } from 'react';
import { useOutletContext } from 'react-router-dom';
import back4app2 from '../back4app2';
import LoadingSpinner from '../components/LoadingSpinner';
import { BACK4APP_DOT_COM_SITE_URL } from '../settings';
import { AmplitudeEvent, trackEvent } from '../utils/amplitude';
import ContainerLogBox from '../components/ContainerLogBox';

interface MyAppLogsState {
  isLoading: boolean;
  loadingErrorMessage?: string;
  logs?: ContainerLog[];
  autoScroll: boolean;
}

const INITIAL_STATE: MyAppLogsState = {
  isLoading: true,
  autoScroll: true
};

enum MyAppLogsActionType {
  RESET,
  FINISH_LOADING,
  SET_AUTO_SCROLL
}

const reset = () => ({
  type: MyAppLogsActionType.RESET
} as const);

const finishLoading = (errorMessage?: string, logs?: ContainerLog[]) => ({
  type: MyAppLogsActionType.FINISH_LOADING,
  payload: {
    errorMessage,
    logs
  }
} as const);

const setAutoScroll = (value: boolean) => ({
  type: MyAppLogsActionType.SET_AUTO_SCROLL,
  payload: {
    value
  }
} as const);

type MyAppLogsAction = ReturnType<typeof reset> | ReturnType<typeof finishLoading> | ReturnType<typeof setAutoScroll>;

const reducer = (state: MyAppLogsState = INITIAL_STATE, action: MyAppLogsAction): MyAppLogsState => {
  switch (action.type) {
    case MyAppLogsActionType.RESET:
      return INITIAL_STATE;
    
    case MyAppLogsActionType.FINISH_LOADING:
      return {
        ...state,
        isLoading: false,
        loadingErrorMessage: action.payload.errorMessage,
        logs: action.payload.logs
      };

    case MyAppLogsActionType.SET_AUTO_SCROLL:
      return {
        ...state,
        autoScroll: action.payload.value
      };
  }
};

const MyAppLogs = () => {
  const { app } = useOutletContext<{ app: App }>();
  const mainServiceEnvironment = app && app.mainService && app.mainService.mainServiceEnvironment;
  const mainServiceEnvironmentId = mainServiceEnvironment && mainServiceEnvironment.id;
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  let { isLoading, loadingErrorMessage, logs } = state;

  useEffect(() => {
    trackEvent(AmplitudeEvent.AT_APP_LOGS_PAGE);
  }, []);

  useEffect(
    () => {
      let subscription: Subscription;

      if (mainServiceEnvironmentId) {
        subscription = back4app2.subscribeToContainersLogs(
          mainServiceEnvironmentId,
          (error, snapshot) => {
            if (error) {
              if (error instanceof NetworkError) {
                console.error('network error', error);
                dispatch(finishLoading('Network error when loading app logs. Check your internet connection and try again.'));
              } else if (error instanceof AuthenticationError || error instanceof AuthorizationError) {
                window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
              } else {
                console.error('unexpected error loading app logs', error);
                dispatch(finishLoading('Unexpected error when loading app logs. Please try again.'));
              }
            } else {
              dispatch(finishLoading(undefined, snapshot));
            }
          }
        );
      }

      return () => {
        if (subscription) {
          subscription.unsubscribe();
          dispatch(reset());
        }
      };
    },
    [
      mainServiceEnvironmentId
    ]
  );

  return (<div className='flex flex-col h-[calc(100vh-50px-12rem)]'>
    <h1 className="font-sora text-[1.375rem] leading-[140%] flex-initial">Logs</h1>
    <div className="mt-6 flex-auto max-h-full h-full">
      {isLoading && mainServiceEnvironmentId ? (<div className='flex flex-col justify-center items-center h-full'>
        <div className="flex flex-col justify-center items-center">
          <LoadingSpinner />
          <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
            Loading logs
          </div>
        </div>
      </div>) : (<>
        <ContainerLogBox isLoading={isLoading} errorMessage={loadingErrorMessage} logs={logs} canMaximize fullHeight />
      </>)}
    </div>
  </div>);
};

export default MyAppLogs;
