import { YAxisProps } from 'recharts';
import TimeGraph from './TimeGraph';

const DiskSizeGraph = ({ data, colors, max }: { data: { time: number, [containerId: string]: number }[], colors: { [containerId: string]: string }, max?: number }) => {
  const yAxisProps: YAxisProps = {
    tickFormatter: value => {
      const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let i;

      for (i = 0; i < units.length - 1; i++) {
        if (value < 1000) {
          break;
        }

        value = value / 1000;
      }

      return `${value.toFixed(1).replace(/\.0$/, '')}${units[i]}`;
    },
  };

  if (typeof max === 'number') {
    yAxisProps.ticks = [
      0,
      max / 4,
      max / 2,
      3 * max / 4,
      max
    ];
  }

  return <>
    <TimeGraph
      data={data}
      colors={colors}
      timeWindow={3 * 60 * 60 * 1000}
      yAxisProps={yAxisProps}
    />
  </>
};

export default DiskSizeGraph;
