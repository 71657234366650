import { ReactComponent as CircleExclamationSVG } from '../../assets/images/circle-exclaimation-icon.svg';
import Button from '../Button';
import CustomerErrorMessage from './CustomErrorMessage';

const CreditCardValidationRequiredErrorMessage = () => {
  return (
    <CustomerErrorMessage type='error' icon={<CircleExclamationSVG width="27px" height="26px" className='text-error-red flex-none' />} headerText="Pending Payment" description={
      <>
        <div className="text-sm font-bold">Please Validate Your Credit Card.</div>
          <div className="text-sm mb-6">For security purposes, we require you to confirm your credit card information. A $1.00 charge will be made to your card and refunded shortly after.</div>
          <Button type='submit'><a role="button" target="_blank" rel='noreferrer noopener' href='https://checkout.back4app.io/subscription/0EeFjudf6H'>Validate Card</a></Button>
      </>
    } />
  )
}

export default CreditCardValidationRequiredErrorMessage;