"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var Back4app2Error_1 = require("./Back4app2Error");
var DNSVerificationError = /** @class */ (function (_super) {
    __extends(DNSVerificationError, _super);
    function DNSVerificationError(message) {
        var _this = _super.call(this, message, DNSVerificationError.CODE) || this;
        Object.setPrototypeOf(_this, DNSVerificationError.prototype);
        return _this;
    }
    DNSVerificationError.CODE = 'DNS_VERIFICATION_ERROR';
    return DNSVerificationError;
}(Back4app2Error_1.default));
exports.default = DNSVerificationError;
