import { useMemo } from 'react';
import { Deployment, DeploymentStatus, TaskStatus } from '@back4app2/sdk';
import StatusError from './StatusError';
import StatusSpinner from './StatusSpinner';
import StatusSuccess from './StatusSuccess';
import StatusCancel from './StatusCancel';
import StatusQueued from './StatusQueued';

const MyAppDeploymentStatus = ({ deployment }: { deployment: Deployment }) => {
  const deploymentStatus = deployment.status;
  const deployTaskStatus = deployment.deployTask && deployment.deployTask.status;

  const { Icon, text } = useMemo(
    () => {
      let Icon = StatusError;
      let text = 'Failed';

      if (deploymentStatus === DeploymentStatus.INITIALIZING && deployTaskStatus !== TaskStatus.FAILED) {
        Icon = StatusSpinner;
        text = 'Initializing';
      } else if (
        [DeploymentStatus.INITIALIZED, DeploymentStatus.DEPLOYING].includes(deploymentStatus) &&
        deployTaskStatus !== TaskStatus.FAILED
      ) {
        Icon = StatusSpinner;
        text = 'Deploying';
      } else if (
        deploymentStatus === DeploymentStatus.QUEUED &&
        deployTaskStatus !== TaskStatus.FAILED
      ) {
        Icon = StatusQueued;
        text = 'Queued';
      } else if (
        deploymentStatus === DeploymentStatus.READY
      ) {
        Icon = StatusSuccess;
        text = 'Ready';
      } else if (
        deploymentStatus === DeploymentStatus.SLEEPY
      ) {
        Icon = StatusSpinner;
        text = 'Sleepy';
      } else if (
        deploymentStatus === DeploymentStatus.SLEEPING
      ) {
        Icon = StatusQueued;
        text = 'Sleeping';
      } else if (
        deploymentStatus === DeploymentStatus.WAKING_UP
      ) {
        Icon = StatusSpinner;
        text = 'Waking up';
      } else if (
        deploymentStatus === DeploymentStatus.RENEWING
      ) {
        Icon = StatusSpinner;
        text = 'Renewing';
      } else if (
        deploymentStatus === DeploymentStatus.DESTROYING
      ) {
        Icon = StatusSpinner;
        text = 'Destroying';
      } else if (
        deploymentStatus === DeploymentStatus.DESTROYED
      ) {
        Icon = StatusCancel;
        text = 'Destroyed';
      } else if (
        deploymentStatus === DeploymentStatus.CANCELING &&
        deployTaskStatus !== TaskStatus.FAILED
      ) {
        Icon = StatusSpinner;
        text = 'Canceling';
      } else if (
        deploymentStatus === DeploymentStatus.CANCELED
      ) {
        Icon = StatusCancel;
        text = 'Canceled';
      }
      
      return { Icon, text };
    },
    [
      deploymentStatus,
      deployTaskStatus
    ]
  )
  
  return (<>
    <div className="flex items-center">
      <Icon />
      <div className="ml-[0.3125rem] text-light-blue text-xs leading-[140%]">
        {text}
      </div>
    </div>
  </>);
}

export default MyAppDeploymentStatus;
