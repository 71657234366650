
import { useEffect, useRef, useState } from 'react';
import { Agent } from '@back4app2/sdk';

import { ReactComponent as EditPenSVG } from '../../assets/images/edit-pen-icon.svg';
import { ReactComponent as TrashOutlineSVG } from '../../assets/images/trash-outline-icon.svg';
import { ReactComponent as CloseSVG } from '../../assets/images/close-icon.svg';
import { ReactComponent as CheckSVG } from '../../assets/images/check-icon.svg';
import { nextTick } from 'process';
import LoadingSpinner from '../LoadingSpinner';
import { Link } from 'react-router-dom';
import { AmplitudeEvent, trackEvent } from '../../utils/amplitude';


interface AIAgentListProps {
  agents?: Agent[], 
  selectedAgentId?: string,
  onSelected: (agentId: string) => void,
  onUpdate: (agentId: string, name: string) => Promise<void>,
  onDelete: (agentId: string) => Promise<void>,
}

const AIAgentList = ({ agents, selectedAgentId, onSelected, onDelete, onUpdate }: AIAgentListProps) => {
  return (
    <>
      {agents && agents.length > 0 && (
        <div className="w-full">
          <div className="text-light-blue text-sm font-extrabold mb-5">Agents</div>
          <div>
            {agents.map(agent => <AIAgentItem 
              key={agent.id} 
              agent={agent} 
              isSelected={agent.id === selectedAgentId} 
              onSelected={onSelected}
              onUpdate={onUpdate}
              onDelete={onDelete}
            />)}
          </div>
        </div>
      )}
    </>
  )
};

const AIAgentItem = ({ 
  agent, 
  isSelected=false, 
  onSelected,
  onUpdate,
  onDelete,
}: { 
  agent: Agent, 
  isSelected?: boolean, 
  onSelected: (a: string) => void ,
  onUpdate: (agentId: string, name: string) => Promise<void>,
  onDelete: (agentId: string) => Promise<void>,
}) => {
  const [confirmAction, setConfirmAction] = useState<'UPDATE' | 'DELETE' | ''>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [agentName, setAgentName] = useState(() => agent.name);
  const inputRef = useRef<HTMLInputElement>(null);
  
  let actionsButton;

  const onConfirmAction = async () => {
    setIsSubmitting(true);
    if (confirmAction === 'UPDATE' && agentName.trim() !== '') {
      await onUpdate(agent.id, agentName.trim());
    } else if (confirmAction === 'DELETE') {
      await onDelete(agent.id);
    }
    setConfirmAction('');
    setIsSubmitting(false);
  }

  useEffect(
    () => {
      if (!isSelected && !isSubmitting) {
        setConfirmAction('');
      }
    },
    [isSelected, isSubmitting]
  );

  if (isSubmitting) {
    actionsButton = <div className="flex flex-col items-end w-1/5 gap-2 ml-2">
      <LoadingSpinner width='18px' height='18px' />
    </div>
  } else if (confirmAction) {
    actionsButton = <div className="flex w-1/5 gap-2 ml-2">
      <button><CheckSVG onClick={onConfirmAction} /></button>
      <button><CloseSVG onClick={() => setConfirmAction('')} width="12px" /></button>
    </div>
  } else {
    actionsButton = <div className="flex w-1/5 gap-2 ml-2 text-light-blue">
      <button><EditPenSVG onClick={() => { 
        trackEvent(AmplitudeEvent.AGENT_EDIT_NAME);
        setAgentName(agent.name); 
        setConfirmAction('UPDATE'); 
        nextTick(() => inputRef.current && inputRef.current.focus()); 
        }} 
      /></button>
      <button><TrashOutlineSVG onClick={() => {
        trackEvent(AmplitudeEvent.AGENT_DELETE);
        setConfirmAction('DELETE');
        }} 
      /></button>
    </div>
  }

  const classNames = `flex w-full px-4 py-3 justify-between items-center gap-1 rounded-lg hover:cursor-pointer ${isSelected ? 'bg-old-dark-blue' : ''}`;
  return <Link to={`/agents/${agent.id}`} className={classNames} key={agent.id} onClick={() => onSelected(agent.id)}>
    <div className="w-4/5 text-sm">
      {confirmAction === 'UPDATE' ? <input className='outline-none w-full p-0 inline-block text-white bg-transparent border-none text-sm' value={agentName} onClick={()=>isSubmitting && onSelected(agent.id)} onChange={(e) => !isSubmitting && setAgentName(e.target.value)} ref={inputRef} /> : <div className="truncate text-ellipsis text-sm">{agent.name}</div>}
    </div>
    {isSelected || isSubmitting ? actionsButton : null}
  </Link>
}

export default AIAgentList;
