"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.KanikoImageBuildErrorMessage = void 0;
var Back4app2Error_1 = require("./Back4app2Error");
var KanikoImageBuildErrorMessage;
(function (KanikoImageBuildErrorMessage) {
    KanikoImageBuildErrorMessage["BROKEN_SYMLINK"] = "BROKEN_SYMLINK";
    KanikoImageBuildErrorMessage["DUPLICATED_REPOSITORY"] = "DUPLICATED_REPOSITORY";
    KanikoImageBuildErrorMessage["INVALID_PROTOCOL"] = "INVALID_PROTOCOL";
})(KanikoImageBuildErrorMessage = exports.KanikoImageBuildErrorMessage || (exports.KanikoImageBuildErrorMessage = {}));
var KanikoImageBuildError = /** @class */ (function (_super) {
    __extends(KanikoImageBuildError, _super);
    function KanikoImageBuildError(message) {
        var _this = _super.call(this, message, KanikoImageBuildError.CODE) || this;
        Object.setPrototypeOf(_this, KanikoImageBuildError.prototype);
        return _this;
    }
    KanikoImageBuildError.CODE = 'KANIKO_IMAGE_BUILD_ERROR';
    return KanikoImageBuildError;
}(Back4app2Error_1.default));
exports.default = KanikoImageBuildError;
