import { useEffect, useRef, useState } from 'react';
import ErrorMessage from './ErrorMessage';
import { DeploymentLogLevel } from '@back4app2/sdk';
import LoadingSpinner from './LoadingSpinner';
import Modal from "./Modal";
import { ReactComponent as MaximizeIconSVG } from '../assets/images/maximize-icon.svg';

export type LogBoxProps = {
  errorMessage?: string;
  logs?: {
    id: string;
    time: Date;
    message: string;
    level: DeploymentLogLevel;
  }[];
  isLoading: boolean;
  canMaximize?: boolean
}

const LogBox = ({ errorMessage, logs, isLoading, canMaximize }: LogBoxProps) => {
  const [autoScroll, setAutoScroll] = useState(true);
  const [isMaximized, setIsMaximized] = useState(() => !!document.fullscreenElement);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => {
      if (!errorMessage && logs && autoScroll && boxRef.current) {
        boxRef.current.scrollTop = boxRef.current.scrollHeight;
      }
    },
    [
      errorMessage,
      logs,
      autoScroll
    ]
  );


  const exitHandler = (e: Event) => {
    if (!document.fullscreenElement) setIsMaximized(false);
  }

  useEffect(() => {    
    document.addEventListener('fullscreenchange', exitHandler, false);
    return () => {
      document.removeEventListener('fullscreenchange', exitHandler, false);
    }
  }, []);

  const onScroll = () => {
    if (boxRef.current && (boxRef.current.scrollTop + boxRef.current.clientHeight) >= 0.99 * boxRef.current.scrollHeight && !autoScroll) {
      setAutoScroll(true);
    } else if (boxRef.current && (boxRef.current.scrollTop + boxRef.current.clientHeight) < 0.99 * boxRef.current.scrollHeight && autoScroll) {
      setAutoScroll(false);
    }
  };

  const handleFullScreen = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (isLoading || errorMessage) {
      return;
    }
    if (boxRef.current) {
      if (document.fullscreenElement) {
        await document.exitFullscreen();
      }
      try {
        await boxRef.current.requestFullscreen();
        setIsMaximized(true);
      } catch (err) {
        console.log('err in requesting div fullscreen', err);
        setIsModalOpen(true);
      }
    }
  }

  const exitFullScreen = async () => {
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    }
    setIsMaximized(false);
  }

  return (<div className='relative'>
    {canMaximize && !isMaximized ? <button onClick={isMaximized ? exitFullScreen : handleFullScreen} className={`z-10 outline-none text-xs border border-white rounded bg-light-grey/[0.16] p-[0.3125rem] absolute top-[0.625rem] right-[0.625rem] group`}>
      <MaximizeIconSVG className='text-white group-hover:text-light-blue group-hover:scale-125 duration-300' />
    </button> : null}
    <div className="mt-4 h-[26rem] relative p-4 border border-regal-blue border-solid rounded overflow-y-scroll" ref={boxRef} onWheel={onScroll}>
      {isMaximized ? <button onClick={exitFullScreen} className={`z-10 outline-none text-xs border border-white rounded bg-light-grey/[0.16] p-[0.3125rem] sticky top-[calc(100%-25px)] left-full group`}>
        Exit FullScreen
      </button> : null}
      {isLoading ? <div className="flex h-full justify-center items-center"> <LoadingSpinner color='text-white' /> </div> : null}
      {errorMessage ? (<>
        <div className="flex h-full">
          <ErrorMessage message={errorMessage} />
        </div>
      </>) : (<>
        {logs && logs.map(log => {
          const logTypeColor = log.level === DeploymentLogLevel.INFO ? 'text-light-blue' : log.level === DeploymentLogLevel.ERROR ? 'text-error-log-red' : 'text-white';
          const logMessageColor = log.level === DeploymentLogLevel.ERROR ? 'text-error-log-red' : log.level === DeploymentLogLevel.WARNING ? 'text-alert-yellow' : 'text-white';
          return(
          <p key={log.id} className="font-courrier text-white text-xs leading-[140%] flex">
            <span className="text-light-blue flex-none">{log.time.toISOString()}</span>
            <span className={`ml-4 min-w-[50px] ${logTypeColor} flex-none`}>{log.level}</span>
            <span className={`ml-6 ${logMessageColor}`}>{log.message}</span>
          </p>
        )})}
      </>)}
    </div>
    {canMaximize ? (
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} theme='DARK'>
        <>
          {logs && logs.map(log => {
            const logTypeColor = log.level === DeploymentLogLevel.INFO ? 'text-light-blue' : log.level === DeploymentLogLevel.ERROR ? 'text-error-log-red' : 'text-white';
            const logMessageColor = log.level === DeploymentLogLevel.ERROR ? 'text-error-log-red' : log.level === DeploymentLogLevel.WARNING ? 'text-alert-yellow' : 'text-white';
            return(
            <p key={log.id} className="font-courrier text-white text-xs leading-[140%] flex">
              <span className="text-light-blue flex-none">{log.time.toISOString()}</span>
              <span className={`ml-4 min-w-[50px] ${logTypeColor} flex-none`}>{log.level}</span>
              <span className={`ml-6 ${logMessageColor}`}>{log.message}</span>
            </p>
          )})}
        </>
      </Modal>
    ) : null}
  </div>);
}

export default LogBox;
