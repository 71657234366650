import { ReactComponent as LoadingSpinnerSVG } from '../assets/images/loading-spinner.svg';

interface LoadingSpinnerProps {
  width?: string;
  height?: string;
  color?: string;
}

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  const { width = '2rem', height = '2rem', color } = props;
  return <LoadingSpinnerSVG width={width} height={height} className={`${color ? color : 'text-light-blue'} animate-spin`} />;
}

export default LoadingSpinner;
