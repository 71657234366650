export const timeFromNow = (date: Date): string => {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
  let diff = -1;
  let isPlural = true;

  let interval = seconds / 31540000;

  if (interval > 1) {
    diff = Math.floor(interval);
    isPlural = diff > 1;
    return isPlural ? `${diff} years ago` : `an year ago`;
  }
  interval = seconds / 2.628e+6;
  if (interval > 1) {
    diff = Math.floor(interval);
    isPlural = diff > 1;
    return isPlural ? `${diff} month ago` : `a month ago`;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    diff = Math.floor(interval);
    isPlural = diff > 1;
    return isPlural ? `${diff} days ago` : `a day ago`;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    diff = Math.floor(interval);
    isPlural = diff > 1;
    return isPlural ? `${diff} hours ago` : `an hour ago`;
  }
  interval = seconds / 60;
  if (interval > 1) {
    diff = Math.floor(interval);
    isPlural = diff > 1;
    return isPlural ? `${diff} mins ago` : `a min ago`;
  }
  
  diff = Math.floor(interval);
  isPlural = diff > 1;
  return isPlural ? `${diff} seconds ago` : `a second ago`;
}

// TODO: use moment().fromNow() for more accuracy