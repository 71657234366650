import ReactTooltip from 'react-tooltip';
import styles from './TextInput.module.css';
import { ReactComponent as InfoIcon } from '../../../assets/images/info-icon.svg';
import { forwardRef } from 'react';

interface TextInputProps {
  type?: string,
  value: string,
  label?: string,
  placeholderText?: string,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string,
  infoText?: string,
  isError?: Boolean,
  errorMessage?: string,
  isRequired?: Boolean,
  endAdornment?: React.ReactNode,
  startAdornment?: React.ReactNode,
  helpText?: string,
  isDisabled?: Boolean,
  className?: string,
  labelClassName?: string,
  isLightThemed?: boolean
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const { type='text', value, label, onChange, placeholderText, name, infoText, isError, errorMessage, isRequired, startAdornment, endAdornment, helpText, isDisabled = false, className='', labelClassName='', isLightThemed } = props;
  const randomId = Math.floor(100000 + Math.random() * 900000);
  const uniqueId = `${name}-${randomId}`;

  let inputColorClassNames = isError ? 'text-error-red' : `${isLightThemed ? ' text-dark' : ' text-white'}`;
  let inputBorderClassNames = isError ? 'border-error-red border-opacity-70' : 'border-light-blue border-opacity-20';

  return (
    <div className="flex flex-col gap-2 w-full">
      {label && (
        <label htmlFor={uniqueId} className={`inline-flex items-center justify-start gap-2 font-medium leading-140 capitalize ${labelClassName}`}>
          {label} 
          {infoText ? (<span data-tip={infoText} data-for="input-tooltip" ><InfoIcon className='text-light-blue' /></span>) : null}
        </label>
      )}
      <div className={`flex items-center space-x-2 p-3 border opacity-70 hover:opacity-100 transition-all duration-300 rounded hover:border-opacity-90 ${inputBorderClassNames} ${className}`}>
        {startAdornment}
        <input 
          type={type} 
          ref={ref} 
          className={`bg-transparent inline-block w-full outline-none border-none ${inputColorClassNames}`} 
          id={uniqueId} 
          placeholder={placeholderText} 
          name={name} 
          value={value} 
          onChange={!!isDisabled ? undefined : onChange}
          required={!!isRequired}
          disabled={!!isDisabled}
        />
        {endAdornment}
      </div>
      {!isError && helpText ? <span className='text-sm font-medium text-[#ccc]'>{helpText}</span> : null}
      {isError && <span className='text-xs text-error-red'>{errorMessage}</span>}
      {infoText ? <ReactTooltip id="input-tooltip" className={styles.tooltip} delayHide={200} place="top" effect="solid" html={true} arrowColor="#303338" eventOff='click' /> : null}
    </div>
  )
});

export default TextInput;