const buildCommitAuthorsMessage = (authorLogin?: string, committerLogin?: string): string => {
  let message = '';
  
  if (authorLogin || committerLogin) {
    message += 'by ';

    if (authorLogin) {
      message += authorLogin;
    }

    if (committerLogin && committerLogin !== authorLogin && committerLogin !== 'web-flow') {
      if (authorLogin) {
        message += ' and ';
      }

      message += committerLogin;
    }
  }

  return message;
};

export default buildCommitAuthorsMessage;
