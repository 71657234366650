import { useCallback, useEffect, useRef } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/images/close-icon.svg';
import Button from '../Button';
import TextInput from '../FormInputField/TextInput/TextInput';

interface ModalProps {
  open: boolean;
  onClose: () => void,
  onCreate: () => void,
}

const AIPromptModal = ({ open, onClose, onCreate }: ModalProps) => { 
  const modalContentRef = useRef<HTMLDivElement>(null);
 
  const handleClickOutsideEvent = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (modalContentRef.current && !modalContentRef.current.contains(e.target as Node)) {
        onClose && onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideEvent);
    document.addEventListener('touchstart', handleClickOutsideEvent);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideEvent);
      document.removeEventListener('touchstart', handleClickOutsideEvent);
    }
  }, [handleClickOutsideEvent]);

  if (!open) {
    return <></>
  }

  return (
    <>
      <div className="fixed top-0 left-0 right-0 bottom-0 bg-black/60 w-full h-full z-[9999]"></div>
      <div className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-[0.625rem] border border-regal-blue bg-dark z-[9999] shadow-[0_0_7px_0_rgba(193,226,255,0.16)] min-w-[45rem] animate-slide-in-center`} ref={modalContentRef}>
        <div className="relative max-h-[calc(100vh-50px)] overflow-y-auto">
          <div className="w-full flex justify-between items-center p-4 font-sora text-[1.375rem]">
            New Prompt
            <CloseIcon onClick={onClose} className="cursor-pointer text-light-blue inline-block bg-regal-blue rounded-[1.25rem] p-[0.38rem]" width="1.5rem" height="1.5rem" />
          </div>
          <div className="border border-regal-blue px-[4.38rem] py-[2.5rem] space-y-8">
            <TextInput placeholderText='Your Prompt Name' onChange={() => {}} value="" label='Name' />
            <div className="flex flex-col gap-2">
              <label htmlFor="description-textarea">Description</label>
              <textarea id="description-textarea" className='resize-none bg-transparent outline-none p-3 w-full border border-light-blue border-opacity-20 hover:border-opacity-70 focus:border-opacity-70 rounded' rows={3} placeholder='Prompt description' />
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="prompt-textarea">Prompt</label>
              <textarea id="prompt-textarea" className='resize-none bg-transparent outline-none p-3 w-full border border-light-blue border-opacity-20 hover:border-opacity-70 focus:border-opacity-70 rounded' rows={6} placeholder='Write your prompt here' />
            </div>
          </div>
          <div className="flex w-full items-center justify-center gap-4 py-6">
            <Button onClick={onClose} className='border border-light-grey rounded-md opacity-60 hover:opacity-70 transition-opacity duration-500 text-sm font-bold'>Cancel</Button>
            <Button type='primary' className='text-sm font-bold'>Create prompt</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default AIPromptModal;