import { init, track, identify, Identify } from '@amplitude/analytics-browser';
import { AMPLITUDE_KEY } from '../settings';

export const initializeAmplitude = (userId: string) => {
  if (!AMPLITUDE_KEY) {
    return;
  }
  init(AMPLITUDE_KEY, userId);
};

export const trackEvent = (name: string, data?: Record<string, any>) => {
  if (!AMPLITUDE_KEY) {
    return;
  }
  track(name, data);
};

export const setUserForTracking = (id: string, name?: string) => {
  if (!AMPLITUDE_KEY) {
    return;
  }
  const identifyObj = new Identify();
  identifyObj.setOnce('id', id);
  identifyObj.setOnce('name', name as string);

  identify(identifyObj);
};

export const AmplitudeEvent = {
  AT_CONTAINERS_DASHBOARD_PAGE: 'At Dashboard Containers page',
  AT_SELECT_REPOSITORY_PAGE: 'At Select Repository page',
  AT_CREATE_APP_PAGE: 'At Create App page',
  AT_APP_OVERVIEW_PAGE: 'At App Overview page',
  AT_APP_DEPLOYMENT_PAGE: 'At App Deployment page',
  AT_APP_LOGS_PAGE: 'At App Logs page',
  AT_APP_METRICS_PAGE: 'At App Metrics page',
  AT_APP_SETTINGS_PAGE: 'At App Settings page',
  EDIT_GITHUB_PERMISSION_CLICK: 'Edit Github Permission Click',
  IMPORT_GITHUB_REPOSITORY_CLICK: 'Import github repository Click',
  CREATE_APP_CLICK: 'Create APP Click',
  APP_SETTINGS_SAVE_CLICK: 'App Settings Save Click',
  DEPLOY_LATEST_COMMIT: 'deploy latest commit',
  CLICK_ON_DELETE_APP: 'click on delete app',
  CLICK_ON_CREATE_DOMAIN_BTN: 'click on add custom domain button',
  AT_AGENT_UI: 'Agent - At AI Agent',
  AGENT_CREATE_ACTION: 'Agent - Created new Agent',
  AGENT_PROMPT_SELECTED: 'Agent - Prompt Selected',
  AGENT_PROMPT_USED: 'Agent - Submited new prompt',
  AGENT_EDIT_NAME: 'Agent - Edit Agent Name',
  AGENT_DELETE: 'Agent- Delete Agent',
};