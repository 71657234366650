import { ReactComponent as ErrorIconSVG } from '../assets/images/error-icon.svg';

const ErrorMessage = ({ message }: { message: string }) => {
  return (<>
    <div className="grow flex flex-col items-center justify-center">
      <ErrorIconSVG />
      <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
        {message}
      </div>
    </div>
  </>);
};

export default ErrorMessage;
