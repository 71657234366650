import React, { useCallback, useEffect, useRef } from 'react';
import { ReactComponent as CloseIcon } from '../assets/images/close-icon.svg';

interface ModalProps {
  open: boolean;
  onClose?: () => void,
  children: React.ReactNode,
  theme?: "LIGHT" | "DARK"
}

const Modal = ({ open, onClose, children, theme = 'LIGHT' }: ModalProps) => {
  const modalContentRef = useRef<HTMLDivElement>(null);

  if (typeof onClose === "undefined") {
    onClose = () => {};
  }

  const handleClickOutsideEvent = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (modalContentRef.current && !modalContentRef.current.contains(e.target as Node)) {
        onClose && onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideEvent);
    document.addEventListener('touchstart', handleClickOutsideEvent);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideEvent);
      document.removeEventListener('touchstart', handleClickOutsideEvent);
    }
  }, [handleClickOutsideEvent]);

  if (!open) {
    return <></>
  }

  let bgColor = theme === 'LIGHT' ? 'bg-white' : 'bg-dark-grey';
  let iconColor = theme === 'LIGHT' ? '' : 'text-white';

  return <>
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-black/60 w-full h-full z-[9999]"></div>
    <div className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${bgColor} rounded-lg z-[9999] text-dark min-w-[25rem] animate-slide-in-center`} ref={modalContentRef}>
      <div className="relative px-8 py-4 max-h-[calc(100vh-50px)] overflow-y-auto">
        <CloseIcon className={`absolute right-3 top-3 cursor-pointer ${iconColor}`} width="10px" height="10px" onClick={onClose} />
        {children}
      </div>
    </div>
  </>
}

export default Modal;