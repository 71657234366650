import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Message } from "../../types/Agent";
import AgentInput from "./AgentInput";
import AgentMessage from "./AgentMessage";
import { ReactComponent as ErrorIconSVG } from '../../assets/images/error-icon.svg';
import Button from "../Button";
import { BACK4APP_DOT_COM_SITE_URL } from "../../settings";

const Agent = forwardRef<{ setInputContent: (content: string) => void }, { agentId: string, messages?: Message[], isSidebarOpen: boolean, paymentErrorMessage?: string, onSend: (content: string) => Promise<boolean> }>(({ agentId, messages, onSend, isSidebarOpen, paymentErrorMessage }, ref) => {
  const [autoScroll, setAutoScroll] = useState(true);
  const boxRef = useRef<HTMLDivElement>(null);
  const agentInputRef = useRef<{ setContent: (content: string) => void }>(null);

  useImperativeHandle(ref, () => ({
    setInputContent: (content: string) => {
      if (agentInputRef.current) {
        agentInputRef.current.setContent(content);
      }
    }
  }));

  useEffect(
    () => {
      if (messages && autoScroll && boxRef.current) {
        boxRef.current.scrollTop = boxRef.current.scrollHeight;
      }
    },
    [
      messages,
      autoScroll
    ]
  );

  const onScroll = () => {
    if (boxRef.current && (boxRef.current.scrollTop + boxRef.current.clientHeight) >= 0.99 * boxRef.current.scrollHeight && !autoScroll) {
      setAutoScroll(true);
    } else if (boxRef.current && (boxRef.current.scrollTop + boxRef.current.clientHeight) < 0.99 * boxRef.current.scrollHeight && autoScroll) {
      setAutoScroll(false);
    }
  };

  return (
    <div className={`relative h-full w-full transition-all duration-500 ${isSidebarOpen ? 'max-w-[calc(100vw-19.75rem)]' : 'max-w-[calc(100vw-1rem)]'}`}>
      <div className="h-full w-full overflow-y-scroll" ref={boxRef} onScroll={onScroll}>
        <div className="w-full px-[7.5rem] pb-[15rem] py-5">
          <AgentMessage
            message={{
              id: 'welcome',
              role: 'assistant',
              content: `Hello! I'm your Back4App AI Agent, directly connected to Back4App's backend and web deployment services. I'm here to enhance your experience with these products. Here's some examples of prompts you can start with:

"Create a new todo list app with user authentication and task management tables" 
"Generate 100 mock users and 500 tasks for my project management app"
"Show me how to connect my Flutter App to this backend"
"Create a cloud function that sends push notifications when new tasks are assigned"
"Set up Stripe payment integration for my e-commerce app"
"Install TensorFlow.js and create an image classification function"
"Review and optimize my user feed cloud functions"
"Set up web hosting for my React application"
"Deploy my e-commerce app from GitHub repository 'my-store'"

What would you like to work on today?`
            }}
          />
          {messages?.map(message => <AgentMessage key={message.id} message={message} />)}
          {paymentErrorMessage && (
            <div className="grow flex flex-col items-center justify-center mt-10">
            <ErrorIconSVG />
            <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey mb-8">
              {paymentErrorMessage}
            </div>
            <Button type='submit'><a role="button" target="_blank" rel='noreferrer noopener' href={`${BACK4APP_DOT_COM_SITE_URL}/pricing/agent?appId=${agentId}&type=agent`}>Pay Now</a></Button>
          </div>
          )}
        </div>
      </div>
      <AgentInput onSend={onSend} ref={agentInputRef}  />
    </div>
  )
});

export default Agent;
