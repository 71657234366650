import styles from '../assets/styles/Button.module.css';

type ButtonType = 'submit' | 'primary' | 'secondary' | 'iconBtn';

interface ButtonProps {
  type?: ButtonType,
  className?: string,
  children: React.ReactNode,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  isDisabled?: boolean
}

const Button = ({ onClick, type, className, children, isDisabled=false }: ButtonProps) => {
  const classes: string[] = [styles.button];
  switch (type) {
    case 'submit':
    case 'primary':
      classes.push(styles.primary);
      break;
    case 'secondary':
      classes.push(styles.secondary);
      break;
    case 'iconBtn':
      classes.push(styles.iconBtn);
      break;
    default:
      classes.push(styles.default);
      break;
  }

  if (className) {
    classes.push(className);
  }

  if (isDisabled) {
    classes.push(styles.disabled)
  }


  return (
    <button type={type === 'submit' ? type : 'button'} onClick={onClick} className={classes.join(' ')}>{children}</button>
  )
}

export default Button
