import { ContainerStats } from "@back4app2/sdk";

export const createRandomColor = () => {
  const red = Math.floor(256 - Math.random() * 128);
  const green = Math.floor(256 - Math.random() * 128);
  const blue = Math.floor(256 - Math.random() * 128);
  return `rgb(${red}, ${green}, ${blue})`;
}

export const DEFAULT_STATS_TIME_WINDOW = 3 * 60 * 60 * 1000;

export const getContainerStatsGraphData = (stats: ContainerStats[], colors?:{ [containerId: string]: string }) => {
  colors = (colors && { ...colors }) || {};

  let cpuGraphProps: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string }} | undefined = undefined;

  let ramGraphProps: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string }, max: number} | undefined = undefined;

  let pidGraphProps: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string }, max: number} | undefined = undefined;

  let netInGraphProps: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string } } | undefined = undefined;

  let netOutGraphProps: { data: { time: number, [containerId: string]: number }[], colors: { [key: string]: string } } | undefined = undefined;

  if (stats.length) {

    const cpuGraphData: { time: number, [containerId: string]: number }[] = [];
    const cpuGraphColors: { [containerId: string]: string } = {};

    const ramGraphData: { time: number, [containerId: string]: number }[] = [];
    const ramGraphColors: { [containerId: string]: string } = {};
    let ramGraphMax = 256 * 1024 * 1024;

    const pidGraphData: { time: number, [containerId: string]: number }[] = [];
    const pidGraphColors: { [containerId: string]: string } = {};
    let pidGraphMax = 100;

    const netInGraphData: { time: number, [containerId: string]: number }[] = [];
    const netInGraphColors: { [containerId: string]: string } = {};
    let lastNetInByContainer: { [containerId: string]: number } = {};

    const netOutGraphData: { time: number, [containerId: string]: number }[] = [];
    const netOutGraphColors: { [containerId: string]: string } = {};
    let lastNetOutByContainer: { [containerId: string]: number } = {};

    let lastTimeByContainer: { [containerId: string]: number } = {};

    for (let i = 0; i < stats.length; i++) {
      const containerId = stats[i].container.id.split('-')[0];
        
      if (!colors[containerId]) {
        colors[containerId] = createRandomColor();
      }
          
      cpuGraphData.push({
        time: stats[i].time.getTime(),
        [containerId]: stats[i].cpu >= stats[i].container.maxCPU ? 100 : stats[i].cpu * 100 / stats[i].container.maxCPU
      });

      if (!cpuGraphColors[containerId]) {
        cpuGraphColors[containerId] = colors[containerId];
      }

      const containerMaxRAM = stats[i].container.maxRAM * 1024 * 1024;

      ramGraphData.push({
        time: stats[i].time.getTime(),
        [containerId]: stats[i].ram >= containerMaxRAM ? containerMaxRAM : stats[i].ram
      });

      if (!ramGraphColors[containerId]) {
        ramGraphColors[containerId] = colors[containerId];
      }

      if (containerMaxRAM > ramGraphMax) {
        ramGraphMax = containerMaxRAM;
      }

      pidGraphData.push({
        time: stats[i].time.getTime(),
        [containerId]: stats[i].pid >= stats[i].container.maxPID ? stats[i].container.maxPID : stats[i].pid
      });

      if (!pidGraphColors[containerId]) {
        pidGraphColors[containerId] = colors[containerId];
      }

      if (stats[i].container.maxPID > pidGraphMax) {
        pidGraphMax = stats[i].container.maxPID;
      }

      if (lastTimeByContainer[containerId] && stats[i].time.getTime() > lastTimeByContainer[containerId]) {
        netInGraphData.push({
          time: stats[i].time.getTime(),
          [containerId]: (stats[i].netIn - lastNetInByContainer[containerId]) * 1000 / (stats[i].time.getTime() - lastTimeByContainer[containerId])
        });

        if (!netInGraphColors[containerId]) {
          netInGraphColors[containerId] = colors[containerId];
        }

        netOutGraphData.push({
          time: stats[i].time.getTime(),
          [containerId]: (stats[i].netOut - lastNetOutByContainer[containerId]) * 1000 / (stats[i].time.getTime() - lastTimeByContainer[containerId])
        });

        if (!netOutGraphColors[containerId]) {
          netOutGraphColors[containerId] = colors[containerId];
        }
      }

      lastNetInByContainer[containerId] = stats[i].netIn;

      lastNetOutByContainer[containerId] = stats[i].netOut;

      lastTimeByContainer[containerId] = stats[i].time.getTime();
    }

    cpuGraphProps = { data: cpuGraphData, colors: cpuGraphColors };

    ramGraphProps = { data: ramGraphData, colors: ramGraphColors, max: ramGraphMax };

    pidGraphProps = { data: pidGraphData, colors: pidGraphColors, max: pidGraphMax };

    netInGraphProps = { data: netInGraphData, colors: netInGraphColors };

    netOutGraphProps = { data: netOutGraphData, colors: netOutGraphColors };
  }

  return {
    colors,
    cpuGraphProps,
    ramGraphProps,
    pidGraphProps,
    netInGraphProps,
    netOutGraphProps
  }
}