import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as CarretIcon } from '../assets/images/down-arrow.svg';
import { ReactComponent as InfoIcon } from '../assets/images/info-icon.svg';

interface CollapsibleComponentProps {
  title: string,
  children: React.ReactNode,
  infoText?: string,
  helpText?: string;
  open?: Boolean,
  showBottomBorder?: Boolean
}

const CollapsibleComponent = ({ title, children, infoText, helpText, open, showBottomBorder=true }: CollapsibleComponentProps) => {
  const [isOpen, setIsOpen] = useState(!!open);
  const [rotate, setRotate] = useState('transform duration-700 ease');

  const contentSpace = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setRotate(isOpen ? 'transform duration-500 ease-in-out rotate-180' : 'transform duration-500 ease-in-out');
  }, [isOpen]);
  

  const toggleAccordion = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsOpen(prev => !prev);
  };

  return (
    <div className={`pb-6 my-4 ${showBottomBorder ? 'border-b-light-blue border-opacity-20 border-b' : ''}`}>
      <div className={`flex gap-2 items-center font-semibold text-[1.125rem] text-[#ccc] cursor-pointer transition-all duration-500 ${isOpen ? 'mb-6':''}`} onClick={toggleAccordion}> 
        <CarretIcon className={`align-middle ${rotate}`} />
        {title} 
        {infoText ? (<span data-tip={infoText} data-for="input-tooltip" >
          <InfoIcon className='text-light-blue' /></span>) 
        : null} 
        <span className="text-sm text-light-grey font-light leading-140">{helpText}</span>
      </div>
      <div ref={contentSpace} className={`overflow-hidden transition-all duration-500 ease-in-out ${isOpen ? 'opacity-100' : 'max-h-0 opacity-0'}`}>
        {children}
      </div>
    </div>
  )
}

export default CollapsibleComponent