import { useEffect, useReducer } from "react";
import back4app2 from "../back4app2";
import { ChatMessage, Subscription,  AuthenticationError, AuthorizationError, Deployment, NetworkError, NotFoundError, GitHubResourceNotFoundError, GitHubReadWritePermissionError } from "@back4app2/sdk";
import { BACK4APP_DOT_COM_SITE_URL, GITHUB_APP_URL } from '../settings';
import toast from "react-hot-toast";

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import styles from '../utils/codeStylesCss';

import Button from "./Button";
import LoadingSpinner from "./LoadingSpinner";

import { ReactComponent as B4AsqaureLogoSVG } from '../assets/images/b4a-sqaure-logo.svg';
import { ReactComponent as CloseIcon } from '../assets/images/close-icon.svg';
import { ReactComponent as CopyIcon } from '../assets/images/copy-icon.svg';
import { ReactComponent as OpenAiLogoSVG } from '../assets/images/open-ai-logo.svg';
import { ReactComponent as OpenAiTextLogoSVG } from '../assets/images/open-ai-logo-text.svg';
import { ReactComponent as GithubIcon } from "../assets/images/github-logo.svg";
import { ReactComponent as B4AIconSVG } from "../assets/images/b4a-sqaure-glow-logo.svg";
import { ReactComponent as StatusErrorSVG } from '../assets/images/status-error.svg';


let windowObjectReference: Window | null = null;
const windowName = 'GithubIntegrationWindow';

interface AIAssisantState {
  chatData?: ChatMessage[];
  isLoadingChatMessages: boolean;
  loadingChatMessagesError?: string;
  isLoadingTryToFixDockerfile: boolean;
  loadingTryToFixDockfileErrorMessage?: string;
  isCopied: boolean;
  isLoadingCreatePr: boolean;
  loadingCreatePrErrorMessage?: string;
  showCreatePrBtn: boolean;
  isCloseAIAssistant: boolean;
  openGithubEditPermissions: boolean;
}

const INITIAL_STATE: AIAssisantState = {
  isLoadingChatMessages: true,
  isLoadingTryToFixDockerfile: false,
  isCopied: false,
  isLoadingCreatePr: false,
  showCreatePrBtn: false,
  isCloseAIAssistant: false,
  openGithubEditPermissions: false
}

enum AIAssisantType {
  RESET,
  FINISH_LOADING,
  START_CHAT,
  FINISH_TRY_TO_FIX_DOCKERFILE,
  SET_COPIED_TEXT,
  CREATE_PR,
  FINISH_CREATE_PR,
  CLOSE_AI_ASSISTANT,
  OPEN_GITHUB_EDIT_PERMISSIONS
}

const reset = () => ({
  type: AIAssisantType.RESET
} as const);

const finishLoading = (errorMessage?: string, chatData?: ChatMessage[]) => ({
  type: AIAssisantType.FINISH_LOADING,
  payload: {
    errorMessage,
    chatData
  }
} as const);

const startChat = () => ({
  type: AIAssisantType.START_CHAT,
} as const);

const finishTryToFixDockerfile = (errorMessage?: string) => ({
  type: AIAssisantType.FINISH_TRY_TO_FIX_DOCKERFILE,
  payload: errorMessage
} as const);

const setCopiedText = (isCopied: boolean) => ({
  type: AIAssisantType.SET_COPIED_TEXT,
  payload: isCopied
} as const);

const startCreatingPr = () => ({
  type: AIAssisantType.CREATE_PR,
} as const);

const finishCreatingPr = (errorMessage?: string) => ({
  type: AIAssisantType.FINISH_CREATE_PR,
  payload: errorMessage
} as const);

const closeAIAssistant = () => ({
  type: AIAssisantType.CLOSE_AI_ASSISTANT
} as const);

const setOpenGithubPermissions = () => ({
  type: AIAssisantType.OPEN_GITHUB_EDIT_PERMISSIONS
} as const);


type AIAssisantAction = ReturnType<typeof reset> | ReturnType<typeof finishLoading> | ReturnType<typeof startChat> | ReturnType<typeof finishTryToFixDockerfile> | ReturnType<typeof setCopiedText> | ReturnType<typeof startCreatingPr> | ReturnType<typeof finishCreatingPr> | ReturnType<typeof closeAIAssistant> | ReturnType<typeof setOpenGithubPermissions>;

const reducer = (state: AIAssisantState, action: AIAssisantAction): AIAssisantState => {
  switch (action.type) {
    case AIAssisantType.RESET:
      return INITIAL_STATE;
    case AIAssisantType.FINISH_LOADING:
      return {
        ...state,
        chatData: action.payload.chatData,
        loadingChatMessagesError: action.payload.errorMessage,
        isLoadingChatMessages: false,
        showCreatePrBtn: action.payload.chatData?.length ? action.payload.chatData.length === 1 : false      
      }
    case AIAssisantType.START_CHAT:
      return {
        ...state,
        isLoadingTryToFixDockerfile: true
      }
    case AIAssisantType.FINISH_TRY_TO_FIX_DOCKERFILE:
      return {
        ...state,
        isLoadingTryToFixDockerfile: false,
        loadingTryToFixDockfileErrorMessage: action.payload
      }
    case AIAssisantType.SET_COPIED_TEXT:
      return {
        ...state,
        isCopied: action.payload
      }
    case AIAssisantType.CREATE_PR:
      return {
        ...state,
        isLoadingCreatePr: true,
        loadingCreatePrErrorMessage: undefined
      }
    case AIAssisantType.FINISH_CREATE_PR:
      return {
        ...state,
        isLoadingCreatePr: false,
        loadingCreatePrErrorMessage: action.payload
      }
    case AIAssisantType.CLOSE_AI_ASSISTANT:
      return {
        ...state,
        isCloseAIAssistant: true
      }
    case AIAssisantType.OPEN_GITHUB_EDIT_PERMISSIONS:
      return {
        ...state,
        openGithubEditPermissions: true
      }
  }
}

function extractKeysFromString(input: string): Record<string, string | boolean> {
  try {
    return JSON.parse(input);
  } catch (err) {
    const keysToExtract = ['explanation', 'fixed', 'dockerfile', 'comments'];
    const extractedData: Record<string, string | boolean> = {};
  
    keysToExtract.forEach(key => {
      const regex = new RegExp(`"${key}"\\s*:\\s*(?:"([^"]*)|(true|false))`, 'g');
      const match = regex.exec(input);
      
      if (match) {
        extractedData[key] = key === 'comments' ? match[1] : (match[1] || (match[2] === 'true'));
      }
    });
  
    return extractedData;
  }
}

const AIAssisant = (props: { deployment: Deployment }) => {
  const { deployment } = props;
  const deploymentId = deployment && deployment.id;
  const lastDockerfileChatId = deployment && deployment.lastDockerfileChat && deployment.lastDockerfileChat.id;
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { chatData, isLoadingTryToFixDockerfile, isCopied, isLoadingCreatePr, showCreatePrBtn, isCloseAIAssistant, loadingCreatePrErrorMessage, openGithubEditPermissions } = state;

  useEffect(() => {
    let subscription: Subscription;
    if (deploymentId && lastDockerfileChatId) {
      subscription = back4app2.subscribeToChatMessages(lastDockerfileChatId, (error, snapshot) => {
        if (error) {
          if (error instanceof NetworkError) {
            console.error('network error', error);
            dispatch(finishLoading('Network error when loading app. Check your internet connection and try again.'));
          } else if (error instanceof AuthenticationError || error instanceof AuthorizationError) {
            window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
          } else if (error instanceof NotFoundError) {
            dispatch(finishLoading('App not found.'));
          } else {
            console.error('unexpected error loading app', error);
            dispatch(finishLoading('Unexpected error when loading app. Please try again.'));
          }
        } else {
          dispatch(finishLoading(undefined, snapshot));
        }
      })
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    }
  }, [deploymentId, lastDockerfileChatId]);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        dispatch(setCopiedText(false));
      }, 2000);
    }
  }, [isCopied]);

  const tryToFixDockerfile = async () => {
    if (isLoadingTryToFixDockerfile) {
      return;
    }
    dispatch(startChat());
    try {
      await back4app2.tryToFixDockerfileUsingAI(deploymentId);
      dispatch(finishTryToFixDockerfile());
    } catch (err) {
      let errMsg = '';
      if (err instanceof NetworkError) {
        console.error('network error', err);
        errMsg = 'Network error when asking AI Assistant. Check your internet connection.'
      } else if (err instanceof AuthenticationError || err instanceof AuthorizationError) {
        window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
      } else if (err instanceof NotFoundError || err instanceof GitHubResourceNotFoundError) {
        errMsg = err.message;
      } else {
        console.error('unexpected error while asking AI Assistant', err);
        errMsg = 'Unexpected error when asking AI Assistant.';
      }
      dispatch(finishTryToFixDockerfile(errMsg));
      toast.error(errMsg, {
        className:`bg-white px-6 py-4 text-dark text-center rounded-none rounded-bl-lg rounded-br-lg shadow-[0_6px_16px_rgba(0,0,0,0.25)] max-w-3xl text-sm`,
        icon: <StatusErrorSVG width="24px" height="24px" className="animate-bounce-in flex-none" />,
        duration: 4000
      });
    }
  }

  const openGithubInPopup = ({url, title, w, h}: {url: string; title: string; w: number; h: number}) => {
    if (!(windowObjectReference === null || windowObjectReference.closed)) {
      windowObjectReference.focus();
    } 
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;
  
    // eslint-disable-next-line no-restricted-globals
    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    // eslint-disable-next-line no-restricted-globals
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
  
    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;
    const newWindow = window.open(url, title, 
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )
  
    if (typeof window.focus === 'function' && newWindow) newWindow.focus();
    return newWindow;
  }

  const openGithubPopup = () => {
    if (openGithubEditPermissions) {
      windowObjectReference = openGithubInPopup({ url: GITHUB_APP_URL, title: windowName, w: 800, h: 700 });
      windowObjectReference?.addEventListener('unload', createPr);
    } else 
      createPr();
  }

  const createPr = async () => {
    try {
      dispatch(startCreatingPr());
      await back4app2.createPrWithDockerfileFix(deploymentId);
      dispatch(finishCreatingPr());
    } catch (err) {
      let errMsg = '';
      if (err instanceof NetworkError) {
        console.error('network error', err);
        errMsg = 'Network error while creating pr. Check your internet connection.';
      } else if (err instanceof AuthenticationError || err instanceof AuthorizationError) {
        window.location.replace(`${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(window.location.href)}`);
      } else if (err instanceof GitHubReadWritePermissionError) {
        errMsg = err.message;
        dispatch(setOpenGithubPermissions());
      } else {
        console.error('unexpected error while creating pr', err);
        errMsg = 'Unexpected error while creating pr.';
      }
      dispatch(finishCreatingPr(errMsg));
    }
  }

  const onClickCopy = (code: string) => {
    navigator.clipboard.writeText(code);
    dispatch(setCopiedText(true));
  }

  if (isCloseAIAssistant) {
    return null;
  }

  return (
    <div className="relative bg-dark-grey rounded-lg p-6 mb-4">
      <button><CloseIcon className={`absolute right-4 top-4 cursor-pointer text-white`} width="10px" height="10px" onClick={() => dispatch(closeAIAssistant())}/></button>
      <div className="mb-6">
        <div className="text-error-red text-[1.375rem] font-bold leading-140 font-sora mb-6">Build Failure Detected</div>
        <div className="text-sm"> Our system detected an issue during your project's build process. Get immediate help from our AI Assistant to troubleshoot and resolve the Dockerfile issues swiftly. The AI Assistant can analyze the error logs, suggest solutions, and even create a corrected Dockerfile for your project. Ready to get started?</div>
      </div>
      <div className="mb-6">
        {chatData && chatData.length ? (
          <div className="border border-[#232527] rounded overflow-hidden divide-y divide-[#01010166]">
            {chatData && chatData.map((chat, idx) => {
              let textResponse = '', code = '';
              if (chat.aiResponse) {
                const aiObjResponse = extractKeysFromString(chat.aiResponse);
                if (Object.keys(aiObjResponse).length && aiObjResponse.fixed) {
                  textResponse = (aiObjResponse['explanation'] ? aiObjResponse['explanation'] : '') as string;
                  code = aiObjResponse['dockerfile'] ? aiObjResponse['dockerfile'] as string : '';
                  textResponse = aiObjResponse['comments'] ? `${textResponse}\nComments: ${aiObjResponse['comments']}` : textResponse;
                  if (aiObjResponse['originalDockerfile'] === code){ 
                    code = ''
                    textResponse = 'Unable to fix find any fixes for the dockerfile.'
                  };
                } else {
                  const countSplitter = chat.aiResponse.split('```').length - 1;
                  if (countSplitter === 1) {
                    [textResponse, code] = chat.aiResponse?.split('```') as [string, string];
                  } else if (countSplitter === 2) {
                    code = chat.aiResponse.match(
                      /(?<=(```))((.|\n)*?)(?=(```))/gim
                    )?.[0] || '';
                    textResponse = chat.aiResponse.replace(code, '').replaceAll('```', '');
                  } else 
                    textResponse = chat.aiResponse;
                };
              }
              const matchedUrl = chat.question.match(/(https?:\/\/[^\s]+)/g);

              return(
                <div key={`chat-${idx}`}>
                  <div className={`px-[1.625rem] py-[1.125rem] shadow-[0_1px_0px_rgba(1,1,1,0.4)] flex items-center gap-6 bg-[#343540]`}>
                    <B4AsqaureLogoSVG width="37px" height="37px" className="self-baseline flex-none" />
                    <div className="text-sm">{matchedUrl ? (
                      <>
                        {chat.question.replace(/(https?:\/\/[^\s]+)/g, '')}
                        <a className="text-old-blue hover:underline underline-offset-2" href={matchedUrl[0]} target="_blank" rel="noopener noreferrer">{matchedUrl[0]}</a>
                      </>
                    ) : chat.question}</div>
                  </div>
                  {chat.aiResponse ? (
                    <div className={`px-[1.625rem] py-[1.125rem] shadow-[0_1px_0px_rgba(1,1,1,0.4)] flex items-center gap-6 bg-[#444653]`}>
                      <OpenAiLogoSVG className="flex-none self-baseline" width="37px" height="37px" />
                      <div className="text-sm min-w-[94%]">
                        {isLoadingTryToFixDockerfile && !textResponse.trim() && !code ? <div className="flex space-x-2">
                          <span className="w-1 h-1 rounded bg-[#D9D9D9] animate-dot-flashing"></span>
                          <span className="w-1 h-1 rounded bg-[#D9D9D9] animate-[dot-flashing_1s_infinite_0.5s_alternate] "></span>
                          <span className="w-1 h-1 rounded bg-[#D9D9D9] animate-[dot-flashing_1s_infinite_1s_alternate] "></span>
                        </div> : null}
                        {textResponse.trim() ? <div className="text-sm mb-5">{textResponse.trim().split('\n').map((str, idx) => <p key={idx}>{str}</p>)}</div> : null}
                        {code?.length ? <div className="relative w-full syntax-higlighter">
                          <button 
                            className="absolute flex gap-1 hover:bg-cta-green/20 duration-500 top-2 outline-none border border-cta-green p-1 rounded text-xs text-cta-green right-2 z-10" 
                            onClick={() => onClickCopy(code)}
                          >
                            <CopyIcon />{isCopied ? 'Copied!' : 'Copy'}
                          </button>
                          <SyntaxHighlighter
                            language="docker"
                            lineNumberStyle={{
                              marginRight: "24px",
                              textAlign: 'center',
                              padding: "0 20px",
                              opacity: '0.7',
                              fontSize: '12px',
                              minWidth: 'auto',
                              width: '4.5rem',
                              background: '#000',
                              zIndex: '999'
                            }}
                            style={styles}
                            showLineNumbers={true}
                          >
                            {code}
                          </SyntaxHighlighter>
                        </div> : null} 
                      </div>
                    </div>
                  ) : chat.error ? <div className={`px-[1.625rem] py-[1.125rem] shadow-[0_1px_0px_rgba(1,1,1,0.4)] flex items-center gap-6 bg-[#343540]`}>
                  <OpenAiLogoSVG width="37px" height="37px" className="self-baseline" />
                  <div className="text-sm text-error-red">Error while asking AI Assistant.</div>
                </div> : null}
                  {code && !isLoadingTryToFixDockerfile && showCreatePrBtn && !loadingCreatePrErrorMessage ? (
                    <div className={`px-[1.625rem] py-[1.125rem] shadow-[0_1px_0px_rgba(1,1,1,0.4)] flex items-center justify-center gap-6 bg-[#343540]`}>
                      <Button type="primary" onClick={() => createPr()}>Create Pull Request with New Dockerfile</Button>
                    </div>
                  ) : null}
                </div>
              )
            })}
            {isLoadingCreatePr ? <>
              <div className="px-[1.625rem] py-[2.2rem] shadow-[0_1px_0px_rgba(1,1,1,0.4)] flex items-center justify-center h-6 bg-[#343540]">
                <B4AIconSVG width="76px" height="76px" />
                <div className="flex space-x-2 mr-5">
                  <span className="w-1 h-1 rounded bg-[#D9D9D9] animate-dot-flashing"></span>
                  <span className="w-1 h-1 rounded bg-[#D9D9D9] animate-[dot-flashing_1s_infinite_0.5s_alternate] "></span>
                  <span className="w-1 h-1 rounded bg-[#D9D9D9] animate-[dot-flashing_1s_infinite_1s_alternate] "></span>
                </div>
                <GithubIcon width="24px" height="24px" />
              </div>
          </> : null}
          {loadingCreatePrErrorMessage ? <>
            <div className={`px-[1.625rem] py-[1.125rem] shadow-[0_1px_0px_rgba(1,1,1,0.4)] flex items-center gap-6 bg-[#343540]`}>
              <B4AsqaureLogoSVG width="37px" height="37px" className="self-baseline" />
              <div className="text-sm text-error-red">Error while creating the Pull Request. <br /> "{loadingCreatePrErrorMessage}"</div>
            </div>
            <div className={`px-[1.625rem] py-[1.125rem] shadow-[0_1px_0px_rgba(1,1,1,0.4)] flex items-center justify-center gap-6 bg-[#343540]`}>
              <Button type="primary" onClick={() => openGithubPopup()}>Try again</Button>
            </div>
          </> : null}
        </div>
        ) : !lastDockerfileChatId ? 
          <>
            <Button type="primary" onClick={() => tryToFixDockerfile()}>{isLoadingTryToFixDockerfile ? 'Initializing AI Assistant...' : 'Ask AI Assistant for Help'}</Button>
            <div className="text-xs mt-4 text-white/50">By clicking here, you agree to share your application's Dockerfile and Logs with OpenAI, and to adhere to <a className="text-cta-green hover:underline hover:underline-offset-2" target="_blank" href="https://openai.com/policies" rel="noopener noreferrer">OpenAI Terms & Policies.</a></div>
          </> : <LoadingSpinner color="text-white" width="1rem" height="1rem" />
        }
      </div>
      <div className="">
        <div className="text-[0.5rem] font-medium text-light-grey mb-0.5 opacity-50">Powered by</div>
        <OpenAiTextLogoSVG width="60px" className="text-[#9b9c9f]" />
      </div>
    </div>
  )
}

export default AIAssisant