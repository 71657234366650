import { useEffect, useRef, useState } from 'react';
import ErrorMessage from './ErrorMessage';
import LoadingSpinner from './LoadingSpinner';
import { ReactComponent as MaximizeIconSVG } from '../assets/images/maximize-icon.svg';
import { ReactComponent as GhostIconSVG } from "../assets/images/ghost-icon.svg";
import { ContainerLog } from '@back4app2/sdk';
import Modal from "./Modal";

export type LogBoxProps = {
  errorMessage?: string;
  logs?: ContainerLog[];
  isLoading: boolean;
  canMaximize?: boolean;
  fullHeight?: boolean;
}

const ContainerLogBox = ({ errorMessage, logs, isLoading, canMaximize, fullHeight }: LogBoxProps) => {
  const [autoScroll, setAutoScroll] = useState(true);
  const [isMaximized, setIsMaximized] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const boxRef = useRef<HTMLDivElement>(null);

  const exitHandler = (e: Event) => {
    if (!document.fullscreenElement) setIsMaximized(false);
  }

  useEffect(() => {    
    document.addEventListener('fullscreenchange', exitHandler, false);
    return () => {
      document.removeEventListener('fullscreenchange', exitHandler, false);
    }
  }, []);

  useEffect(
    () => {
      if (!errorMessage && logs && autoScroll && boxRef.current) {
        boxRef.current.scrollTop = boxRef.current.scrollHeight;
      }
    },
    [
      errorMessage,
      logs,
      autoScroll
    ]
  );

  const onScroll = () => {
    if (boxRef.current && (boxRef.current.scrollTop + boxRef.current.clientHeight) >= 0.99 * boxRef.current.scrollHeight && !autoScroll) {
      setAutoScroll(true);
    } else if (boxRef.current && (boxRef.current.scrollTop + boxRef.current.clientHeight) < 0.99 * boxRef.current.scrollHeight && autoScroll) {
      setAutoScroll(false);
    }
  };

  const handleFullScreen = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isLoading || errorMessage) {
      return;
    }
    e.preventDefault();
    if (boxRef.current) {
      if (document.fullscreenElement) {
        await document.exitFullscreen();
      }
      try {
        await boxRef.current.requestFullscreen();
        setIsMaximized(true);
      } catch (err) {
        console.log('err in requesting div fullscreen', err);
        setIsModalOpen(true);
      }
    }
  }

  const exitFullScreen = async () => {
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    }
    setIsMaximized(false);
  }

  return (<div className={`relative ${fullHeight ? 'h-full' : ''}`}>
    {canMaximize && !isMaximized ? <button onClick={isMaximized ? exitFullScreen : handleFullScreen} className={`z-10 outline-none text-xs border border-white rounded bg-light-grey/[0.16] p-[0.3125rem] absolute top-[0.625rem] right-[0.625rem] group`}>
      <MaximizeIconSVG className='text-white group-hover:text-light-blue group-hover:scale-125 duration-300' />
    </button> : null}
    <div className="mt-4 relative p-4 border border-regal-blue border-solid rounded overflow-y-scroll" ref={boxRef} onScroll={onScroll} style={{ height: fullHeight ? '100%' : '26rem' }}>
      {isMaximized ? <button onClick={exitFullScreen} className={`z-10 outline-none text-xs border border-white rounded bg-light-grey/[0.16] p-[0.3125rem] sticky top-[calc(100%-25px)] left-full group`}>
        Exit FullScreen
      </button> : null}
      {isLoading ? (<div className='flex h-full justify-center items-center'>
        <div className="flex flex-col justify-center items-center">
          <LoadingSpinner />
          <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
            Loading logs
          </div>
        </div>
      </div>) : (<>
        {errorMessage ? (<>
          <ErrorMessage message={errorMessage} />
        </>) : (<>
          {logs && logs.length > 0 ? (<>
            <div className="flex flex-col gap-0.5">
              {logs && logs.map(log => (
                <p key={log.id} className="font-courrier text-xs leading-[140%]">
                  <span className="text-light-blue">{log.time.toISOString()}</span>
                  <span className="text-light-blue ml-4">{log.container.id.split('-')[0]}</span>
                  <span className={`ml-4 ${log.source === 'stderr' ? 'text-error-log-red' : 'text-white'}`}>{log.message}</span>
                </p>
              ))}
            </div>
          </>) : (<div className='flex h-full justify-center items-center'>
            <div className="flex flex-col items-center">
              <GhostIconSVG />
              <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
                Nothing here, yet!
              </div>
            </div>
          </div>)}
        </>)}
      </>)}
    </div>
    {canMaximize ? (
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)} theme='DARK'>
        <>
          {logs && logs.length > 0 ? (<>
            <div className={`flex flex-col gap-0.5`}>
              {logs && logs.map(log => (
                <p key={log.id} className="font-courrier text-xs leading-[140%]">
                  <span className="text-light-blue">{log.time.toISOString()}</span>
                  <span className="text-light-blue ml-4">{log.container.id.split('-')[0]}</span>
                  <span className={`ml-4 ${log.source === 'stderr' ? 'text-error-log-red' : 'text-white'}`}>{log.message}</span>
                </p>
              ))}
            </div>
          </>) : (<div className='flex h-full justify-center items-center'>
            <div className="flex flex-col items-center">
              <GhostIconSVG />
              <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
                Nothing here, yet!
              </div>
            </div>
          </div>)}
        </>
      </Modal>
    ) : null}
  </div>);
}

export default ContainerLogBox;


