import { useState, useRef, useCallback, useEffect } from "react";
import { ReactComponent as DownArrowIcon } from "../assets/images/down-arrow.svg";
import { ReactComponent as TriangleExclamationIcon } from '../assets/images/triangle-exclamation.svg';
import { CustomDomain } from "@back4app2/sdk";
import SelectInput from "./FormInputField/SelectInput/SelectInput";
import Modal from "./Modal";

const CustomDomainActions = ({ customDomain, mainCustomDomainId, onDeleteCustomDomain, redirectOptionList, onUpdateCustomDomain, onShowDNSConfig }: { customDomain: CustomDomain, mainCustomDomainId: string, onDeleteCustomDomain: (c: CustomDomain) => {}, onUpdateCustomDomain: (c: CustomDomain, r: string) => {}, onShowDNSConfig: (c: string) => void, redirectOptionList: {
  displayText: string;
  value: string;
}[]}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openRedirectForm, setOpenRedirectForm] = useState(false);
  const [redirectToInput, setRedirectToInput] = useState('');

  const handleClickOutsideEvent = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    },
    [],
  );

  useEffect(() => {
    if (customDomain) {
      setRedirectToInput(customDomain.redirectTo?.id || '');
    }
  }, [customDomain])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideEvent);
    document.addEventListener('touchstart', handleClickOutsideEvent);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideEvent);
      document.removeEventListener('touchstart', handleClickOutsideEvent);
    }
  }, [handleClickOutsideEvent]);

  const onSave = () => {
    onUpdateCustomDomain(customDomain, redirectToInput);
    reset();
  };

  const onCancel = () => {
    setOpenRedirectForm(false);
    reset()
  }

  const reset = () => {
    setOpenRedirectForm(false);
    setRedirectToInput(customDomain.redirectTo?.id || '');
    setIsOpen(false);
    setOpenModal(false);
  }

  const showDSNConfig = () => {
    onShowDNSConfig(customDomain.id);
    reset();
  };

  const deleteCustomDomain = () => {
    onDeleteCustomDomain(customDomain);
    reset();
  }

  if (openRedirectForm) {
    return <div className="flex gap-2">
      <SelectInput onChange={(e) => setRedirectToInput(e.target.value)} optionList={redirectOptionList} value={redirectToInput} />
      <button className="px-6 py-3 outline-none border border-light-grey rounded-[0.3125rem] font-bold text-sm text-light-grey opacity-70 hover:opacity-80 duration-300" onClick={onCancel}>Cancel</button>
      <button className="px-6 py-3 outline-none border border-cta-green bg-cta-green font-bold text-sm rounded-[0.3125rem]" onClick={onSave}>Save</button>
    </div>
  }


  return <div className="max-w-lg min-w-fit relative" ref={ref}>
    <button 
      className={`w-full bg-[#2D384B] px-[0.625rem] py-[0.4375em] outline-none border border-white rounded-[0.3125rem] hover:cursor-pointer flex items-center justify-between font-medium text-sm leading-140 ${isOpen ? 'border-b-0 rounded-br-none rounded-bl-none' : ''}`} 
      type="button"
      onClick={() => setIsOpen(prev => !prev)}
    >
      Options
      <DownArrowIcon width="14px" height="10px" className={`ml-2 transition-all duration-300 ${isOpen ? 'rotate-180' : 'rotate-0'}`} />
    </button>
    <div className={`${isOpen ? 'opacity-100' : 'opacity-0 hidden'} absolute top-full left-0 overflow-hidden min-w-max transition-all duration-300 bg-[#2D384B] text-sm z-[999] list-none divide-y divide-gray-100 border border-white rounded-tr-[5px] rounded-bl-[5px] rounded-br-[5px]`}>
      <ul className="py-1">
        {customDomain.id !== mainCustomDomainId ? <li className="hover:cursor-pointer hover:bg-[#34506F] transition-all duration-200 px-[0.625rem] py-[0.4375em]" onClick={() => showDSNConfig()}>Show DNS Config</li> : null}
        <li className="hover:cursor-pointer hover:bg-[#34506F] transition-all duration-200 px-[0.625rem] py-[0.4375em]" onClick={() => setOpenRedirectForm(true)}>Redirect Options</li>
        {customDomain.id !== mainCustomDomainId ? <li className="text-error-red hover:cursor-pointer hover:bg-[#34506F] transition-all duration-200 px-[0.625rem] py-[0.4375em]" onClick={() => setOpenModal(true)}>Delete</li> : null}
      </ul>
  </div>
  <Modal open={openModal} onClose={() => setOpenModal(false)}>
    <>
      <div className="flex w-full justify-center">
        <TriangleExclamationIcon className="text-error-red" width="40px" height="56px" />
      </div>
      <div className="font-semibold leading-140 text-dark text-center mb-2">Delete Custom Domain?</div>
      <div className="text-sm text-dark/70 text-center max-w-sm">{customDomain.name}</div>
      <div className="flex justify-center space-x-2 w-full mt-4">
        <button className="text-dark-grey border border-light-grey text-xs px-[0.625rem] py-2 font-semibold rounded-[5px]" onClick={() => reset()}>No</button>
        <button className="bg-error-red text-xs px-[0.625rem] py-2 font-semibold rounded-[5px] text-white" onClick={() => deleteCustomDomain()}>Yes, Delete</button>
      </div>
    </>
  </Modal>
</div>;
};

export default CustomDomainActions;
