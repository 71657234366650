import { useEffect, useState } from 'react';
import AreaLineGraph from './AreaLineGraph';
import { XAxisProps, YAxisProps } from 'recharts';

const TimeGraph = ({ data, colors, timeWindow, yAxisProps, hideLegend, axisLegendColor }: {data: { [key: string]: number }[], colors: { [key: string]: string }, timeWindow: number, yAxisProps: YAxisProps, hideLegend?: boolean, axisLegendColor?: string }) => {
  const [xAxisProps, setXAxisProps] = useState<XAxisProps>();

  const lastTime = (data && data.length > 0 && data[data.length - 1].time) || undefined;

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const refreshXAxisProps = () => {
      const now = new Date();

      const nexMinute = new Date(now.getTime());
      nexMinute.setSeconds(0);
      nexMinute.setMinutes(now.getMinutes() + 1);

      let domainMax = nexMinute.getTime();

      if (lastTime && lastTime > domainMax) {
        domainMax = lastTime;
      }

      const domainMin = domainMax - timeWindow;

      const ticks: number[] = [];

      let tick = new Date(domainMin).setMinutes(30);

      if (tick < domainMin) {
        tick += 30 * 60 * 1000;
      }

      while(tick <= domainMax) {
        ticks.push(tick);
        tick += 30 * 60 * 1000;
      }

      setXAxisProps({
        dataKey: 'time',
        domain: [domainMin, domainMax],
        ticks,
        tickFormatter: value => new Date(value).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hourCycle: 'h23' }).replace(/^0/, '')
      });

      setTimeout(refreshXAxisProps, domainMax - (new Date()).getTime());
    };

    refreshXAxisProps();

    return () => {
      clearTimeout(timeout);
    };
  }, [timeWindow, lastTime]);

  return <>
    {xAxisProps &&
      <AreaLineGraph
        data={data}
        colors={colors}
        xAxisProps={xAxisProps}
        yAxisProps={yAxisProps}
        hideLegend={!!hideLegend}
        axisLegendColor={axisLegendColor}
      />
    }
  </>;
};

export default TimeGraph;
