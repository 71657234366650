import { ReactComponent as DockerLogoSVG } from '../../assets/images/dockerfile-missing-icon.svg';
import CustomerErrorMessage from './CustomErrorMessage';

const DockerfileErrorMessage = () => {
  return (
    <CustomerErrorMessage type='error' icon={<DockerLogoSVG width="28px" height="28px" className='text-error-red flex-none' />} headerText="Dockerfile is missing!" description={
      <>
      <div className="">Your repository must be dockerized to be deployed in your container.</div>
        <ol className='list-decimal ml-4 pl-1 mb-6'>
          <li>Create a file called Dockerfile(case-sensitive)</li>
          <li>Configure your Dockerfile according to your App needs</li>
          <li>Paste the file on your project root</li>
          <li>Re-deploy your Container App using the Select Actions box</li>
        </ol>
        <a href="https://www.back4app.com/docs-containers/how-to-create-a-dockerfile" target="_blank" rel="noopener noreferrer" className='text-old-blue underline underline-offset-2'>How to create a Dockerfile?</a>
      </>
    } />
  )
}

export default DockerfileErrorMessage