import { ReactComponent as StatusErrorSVG } from '../../assets/images/status-cancel.svg';
import CustomerErrorMessage from './CustomErrorMessage';

const ResourceNotAccessibleError = () => {
  return (
    <CustomerErrorMessage type='error' icon={<StatusErrorSVG width="26px" height="26px" className='text-error-red flex-none' />} headerText="Resource not accessible!" description={
      <>
        <div className="font-bold">Your repository is not longer accessible to us.</div>
        <div className="">Please edit github permission to give access to this repository or unsuspend the app to continue deploying.</div>
      </>
    } />
  )
}

export default ResourceNotAccessibleError