import { ReactComponent as GhostIconSVG } from '../assets/images/ghost-icon.svg';

const GhostMessage = () => {
  return (<>
    <div className="grow flex flex-col items-center justify-center">
      <GhostIconSVG />
      <div className="mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey">
        Nothing here, yet!
      </div>
    </div>
  </>);
};

export default GhostMessage;
