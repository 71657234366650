import styles from './AgentMessage.module.css';

import { Message } from "../../types/Agent";

import Markdown from 'react-markdown';

// import rehypeMathjax from 'rehype-mathjax';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';

import { ReactComponent as UserIconSVG } from '../../assets/images/user-icon-square.svg';
import { ReactComponent as B4ASqaureIconSVG } from '../../assets/images/b4a-sqaure-logo.svg';
import React from 'react';

const customStyle: {
  [key: string]: React.CSSProperties
} = {
  ...oneDark,
  'pre[class*="language-"]': {
    ...oneDark['pre[class*="language-"]'],
    backgroundColor: '#111214',
    margin: 0
  },
  'code[class*="language-"]': {
    ...oneDark['code[class*="language-"]'],
    backgroundColor: '#111214',
    margin: 0
  },
};

const AgentMessage = ({ message }: { message: Message}) => {
  const bgColor = message.role === 'assistant' ? 'bg-white/5' : '';
  return (
    <div className={`flex items-center justify-start gap-[1.44rem] px-[1.62rem] py-[1.12rem] text-sm ${bgColor}`}>
      <div className="flex">
        {message.role === 'assistant' ? <B4ASqaureIconSVG width="2rem" height="2rem" /> : <span className="inline-block p-[0.44rem] bg-old-blue rounded-sm"><UserIconSVG  /></span>}
      </div>
      {/* <div className="w-full"> */}
        {message.role === 'user' ? (
          <div className="prose !prose-invert whitespace-pre-wrap flex-1">
            {message.content}
          </div>
        ) : (
          <div className='flex flex-col w-full max-w-[calc(100%-3.5rem)]'>
            <Markdown
              className="prose !prose-invert flex-1"
              remarkPlugins={[remarkGfm, remarkMath ]}
              // rehypePlugins={[rehypeMathjax]}
              components={{
                code(props) {
                  const {children, className, node, ...rest} = props
                  const match = /language-(\w+)/.exec(className || '')                  
                  return match && match[1] ? (
                    <div className='codeblock'>
                      <SyntaxHighlighter
                        children={String(children).replace(/\n$/, '')}
                        style={customStyle}
                        language={match[1]}
                      />
                    </div>
                  ) : (
                    <code {...rest} className={className}>
                      {children}
                    </code>
                  )
                },
                table({ children }) {
                  return (
                    <table className="border-collapse border border-black px-3 py-1 dark:border-white">
                      {children}
                    </table>
                  );
                },
                th({ children }) {
                  return (
                    <th className="break-words border border-black bg-gray-500 px-3 py-1 text-white dark:border-white">
                      {children}
                    </th>
                  );
                },
                td({ children }) {
                  return (
                    <td className="break-words border border-black px-3 py-1 dark:border-white">
                      {children}
                    </td>
                  );
                },
                a({ href, className, children }) {
                  return (
                    <a className={className} href={href} target="_blank" rel="noreferrer noopener">
                      {children}
                    </a>
                  );
                }
              }}
            >
              {message.content}
            </Markdown>
            {message.hasFailed ? (
              <div className="mt-4 text-error-red">
                Something went wrong. If this issue persists, please contact us.
              </div>
            ) : (
              <>
                {message.isTyping && (
                  <span className={styles.cursor}></span>
                )}
              </>
            )}
          </div>
        )}
      {/* </div> */}
    </div>
  )
}

export default AgentMessage;
