import { XAxis, YAxis, CartesianGrid, AreaChart, ResponsiveContainer, Area, Legend, XAxisProps, YAxisProps } from 'recharts';

const AreaLineGraph = ({ data, colors, xAxisProps, yAxisProps, hideLegend, axisLegendColor }: { data: { [key: string]: number }[], colors: { [key: string]: string }, xAxisProps: XAxisProps, yAxisProps: YAxisProps, hideLegend: boolean, axisLegendColor?: string }) => {
  return (
    <ResponsiveContainer>
      <AreaChart
        data={data}
        margin={{ top: 5, right: 0, bottom: 0, left: 20 }}
      >
        <CartesianGrid strokeDasharray="1 1" stroke='#cccccc' strokeWidth={0.5} strokeOpacity={0.5} />
        <XAxis type="number" allowDataOverflow={true} interval={0} stroke={axisLegendColor ? axisLegendColor : '#C1E2FF'} axisLine={false} tickLine={false} tickMargin={15} fontFamily='"Inter", sans-serif' fontSize={10} {...xAxisProps} />
        <YAxis type="number" allowDataOverflow={true} interval={0} orientation="right" stroke={axisLegendColor ? axisLegendColor : '#C1E2FF'} axisLine={false} tickLine={false} tickMargin={5} fontFamily='"Inter", sans-serif' fontSize={10} width={65} {...yAxisProps} />
        {Object.keys(colors).map(key =>
          <Area key={`area-${key}`} type="linear" dataKey={key} connectNulls stroke={colors[key]} strokeWidth={2} fill={colors[key]} fillOpacity={0.1} isAnimationActive={false} />
        )}
        {hideLegend ? null : (
          <Legend content={({ payload }) => (
            <>
              {payload && (
                <ul className="flex flex-row items-center gap-6 py-2 px-3.5 mt-3">
                  {payload.map((entry: any, index: number) => (
                    <li key={`legend-item-${index}`} className="flex flex-row gap-1.5 items-center">
                      <div className="w-[15px] h-0 border-solid rounded" style={{ borderColor: entry.color, borderWidth: '1px' }}></div>
                      <div className="font-inter text-xs leading-[140%] text-light-blue">
                        {entry.value}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )} />
        )}
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default AreaLineGraph;
