import { App, AuthenticationError, AuthorizationError, Deployment,DeploymentLog, NetworkError, Subscription } from '@back4app2/sdk';
import { useOutletContext } from 'react-router-dom';
import { ReactComponent as BranchIconSmallSVG } from '../assets/images/branch-icon-small.svg';
import { useState, useEffect, useReducer} from 'react';
import LogBox from '../components/LogBox';
import back4app2 from '../back4app2';
import { BACK4APP_DOT_COM_SITE_URL } from '../settings';
import { timeFromNow } from '../utils/dates';
import Timer from '../components/Timer';
import MyAppDeploymentStatus from '../components/MyAppDeploymentStatus';
import { AmplitudeEvent, trackEvent } from '../utils/amplitude';
import CancelBuildButton from '../components/CancelBuildButton';
import GhostMessage from "../components/GhostMessage";
import buildCommitAuthorsMessage from '../utils/buildCommitAuthorsMessage';
interface MyAppDeploymentsState {
  isLoading: boolean;
  loadingErrorMessage?: string;
  deployments?: Deployment[];
  selectedDeployment?: Deployment;
  isLoadingDeploymentLogs: boolean;
  deploymentLogsLoadingErrorMessage?: string;
  deploymentLogs?: DeploymentLog[];
}

const INITIAL_STATE: MyAppDeploymentsState = {
  isLoading: true,
  isLoadingDeploymentLogs: true,
};

enum MyAppDeploymentsActionType {
  RESET,
  FINISH_LOADING,
  SET_DEPLOYMENT,
  FINISH_LOADING_DEPLOYMENT_LOGS,
}

const finishLoading = (errorMessage?: string, deployments?: Deployment[]) =>
  ({
    type: MyAppDeploymentsActionType.FINISH_LOADING,
    payload: {
      errorMessage,
      deployments,
    },
  } as const);

const setSelectedDeployment = (
  errorMessage?: string,
  deployment?: Deployment
) =>
  ({
    type: MyAppDeploymentsActionType.SET_DEPLOYMENT,
    payload: {
      errorMessage,
      deployment,
    },
  } as const);

const finishLoadingDeploymentLogs = (
  errorMessage?: string,
  deploymentLogs?: DeploymentLog[]
) =>
  ({
    type: MyAppDeploymentsActionType.FINISH_LOADING_DEPLOYMENT_LOGS,
    payload: {
      errorMessage,
      deploymentLogs,
    },
  } as const);

const reset = () =>
  ({
    type: MyAppDeploymentsActionType.RESET,
  } as const);

type MyAppDeployemnstsAction =
  | ReturnType<typeof reset>
  | ReturnType<typeof finishLoading>
  | ReturnType<typeof setSelectedDeployment>
  | ReturnType<typeof finishLoadingDeploymentLogs>;

const reducer = (
  state: MyAppDeploymentsState = INITIAL_STATE,
  action: MyAppDeployemnstsAction
): MyAppDeploymentsState => {
  switch (action.type) {
    case MyAppDeploymentsActionType.RESET:
      return INITIAL_STATE;

    case MyAppDeploymentsActionType.FINISH_LOADING:
      return {
        ...state,
        isLoading: false,
        loadingErrorMessage: action.payload.errorMessage,
        deployments: action.payload.deployments,
      };
    case MyAppDeploymentsActionType.SET_DEPLOYMENT:
      return {
        ...state,
        selectedDeployment: action.payload.deployment,
        deploymentLogsLoadingErrorMessage: undefined,
        isLoadingDeploymentLogs: true,
        deploymentLogs: undefined,
      };
    case MyAppDeploymentsActionType.FINISH_LOADING_DEPLOYMENT_LOGS:
      return {
        ...state,
        isLoadingDeploymentLogs: false,
        deploymentLogsLoadingErrorMessage: action.payload.errorMessage,
        deploymentLogs: action.payload.deploymentLogs,
      };
  }
};

const MyAppDeployments = () => {
  const { app } = useOutletContext<{ app: App }>();
  const mainServiceEnvironment =
    app && app.mainService && app.mainService.mainServiceEnvironment;
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  let {
    deploymentLogsLoadingErrorMessage,
    deployments,
    selectedDeployment,
    deploymentLogs,
    isLoadingDeploymentLogs
  } = state;
  const [open, setIsOpen] = useState(false);

  useEffect(() => {
    trackEvent(AmplitudeEvent.AT_APP_DEPLOYMENT_PAGE);
  }, []);

  useEffect(() => {
    let subscription: Subscription;

    if (mainServiceEnvironment?.id) {
      subscription = back4app2.subscribeToDeployments(
        mainServiceEnvironment.id,
        (error, snapshot) => {
          if (error) {
            if (error instanceof NetworkError) {
              console.error("network error", error);
              dispatch(
                finishLoading(
                  "Network error when loading app deployments. Check your internet connection and try again."
                )
              );
            } else if (
              error instanceof AuthenticationError ||
              error instanceof AuthorizationError
            ) {
              window.location.replace(
                `${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(
                  window.location.href
                )}`
              );
            } else {
              console.error("unexpected error loading app deployments", error);
              dispatch(
                finishLoading(
                  "Unexpected error when loading app deployments. Please try again."
                )
              );
            }
          } else {
            dispatch(finishLoading(undefined, snapshot));
          }
        }
      );
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
        dispatch(reset());
      }
    };
  }, [mainServiceEnvironment?.id]);

  useEffect(() => {
    let subscription: Subscription;

    if (selectedDeployment) {
      subscription = back4app2.subscribeToDeploymentLogs(
        selectedDeployment.id,
        (error, snapshot) => {
          if (error) {
            if (error instanceof NetworkError) {
              console.error("network error", error);
              dispatch(
                finishLoadingDeploymentLogs(
                  "Network error when loading last deployment logs. Check your internet connection and try again."
                )
              );
            } else if (
              error instanceof AuthenticationError ||
              error instanceof AuthorizationError
            ) {
              window.location.replace(
                `${BACK4APP_DOT_COM_SITE_URL}/login?return-url=${encodeURIComponent(
                  window.location.href
                )}`
              );
            } else {
              console.error(
                "unexpected error loading last deployment logs",
                error
              );
              dispatch(
                finishLoadingDeploymentLogs(
                  "Unexpected error when loading last deployment logs. Please try again."
                )
              );
            }
          } else {
            dispatch(finishLoadingDeploymentLogs(undefined, snapshot));
          }
        }
      );
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [selectedDeployment]);

  const handleExpand = (deployment: Deployment) => {
    if (!selectedDeployment) {
      setIsOpen(true);
      dispatch(setSelectedDeployment(undefined, deployment));
    } else if (selectedDeployment && selectedDeployment.id === deployment.id) {
      setIsOpen(false);
      dispatch(setSelectedDeployment(undefined, undefined));
    } else if (selectedDeployment && selectedDeployment.id !== deployment.id) {
      dispatch(setSelectedDeployment(undefined, deployment));
    }
  };

  return (
    <>
      <h1 className='font-sora text-[1.375rem] leading-[140%]'>
        Deployments
      </h1>
      {deployments ? (
        deployments.length > 0 ?
        deployments
          .sort((a, b) => Number(b.createdAt) - Number(a.createdAt))
          .map((deployment) => (
            <div
              key={deployment.id}
              className='mt-4 px-4 py-2 hover:bg-[#10203A] rounded-lg cursor-pointer'
              onClick={() => handleExpand(deployment)}
            >
              <div>
                <div className='py-1 flex items-center gap-1.5'>
                  <div className='flex items-center gap-2 w-1/2'>
                    <div className='text-sm leading-[140%] truncate'>
                      {deployment.id}
                    </div>
                    <div className='flex items-center gap-1'>
                      <BranchIconSmallSVG />
                      <div className='text-light-blue text-xs leading-[140%] truncate'>
                        {deployment.branchName}
                      </div>
                    </div>
                  </div>
                  <div className='grow flex justify-end'>
                    <MyAppDeploymentStatus deployment={deployment} />
                  </div>
                  <div>
                    <Timer
                      realElapsedMS={(deployment.deployTask && deployment.deployTask.elapsedTimeMS) || 0}
                      hasFinished={(deployment.deployTask && deployment.deployTask.finishedAt instanceof Date) || false}
                    />
                  </div>
                  <CancelBuildButton deployment={deployment} />
                </div>
                <div className='flex gap-4 justify-between'>
                  <div className='grow text-light-blue text-xs leading-[140%] max-w-[15rem] truncate'>
                    {deployment.commitMessage}
                  </div>
                  <div className='max-w-[50%] text-light-blue text-xs leading-[140%] text-right'>
                    {(deployment.committedAt && timeFromNow(deployment.committedAt)) || ''}
                    {((deployment.commitAuthorLogin || deployment.commitCommitterLogin) && ` ${buildCommitAuthorsMessage(deployment.commitAuthorLogin, deployment.commitCommitterLogin)}`) || ''}
                  </div>
                </div>
              </div>
              {open && selectedDeployment?.id === deployment.id && (
                <div className='handleExpand cursor-auto' onClick={(e) => e.stopPropagation()}>
                  <LogBox
                    errorMessage={deploymentLogsLoadingErrorMessage}
                    logs={deploymentLogs}
                    isLoading={isLoadingDeploymentLogs}
                  />
                </div>
              )}
            </div>
          ))
        : (<div className='my-4'><GhostMessage /></div> )
      ) : (
        !mainServiceEnvironment?.id ? (<div className='my-4'><GhostMessage /></div>) : (
        <div className='mt-10 flex flex-col items-center'>
          <div className='mt-4 font-sora font-semibold text-lg leading-[140%] text-light-grey'>
            Loading deployments...
          </div>
        </div>)
      )}
    </>
  );
};

export default MyAppDeployments;
