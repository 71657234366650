interface CustomErrorMessageProps {
  type: 'error' | 'warn' | 'info';
  icon: React.ReactNode;
  headerText: string;
  description: React.ReactNode;
}

const CustomerErrorMessage = ({ type, icon, headerText, description }: CustomErrorMessageProps) => {
  const color = type === 'error' ? 'error-red' : type === 'warn' ? 'alert-yellow' : 'white';
  const bgColor = type === 'error' ? 'bg-error-red' : type === 'warn' ? 'bg-alert-yellow' : 'bg-white';
  return (
    <div className="mb-4 p-6 bg-dark-grey rounded-lg relative">
    <div className="flex space-x-6">
      <div className="flex flex-col justify-between">
        {icon}
        <div className={`w-[1px] ${bgColor} mx-auto h-full`}></div>
      </div>
      <div className="font-inter text-sm">
        <div className={`font-sora text-[1.375rem] font-bold mb-6 text-${color}`}>{headerText}</div>
        {description}
      </div>
    </div>
  </div>)
}

export default CustomerErrorMessage;