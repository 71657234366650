import TimeGraph from './TimeGraph';

const PIDGraph = ({ data, colors, max }: { data: { time: number, [containerId: string]: number }[], colors: { [containerId: string]: string }, max: number }) => {
  return <>
    <TimeGraph
      data={data}
      colors={colors}
      timeWindow={3 * 60 * 60 * 1000}
      yAxisProps={{
        domain: [0, max],
        ticks: [
          0,
          max / 4,
          max / 2,
          3 * max / 4,
          max
        ]
      }}
    />
  </>
};

export default PIDGraph;
