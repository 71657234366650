import { useCallback, useEffect, useState } from 'react';

const Timer = ({ realElapsedMS, hasFinished }: { realElapsedMS: number, hasFinished: boolean }) => {
  const [realElapsedMSReceivedAt, setRealElapsedMSReceivedAt] = useState<Date>(new Date());
  const [elapsedMessage, setElapsedMessage] = useState<string>();
  const [timeoutObject, setTimeoutObject] = useState<NodeJS.Timeout>();

  useEffect(() => {
    setRealElapsedMSReceivedAt(new Date());
  }, [realElapsedMS]);

  const refresh = useCallback(
    () => {
      const elapsedMS = (realElapsedMS + (hasFinished ? 0 : new Date().getTime() - realElapsedMSReceivedAt.getTime()));

      if (elapsedMS < 1000) {
        if (!hasFinished) {
          setTimeoutObject(setTimeout(refresh, 1000 - elapsedMS));
        }
        return setElapsedMessage('0s');
      }

      const elapsedS = Math.floor(elapsedMS / 1000);

      if (elapsedS < 60) {
        if (!hasFinished) {
          setTimeoutObject(setTimeout(refresh, 1000 - (elapsedMS % 1000)));
        }
        return setElapsedMessage(`${elapsedS}s`);
      }

      const elapsedM = Math.floor(elapsedS / 60);

      if (elapsedM < 60) {
        if (!hasFinished) {
          setTimeoutObject(setTimeout(refresh, 1000 - (elapsedMS % 1000)));
        }
        return setElapsedMessage(`${elapsedM}m ${elapsedS % 60}s`);
      }

      const elapsedH = Math.floor(elapsedM / 60);

      if (elapsedH < 24) {
        if (!hasFinished) {
          setTimeoutObject(setTimeout(refresh, (60 * 1000) - (elapsedMS % (60 * 1000))));
        }
        return setElapsedMessage(`${elapsedH}h ${elapsedM % 60}m`);
      }

      const elapsedD = Math.floor(elapsedH / 24);

      if (elapsedD < 100) {
        if (!hasFinished) {
          setTimeoutObject(setTimeout(refresh, (60 * 60 * 1000) - (elapsedMS % (60 * 60 * 1000))));
        }
        return setElapsedMessage(`${elapsedD}d ${elapsedD % 24}h`);
      }

      if (!hasFinished) {
        setTimeoutObject(setTimeout(refresh, (24 * 60 * 60 * 1000) - (elapsedMS % (24 * 60 * 60 * 1000))));
      }

      return setElapsedMessage(`${elapsedD}d`);
    },
    [
      realElapsedMS,
      hasFinished,
      realElapsedMSReceivedAt
    ]
  );

  useEffect(
    () => {
      refresh();
    },
    [
      refresh
    ]
  )
  
  useEffect(
    () => {
      return () => {
        if (timeoutObject) {
          clearTimeout(timeoutObject);
        }        
      }
    },
    [
      realElapsedMS,
      hasFinished,
      timeoutObject
    ]
  );

  return (<>
    <div className="text-right text-light-blue text-xs leading-[140%]">
      {elapsedMessage}
    </div>
  </>);
};

export default Timer;
