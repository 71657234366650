import { Back4app2 } from '@back4app2/sdk';
import { API_HTTP_URL, API_WS_URL } from './settings';
import { useEffect, useState } from 'react';

const onConnectionStatusChangeCallbacks: ((isConnected: boolean, isReconnecting: boolean) => void)[] = [];

const back4app2 = new Back4app2({
  httpUrl: API_HTTP_URL,
  wsUrl: API_WS_URL,
  onConnectionStatusChange: (isConnected, isReconnecting) => {
    onConnectionStatusChangeCallbacks.forEach(onConnectionStatusChangeCallback => onConnectionStatusChangeCallback(isConnected, isReconnecting));
  },
});

export default back4app2;

export const useBack4app2ConnectionStatus = (): { isConnected: boolean, isReconnecting: boolean } => {
  const [status, setStatus] = useState<{ isConnected: boolean, isReconnecting: boolean }>({ isConnected: back4app2.isConnected, isReconnecting: back4app2.isReconnecting });

  useEffect(
    () => {
      const onConnectionStatusChangeCallback = (isConnected: boolean, isReconnecting: boolean) => {
        setStatus({ isConnected, isReconnecting });
      };

      onConnectionStatusChangeCallbacks.push(onConnectionStatusChangeCallback);

      return () => {
        onConnectionStatusChangeCallbacks.splice(onConnectionStatusChangeCallbacks.indexOf(onConnectionStatusChangeCallback), 1);
      };
    },
    []
  );

  return status;
};
