import { DEFAULT_STATS_TIME_WINDOW } from '../../utils/metrics';
import TimeGraph from './TimeGraph';

const CPUGraph = ({ data, colors, hideLegend, axisLegendColor, yAxisTicksCount = 5, timeWindow = DEFAULT_STATS_TIME_WINDOW }: { data: { time: number, [containerId: string]: number }[], colors: { [containerId: string]: string }, hideLegend?: boolean, axisLegendColor?: string, yAxisTicksCount?: number, timeWindow?: number }) => {
  return <>
    <TimeGraph
      data={data}
      colors={colors}
      timeWindow={timeWindow}
      yAxisProps={{
        domain: [0, 100],
        tickFormatter: value => `${value}%`,
        tickCount: yAxisTicksCount
      }}
      hideLegend={!!hideLegend}
      axisLegendColor={axisLegendColor}
    />
  </>
};

export default CPUGraph;
