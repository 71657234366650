import { useState } from 'react';

import { ReactComponent as PlayIconSVG } from '../../assets/images/play-icon.svg';
import { ReactComponent as ChevronLeftSVG } from '../../assets/images/chevron-left.svg';
import AIPromptModal from './AIPromptModal';
import { AgentPrompt } from '@back4app2/sdk';
import { AmplitudeEvent, trackEvent } from '../../utils/amplitude';

const AIPromptList = ({ prompts, onClickPlay }: { prompts: AgentPrompt[], onClickPlay: (prompt: AgentPrompt) => void }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isCreatePromptModalOpen, setIsCreatePromptModalOpen] = useState(false);

  return (
    <div className="absolute w-full bg-old-dark-blue py-3 left-0 bottom-0 overflow-hidden">
      <div className="flex justify-between items-center text-light-blue px-6 cursor-pointer" onClick={() => setIsCollapsed((prev) => !prev)}>
        <span className='font-semibold text-sm whitespace-nowrap'>Prompt Library</span> <ChevronLeftSVG className={`transform duration-200 ${isCollapsed ? 'rotate-90' : '-rotate-90'}`} />
      </div>
      <div className={`gap-2 flex-col transition-all px-6 duration-500 overflow-x-hidden ${isCollapsed ? 'h-0 opacity-0' : 'h-72 opacity-100 mt-3 overflow-y-auto'}`}>
        {prompts.map(prompt => (
          <div key={prompt.id} className="flex gap-2 items-center justify-between px-4 py-3 cursor-pointer hover:bg-mid-blue rounded-lg group" onClick={() => {
            trackEvent(AmplitudeEvent.AGENT_PROMPT_SELECTED);
            onClickPlay(prompt);
            }}
          >
            <span className='truncate text-ellipsis'>{prompt.name}</span>
            <PlayIconSVG className='hidden opacity-0 group-hover:block group-hover:opacity-100 transition-all text-light-blue' />
          </div>
        ))}
      </div>
      <AIPromptModal open={isCreatePromptModalOpen} onClose={() => setIsCreatePromptModalOpen(false)} onCreate={() => console.log('create prompt')} />
    </div>
  )
}

export default AIPromptList