import { useMemo } from 'react';
import { CustomDomain, CustomDomainStatus, DNSVerificationError } from '@back4app2/sdk';
import StatusError from './StatusError';
import StatusSpinner from './StatusSpinner';
import StatusSuccess from './StatusSuccess';
import StatusAttention from './StatusAttention';

const MyCustomDomainStatus = (props: { customDomain: CustomDomain, onVerifyDNS: (c: string) => void }) => {
  const { customDomain: { status: domainStatus, error, id: customDomainId }, onVerifyDNS } = props;

  const statusList = useMemo(
    () => {
      let statusList: { Icon: () => JSX.Element, text: string | JSX.Element, bgColor: string }[] = [];

      if (domainStatus === CustomDomainStatus.INITIALIZING) {
        statusList.push({ Icon: StatusSpinner, text: 'Initializing', bgColor: 'bg-old-blue/10' });
      } else if (domainStatus === CustomDomainStatus.INITIALIZED) {
        statusList.push({ Icon: StatusSpinner, text: 'Initialized', bgColor: 'bg-old-blue/10' });
      } else if (domainStatus === CustomDomainStatus.AWAITING_DNS) {
        statusList.push({ Icon: StatusAttention, text: 'Awaiting external DNS', bgColor: 'bg-alert-yellow/10' });
      } else if (domainStatus === CustomDomainStatus.GENERATING_SSL) {
        statusList.push({ Icon: StatusSuccess, text: 'Valid Configuration', bgColor: 'bg-cta-green/10' });
        statusList.push({ Icon: StatusSpinner, text: 'Generating SSL', bgColor: 'bg-old-blue/10' });
      } else if (domainStatus === CustomDomainStatus.RELEASING) {
        statusList.push({ Icon: StatusSuccess, text: 'Valid Configuration', bgColor: 'bg-cta-green/10' });
        statusList.push({ Icon: StatusSuccess, text: 'Generating SSL', bgColor: 'bg-old-blue/10' });
        statusList.push({ Icon: StatusSpinner, text: 'Releasing', bgColor: 'bg-old-blue/10' });
      } else if (domainStatus === CustomDomainStatus.READY) {
        statusList.push({ Icon: StatusSuccess, text: 'Valid Configuration', bgColor: 'bg-cta-green/10' });
        statusList.push({ Icon: StatusSuccess, text: 'Valid SSL', bgColor: 'bg-cta-green/10' });
      } else if (domainStatus === CustomDomainStatus.DELETING) {
        statusList.push({ Icon: StatusError, text: 'Deleting', bgColor: 'bg-error-red/10' });
      } else if (domainStatus === CustomDomainStatus.FAILED && error instanceof DNSVerificationError) {
        statusList.push({ Icon: StatusError, text: <>External DNS Failed <button className='outline-none border-none underline text-light-blue' onClick={() => onVerifyDNS(customDomainId)}>Try again?</button> </>, bgColor: 'bg-error-red/10' });
      } else if (domainStatus === CustomDomainStatus.FAILED) {
        statusList.push({ Icon: StatusError, text: 'Failed', bgColor: 'bg-error-red/10' });
      }
      return statusList;
    },
    [customDomainId, domainStatus, error, onVerifyDNS]
  )
  
  return (<>
    <div className="flex items-center flex-wrap gap-[0.625rem]">
      {statusList.map(({ Icon, text, bgColor }, idx) => (
        <div key={idx} className={`${bgColor} rounded-2xl flex items-center space-x-[0.375rem] py-1 px-[0.625rem]`}>
          <Icon />
          <div className="text-xs leading-[140%]">
            {text}
          </div>
        </div>
      ))}
    </div>
  </>);
}

export default MyCustomDomainStatus;
