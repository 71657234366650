import { ServiceEnvironment } from '@back4app2/sdk';
import { ReactComponent as CircleExclamationSVG } from '../../assets/images/circle-exclaimation-icon.svg';
import { BACK4APP_DOT_COM_SITE_URL } from '../../settings';
import Button from '../Button';
import CustomerErrorMessage from './CustomErrorMessage';

const PaymentPendingErrorMessage = ({ appId, mainServiceEnvironment }: { appId: string, mainServiceEnvironment: ServiceEnvironment }) => {
  return (
    <CustomerErrorMessage type='error' icon={<CircleExclamationSVG width="27px" height="26px" className='text-error-red flex-none' />} headerText="Pending Payment" description={
      <>
        <div className="text-sm font-bold">Please complete a payment to proceed with the deployment.</div>
        {!mainServiceEnvironment.isFreePlanElegible && mainServiceEnvironment.plan.name.includes('Free') ? (
          <>
            <div className="text-sm mb-6">Unfortunately, the app type you've created is not eligible for our Free Plan. To continue with this app, please upgrade to a paid plan.</div>
            <Button type='submit'><a role="button" target="_blank" rel='noreferrer noopener' href={`${BACK4APP_DOT_COM_SITE_URL}/pricing/container-as-a-service?appId=${appId}&type=containers`}>Upgrade Now</a></Button>
          </>
        ) : (
          <>
            {mainServiceEnvironment.plan.name.includes('Free') ? (
              <>
                <div className="text-sm mb-6">Sorry, your app cannot be deployed as you have exceeded the usage limits of our Free Plan. Please upgrade to a paid plan to continue.</div>
                <Button type='submit'><a role="button" target="_blank" rel='noreferrer noopener' href={`${BACK4APP_DOT_COM_SITE_URL}/pricing/container-as-a-service?appId=${appId}&type=containers`}>Upgrade Now</a></Button>
              </>
            ) : (
              <>
                <div className="text-sm mb-6">Sorry, your app cannot be deployed due to a pending payment. Please complete your payment or contact our support team if you have any questions.</div>
                <Button type='submit'><a role="button" target="_blank" rel='noreferrer noopener' href={`${BACK4APP_DOT_COM_SITE_URL}/pricing/container-as-a-service?appId=${appId}&type=containers`}>Pay Now</a></Button>
              </>
            )}
          </>
        )}
      </>
    } />
  )
}

export default PaymentPendingErrorMessage;