interface SkeletonProps {
  count?: number;
  circle?: boolean;
  className?: string;
  style?: React.CSSProperties;
  baseColor?: string;
  highlightColor?: string;
  animationDuration?: number;
  direction?: 'ltr' | 'rtl';
}

const Skeleton = (props: SkeletonProps) => {
  const { count=1, circle=false, className='', style } = props;
  let inlineCssStyle: React.CSSProperties = {};
  
  if (style) {
    inlineCssStyle = { ...style }
  }

  const skeleton = <div style={inlineCssStyle} className={`relative isolate inline-block overflow-hidden ${circle && 'rounded-full'} bg-[#d9d9d9]/[.48] before:absolute before:inset-0 before:bg-gradient-to-r before:from-transparent before:via-[#d9d9d9]/[.24] before:to-transparent before:-translate-x-full before:animate-[shimmer_0.5s_infinite] ${className}`}></div>
  
  return (
    count > 1 ? <>
      {Array(count).fill(skeleton)}
    </> : (skeleton)    
  )
}

export default Skeleton