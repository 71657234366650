import { KanikoImageBuildErrorMessage } from '@back4app2/sdk';
import { ReactComponent as StatusErrorSVG } from '../../assets/images/status-cancel.svg';
import CustomerErrorMessage from './CustomErrorMessage';

const ImageBuildError = ({ errorMessage }: { errorMessage: string }) => {
  let errorHeading = '', errorDesc = '';

  switch (errorMessage) {
    case KanikoImageBuildErrorMessage.BROKEN_SYMLINK:
      errorHeading = 'Deployment Failed: Broken Symlinks Detected in Your GitHub Repository';
      errorDesc = 'The deployment branch in your GitHub repository has broken symlinks, which can cause issues during runtime. Please identify, fix or remove these symlinks, and then attempt deployment again.';
      break;
    case KanikoImageBuildErrorMessage.INVALID_PROTOCOL:
      errorHeading = 'Deployment Failed: Invalid Protocol in Dockerfile';
      errorDesc = 'We kindly request that you adhere to our protocol guidelines, which specify the usage of lowercase characters only. Could you please update your Dockerfile to expose the port using the lowercase protocol?';
      break;
    default:
      break;
  }

  return (
    <CustomerErrorMessage type='error' icon={<StatusErrorSVG width="26px" height="26px" className='text-error-red flex-none' />} headerText="Image Build Error" description={
      <>
        <div className="text-sm font-bold">{errorHeading}</div>
        <div className="text-sm">{errorDesc}</div>
      </>
    } />
  )
}

export default ImageBuildError